import { SuccessResponse, TAG_TYPES, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import { Customer, CustomersFilter, CustomerCreateAttrs, CustomerEditAttrs } from './types';

type CustomersQuery = {
    filter: CustomersFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'customers';

const extraInvalidatedTags = TAG_TYPES.filter((tag) => tag !== 'customers');

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME, extraInvalidatedTags);

const customersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCustomers: builder.query<SuccessResponse<Customer>, CustomersQuery>({
            query: ({ filter, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'capacity_group',
                fields: {
                    capacity_groups: 'name',
                },
                filter,
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getCustomer: builder.query<SuccessResponse<Customer>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'capacity_group',
                fields: {
                    capacity_groups: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postCustomer: builder.mutation<SuccessResponse<Customer>, CustomerCreateAttrs>({
            query: ({
                id,
                capacity_group_id,
                capacity_limit,
                sip_account_limit,
                trm_mode,
                ...attributes
            }) => {
                const requestAttrs: Record<string, string> = { ...attributes };

                if (trm_mode !== 'default') {
                    requestAttrs.trm_mode = trm_mode;
                }

                if (capacity_limit) {
                    requestAttrs.capacity_limit = capacity_limit;
                }

                if (sip_account_limit) {
                    requestAttrs.sip_account_limit = sip_account_limit;
                }

                return {
                    url: RESOURCE_NAME,
                    method: 'POST',
                    data: {
                        id,
                        type: RESOURCE_NAME,
                        attributes: requestAttrs,
                        relationships: {
                            capacity_group: {
                                data: {
                                    id: capacity_group_id || null,
                                    type: 'capacity_groups',
                                },
                            },
                        },
                    },
                };
            },
            invalidatesTags: tagsInvalidators.create,
        }),
        patchCustomer: builder.mutation<SuccessResponse<Customer>, { id: string; values: CustomerEditAttrs }>(
            {
                query: ({
                    id,
                    values: { capacity_group_id, capacity_limit, sip_account_limit, trm_mode, ...attributes },
                }) => {
                    const requestAttrs: Record<string, unknown> = {
                        capacity_limit: capacity_limit === '' ? null : capacity_limit,
                        sip_account_limit: sip_account_limit === '' ? null : sip_account_limit,
                        ...attributes,
                    };

                    if (trm_mode === 'operator' || trm_mode === 'operator_customer') {
                        requestAttrs.trm_mode = trm_mode;
                    }

                    return {
                        url: `${RESOURCE_NAME}/${id}`,
                        method: 'PATCH',
                        data: {
                            id,
                            type: RESOURCE_NAME,
                            attributes: requestAttrs,
                            relationships: {
                                capacity_group: {
                                    data: {
                                        id: capacity_group_id || null,
                                        type: 'capacity_groups',
                                    },
                                },
                            },
                        },
                    };
                },
                invalidatesTags: tagsInvalidators.update,
            },
        ),
        deleteCustomer: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetCustomersQuery,
    useGetCustomerQuery,
    usePostCustomerMutation,
    usePatchCustomerMutation,
    useDeleteCustomerMutation,
} = customersApi;
