import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'app/hooks';
import { routes } from 'shared/routes';
import { CustomersListPage } from './list';
import { CustomerShowPage } from './show';
import { CustomerCreatePage } from './create';
import { CustomerEditPage } from './edit';

export const CustomersPages: React.FC = () => {
    const isCreateAllowed = useSelector((state) => state.auth.customer_create_allowed);

    return (
        <Switch>
            <Route exact path={routes.customers.list}>
                <CustomersListPage />
            </Route>
            <Route exact path={routes.customers.show}>
                <CustomerShowPage />
            </Route>
            {isCreateAllowed && (
                <Route exact path={routes.customers.create}>
                    <CustomerCreatePage />
                </Route>
            )}
            <Route exact path={routes.customers.edit}>
                <CustomerEditPage />
            </Route>
            <Route path="*">
                <Redirect to={routes.customers.list} />
            </Route>
        </Switch>
    );
};
