import React from 'react';
import { useParams } from 'react-router-dom';
import { ExternalAccountEdit } from 'features/external-accounts';
import { PageLayout } from 'shared/ui/layouts';

export const ExternalAccountEditPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <PageLayout.Header title={`External Account #${id}`} />
            <PageLayout.Content>
                <ExternalAccountEdit id={id} />
            </PageLayout.Content>
        </PageLayout>
    );
};
