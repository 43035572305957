import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    ExternalAccountRoute,
    ExternalAccountRouteEditAttrs,
    usePatchExternalAccountMutation,
} from 'entities/external-account-routes';
import { routes } from 'shared/routes';
import { FormLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';

interface Props {
    externalAccount: ExternalAccountRoute;
}

export const Form: React.FC<Props> = ({ externalAccount }) => {
    const { id } = externalAccount;

    const showPath = routes.externalAccounts.show.replace(':id', encodeURIComponent(id));

    const { push } = useHistory();

    const [patchExternalAccount] = usePatchExternalAccountMutation();

    const formik = useFormik<ExternalAccountRouteEditAttrs>({
        initialValues: {
            available_number_id: externalAccount.available_did_number?.id || '',
        },
        onSubmit: async (values, { setErrors }) => {
            await patchExternalAccount({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('External Account updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <FormContext formik={formik}>
            <Fields
                customerId={externalAccount.customer.id}
                initialNumber={externalAccount.available_did_number}
            />
            <FormLayout.Footer backPath={showPath} submitText="Save" />
        </FormContext>
    );
};
