import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { GatewaySelect, CustomersSelect } from 'entities/form-selects';
import { TextInput } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

interface Props {
    customerId: string;
}

export const Fields: React.FC<Props> = ({ customerId }) => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <CustomersSelect labelText={<LabelInfo title="Customer" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <GatewaySelect customerId={customerId} labelText={<LabelInfo title="Gateway" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="src_prefix" labelText={<LabelInfo title="SRC prefix" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="dst_prefix" labelText={<LabelInfo title="DST prefix" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="src_rewrite_rule" labelText={<LabelInfo title="SRC rewrite rule" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="src_rewrite_result" labelText={<LabelInfo title="SRC rewrite result" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="dst_rewrite_rule" labelText={<LabelInfo title="DST rewrite rule" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="dst_rewrite_result" labelText={<LabelInfo title="DST rewrite result" />} />
        </FormLayout.Field>
    </FormLayout>
);
