import { Entity } from 'shared/types/resources';

export enum TERMINATION_MODE {
    SYSTEM = 'system',
    CUSTOMER = 'customer',
    SYSTEM_CUSTOMER = 'system_customer',
    OPERATOR = 'operator',
    OPERATOR_CUSTOMER = 'operator_customer',
}

export type Language = 'EN' | 'RU' | 'LT' | 'LV';

export type Customer = Entity<{
    name: string;
    language: Language;
    capacity_limit: number | null;
    sip_account_limit: number | null;
    trm_mode: TERMINATION_MODE;
    capacity_group?: Entity<{ name: string }>;
}>;

// List
export type CustomersFilter = Partial<{
    id: string;
    capacity_limit: string;
    sip_account_limit: string;
    capacity_group_id: string;
}>;

// Create
export type CustomerCreateAttrs = {
    id: string;
    name: string;
    language: Language;
    capacity_limit: string;
    sip_account_limit: string;
    capacity_group_id: string;
    trm_mode: TERMINATION_MODE | 'default';
};

// Edit
export type CustomerEditAttrs = {
    name: string;
    capacity_limit: number | null | '';
    sip_account_limit: number | null | '';
    capacity_group_id: string;
    trm_mode: TERMINATION_MODE;
};
