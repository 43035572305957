import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { TerminationRoute } from 'entities/termination-routes';
import { BooleanCell } from 'shared/ui/cells';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<TerminationRoute> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Termination Route ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
    },
    { key: 'gateway_id', title: 'Gateway ID', render: (_, row) => row.gateway.id },
    { key: 'gateway_name', title: 'Gateway', render: (_, row) => row.gateway.name },
    { key: 'customer_id', title: 'Customer ID', render: (_, row) => row.customer?.id || '' },
    { key: 'customer_name', title: 'Customer', render: (_, row) => row.customer?.name || '' },
    {
        key: 'operator',
        title: 'Operator',
        align: 'center',
        render: function OperatorColumn(_, row) {
            return <BooleanCell value={row.operator} />;
        },
    },
    {
        key: 'src_prefix',
        dataIndex: 'src_prefix',
        title: 'SRC prefix',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'src_prefix' && sorter.order,
    },
    {
        key: 'dst_prefix',
        dataIndex: 'dst_prefix',
        title: 'DST prefix',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'dst_prefix' && sorter.order,
    },
    {
        key: 'src_rewrite_rule',
        dataIndex: 'src_rewrite_rule',
        title: 'SRC rewrite rule',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'src_rewrite_rule' && sorter.order,
    },
    {
        key: 'src_rewrite_result',
        dataIndex: 'src_rewrite_result',
        title: 'SRC rewrite result',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'src_rewrite_result' && sorter.order,
    },
    {
        key: 'dst_rewrite_rule',
        dataIndex: 'dst_rewrite_rule',
        title: 'DST rewrite rule',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'dst_rewrite_rule' && sorter.order,
    },
    {
        key: 'dst_rewrite_result',
        dataIndex: 'dst_rewrite_result',
        title: 'DST rewrite result',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'dst_rewrite_result' && sorter.order,
    },
];
