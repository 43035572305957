import React from 'react';
import { Header } from './header';
import { Content } from './content';
import { Footer } from './footer';
import styles from './styles.module.scss';

export const Page: React.FC & {
    Header: typeof Header;
    Content: typeof Content;
    Footer: typeof Footer;
} = ({ children }) => <div className={styles.page}>{children}</div>;

Page.Header = Header;
Page.Content = Content;
Page.Footer = Footer;
