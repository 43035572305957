import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageLoader } from 'shared/ui/spinners';
import { useGetExternalAccountQuery } from 'entities/external-account-routes';
import { routes } from 'shared/routes';
import { AccountInfo } from './account-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetExternalAccountQuery(id);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!data) {
        // In case user tries to open external account with broken ID (including % or № sign)
        // because we're not able to decode correct id. See
        // https://jira.in.didww.com/browse/PBXOPUI-60 (latest comment)
        return <Redirect to={routes.error.somethingWrong} />;
    }

    const [externalAccount] = data.resources;

    return <AccountInfo externalAccount={externalAccount} />;
};
