import dayjs from 'dayjs';
import { BaseAxisProps } from 'recharts/types/util/types';
import { ActiveCallsView } from '../types';

export function getTickFormatter(view: ActiveCallsView): BaseAxisProps['tickFormatter'] {
    if (view === 'hour_of_day') return;

    const showHours = view === 'hour';

    return (date) => {
        const format = `DD MMM${showHours ? ' HH:mm' : ''}`;

        return dayjs(date).format(format);
    };
}
