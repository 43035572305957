import React, { useCallback } from 'react';
import cn from 'classnames';
import { ActiveIcon } from 'shared/ui/buttons';
import { useKeyPress } from 'shared/lib/hooks/events';
import { GeneralModalProps } from '../types';
import styles from './styles.module.scss';

export const GeneralModal: React.FC<GeneralModalProps> = (props) => {
    const { classNames, closable = true, width, title, onCancel, footer, sideMenu, children } = props;

    const onKeyPressed = useCallback(
        (key: string): void => {
            if (key === 'Escape') {
                onCancel();
            }
        },
        [onCancel],
    );

    useKeyPress(onKeyPressed);

    return (
        <div className={styles.modalRoot}>
            <div className={styles.modalMask}></div>
            <div role="dialog" className={styles.modalWrapper}>
                <div
                    role="document"
                    className={cn(styles.modal, classNames?.modal)}
                    style={{ maxWidth: width }}
                >
                    {sideMenu && <div className={styles.sideMenu}>{sideMenu}</div>}
                    <div className={styles.modalContent}>
                        {closable && (
                            <ActiveIcon
                                buttonType="button"
                                icon="close-modal"
                                iconSize={19}
                                className={styles.modalButton}
                                onClick={onCancel}
                            />
                        )}
                        {title && <h3 className={cn(styles.modalHeader, classNames?.header)}>{title}</h3>}
                        {children && (
                            <main className={cn(styles.modalBody, classNames?.body)}>{children}</main>
                        )}
                        {footer && (
                            <footer className={cn(styles.modalFooter, classNames?.footer)}>{footer}</footer>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
