import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { ExternalAccountRoute } from 'entities/external-account-routes';
import { AvailableNumberSelect } from 'entities/form-selects';
import { FormLayout } from 'shared/ui/layouts';

interface Props {
    customerId: string;
    initialNumber: ExternalAccountRoute['available_did_number'];
}

export const Fields: React.FC<Props> = ({ customerId, initialNumber }) => (
    <FormLayout>
        <FormLayout.Field>
            <AvailableNumberSelect
                customerId={customerId}
                labelText={<LabelInfo title="External caller ID" />}
                initialResources={initialNumber ? [initialNumber] : undefined}
            />
        </FormLayout.Field>
    </FormLayout>
);
