import { useState, useEffect, DependencyList } from 'react';

export function useAsyncEffect(cb: () => Promise<void>, deps: DependencyList = []): boolean {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function invokeCb(): Promise<void> {
            await cb();

            setIsLoading(false);
        }

        invokeCb();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return isLoading;
}
