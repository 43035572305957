import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'shared/ui/toast';
import { ConfirmModalManager } from 'features/confirm-modal-manager';
import {
    NotFoundPage,
    ServerDeployPage,
    ServerErrorPage,
    SomethingWentWrongPage,
    OfflinePage,
} from 'pages/errors';
import { routes } from 'shared/routes';
import { AuthProvider } from './auth-provider';
import { SentryWrapper } from './sentry-wrapper';
import { GlobalSetup } from './global-setup';
import { Guest, User } from './routes';
import { store } from './store';
import '../shared/assets/styles/App.css';

const App: React.FC = () => {
    return (
        <SentryWrapper>
            <Provider store={store}>
                <HashRouter>
                    <GlobalSetup>
                        <Switch>
                            <Route path={routes.error.notFound}>
                                <NotFoundPage />
                            </Route>
                            <Route path={routes.error.server}>
                                <ServerErrorPage />
                            </Route>
                            <Route path={routes.error.deploy}>
                                <ServerDeployPage />
                            </Route>
                            <Route path={routes.error.somethingWrong}>
                                <SomethingWentWrongPage />
                            </Route>
                            <Route path={routes.error.offline}>
                                <OfflinePage />
                            </Route>
                            <AuthProvider user={<User />} guest={<Guest />} />
                        </Switch>
                        <ToastContainer />
                        <ConfirmModalManager />
                    </GlobalSetup>
                </HashRouter>
            </Provider>
        </SentryWrapper>
    );
};

export default App;
