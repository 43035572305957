import React, { useCallback } from 'react';
import { Page } from 'shared/types/resources';
import { UiPagination } from './ui';

export interface PaginationProps {
    totalRecords: number | undefined;
    page: Page;
    onChangePage: (page: Page) => void;
    pageSizeOptions?: string[];
}

const DEFAULT_PAGE_SIZE = 10;

export const Pagination: React.FC<PaginationProps> = ({
    onChangePage,
    totalRecords,
    page,
    pageSizeOptions,
}) => {
    const handleOnChangePage = useCallback(
        (pageNumber: number, pageSize: number | undefined) => {
            const newSize = pageSize || DEFAULT_PAGE_SIZE;

            // If page size has changed we should set page to 1
            const newPage = page.size !== newSize ? 1 : pageNumber;

            onChangePage({
                number: newPage,
                size: newSize,
            });
        },
        [onChangePage, page.size],
    );

    if (!totalRecords || totalRecords <= DEFAULT_PAGE_SIZE) return null;

    return (
        <UiPagination
            pageSizeOptions={pageSizeOptions}
            onChange={handleOnChangePage}
            current={page.number}
            total={totalRecords}
            pageSize={page.size}
        />
    );
};
