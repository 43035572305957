import React from 'react';
import { PageLayout } from 'shared/ui/layouts';
import { Settings } from 'features/settings/edit';

export const Page: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header title="Settings" />
            <PageLayout.Content>
                <Settings />
            </PageLayout.Content>
        </PageLayout>
    );
};
