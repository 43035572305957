import React, { useEffect, useState } from 'react';
import { Transition } from 'shared/ui/transition';
import transition from './transition.module.scss';

interface Props {
    isHidden: boolean;
}

export const Drawer: React.FC<Props> = ({ isHidden, children }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(!isHidden);
    }, [isHidden]);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <Transition classNames={transition} timeout={350} in={isVisible}>
            {children}
        </Transition>
    );
};
