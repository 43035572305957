import { batch } from 'react-redux';
import { AppThunk } from 'app/store';
import { apiRequest, ApiResponse, apiSlice } from 'shared/api';
import { AuthValues } from '../types';
import { clearSession } from './slice';
import { setSession } from 'entities/auth/lib/set-session';
import {
    USERNAME_KEY,
    CUSTOMER_ALLOW_KEY,
    EXTERNAL_ACCOUNT_ALLOW_KEY,
    TWO_FACTOR_AUTH_ENABLE_KEY,
} from '../config';

type LoginAttrs = {
    customer_create_allowed: boolean;
    external_account_create_allowed: boolean;
    two_factor_auth_enabled: boolean;
};

export const signIn =
    (values: AuthValues): AppThunk<ApiResponse<LoginAttrs>> =>
    async (dispatch) => {
        const response = await apiRequest<LoginAttrs>({
            url: 'logins',
            method: 'POST',
            data: { type: 'logins', attributes: values },
        });

        if (response.data) {
            const [
                {
                    id: username,
                    customer_create_allowed,
                    external_account_create_allowed,
                    two_factor_auth_enabled,
                },
            ] = response.data.resources;

            if (two_factor_auth_enabled) {
                return response;
            } else {
                setSession(
                    {
                        username: username,
                        customer_create_allowed: customer_create_allowed,
                        external_account_create_allowed: external_account_create_allowed,
                        two_factor_auth_enabled: two_factor_auth_enabled,
                    },
                    dispatch,
                );
            }
        }

        return response;
    };

export const signOut =
    ({ rememberPathname = false } = {}): AppThunk =>
    async (dispatch) => {
        localStorage.removeItem(USERNAME_KEY);
        localStorage.removeItem(CUSTOMER_ALLOW_KEY);
        localStorage.removeItem(EXTERNAL_ACCOUNT_ALLOW_KEY);
        localStorage.removeItem(TWO_FACTOR_AUTH_ENABLE_KEY);

        await apiRequest({
            url: 'logins',
            method: 'DELETE',
            data: { type: 'logins' },
        });

        batch(() => {
            dispatch(apiSlice.util.resetApiState());
            dispatch(clearSession({ rememberPathname }));
        });
    };
