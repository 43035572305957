import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetTerminationRouteQuery } from 'entities/termination-routes';
import { RouteInfo } from './route-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetTerminationRouteQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [terminationRoute] = data.resources;

    return <RouteInfo terminationRoute={terminationRoute} />;
};
