import { COLORS, ComplexityRequirements, Result, ValidationResult } from './types';

export function validatePassword(value: string): ValidationResult {
    const result = {
        color: COLORS.GREEN,
        message: '',
        complexityRequirements: {
            uppercase: true,
            lowercase: true,
            digits: true,
            symbols: true,
        },
        progress: 100,
    };

    const requiredValidators = [validateIdenticalChars, validateMinLength, validateMaxLength];
    const requiredValidatorsResults = requiredValidators.map((validate) => validate(value));
    const allRequiredValidatorsArePassed = requiredValidatorsResults.every((result) => result.isValid);

    const complexityResults = {
        uppercase: validateUppercase(value),
        lowercase: validateLowercase(value),
        digits: validateDigits(value),
        symbols: validateSymbols(value),
    };
    const complexityResultValues = Object.values(complexityResults);
    const complexityLevel = complexityResultValues.filter((result) => result.isValid).length;

    result.complexityRequirements = getComplexityRequirements(complexityResults);

    if (allRequiredValidatorsArePassed && complexityLevel === 4) {
        result.color = COLORS.GREEN;
        result.message = 'Password meets the requirements';
        result.progress = 100;
    } else if (allRequiredValidatorsArePassed && complexityLevel === 3) {
        result.color = COLORS.ORANGE;
        result.message = 'Password meets the minimum requirements';
        result.progress = 80;
    } else if (allRequiredValidatorsArePassed && complexityLevel < 3) {
        result.color = COLORS.RED;
        result.message = 'Password does not meet the requirements';
        result.progress = 60;
    } else {
        result.color = COLORS.RED;
        result.message =
            requiredValidatorsResults.find((result) => !result.isValid)?.message ??
            'Password does not meet the requirements';
        result.progress = complexityLevel * 20;
    }

    if (value === '') {
        result.message = '';
    }

    return result;
}

function getComplexityRequirements(complexityResults: Record<string, Result>): ComplexityRequirements {
    return Object.entries(complexityResults).reduce<Record<string, boolean>>((accum, entry) => {
        const [key, result] = entry;
        accum[key] = result.isValid;
        return accum;
    }, {}) as ComplexityRequirements;
}

export const passwordValidator = (value: string): string | undefined => {
    const { message, color } = validatePassword(value);

    if (color === COLORS.RED) {
        return message;
    }

    return undefined;
};

function validateLowercase(value: string): Result {
    return {
        message: '',
        isValid: /[a-z]/.test(value),
    };
}

function validateUppercase(value: string): Result {
    return {
        message: '',
        isValid: /[A-Z]/.test(value),
    };
}

function validateDigits(value: string): Result {
    return {
        message: '',
        isValid: /[0-9]/.test(value),
    };
}

function validateSymbols(value: string): Result {
    return {
        message: '',
        isValid: /[^A-Za-z0-9]/.test(value),
    };
}

function validateMinLength(value: string): Result {
    const hasError = value.length < 8;
    return {
        message: hasError ? 'Password is too short' : '',
        isValid: !hasError,
    };
}

function validateMaxLength(value: string): Result {
    const hasError = value.length > 128;
    return {
        message: hasError ? 'Password is too long' : '',
        isValid: !hasError,
    };
}

function validateIdenticalChars(value: string): Result {
    const hasError = /(.)\1{2,}/.test(value);
    return {
        message: hasError ? 'Password contains 3 or more consecutive identical characters' : '',
        isValid: !hasError,
    };
}
