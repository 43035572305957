import React from 'react';
import { Button, Submit } from 'shared/ui/buttons';
import { FormikProps } from 'formik';
import styles from './styles.module.scss';

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    closeChangePasswordModal?: () => void;
}

const Cancel: React.FC<Props> = ({ formik, closeChangePasswordModal }) => {
    return (
        <Button
            disabled={formik.isSubmitting}
            onClick={closeChangePasswordModal}
            text="Cancel"
            isAutoFocus
            ghost
        />
    );
};

const Confirm: React.FC<Props> = ({ formik }) => {
    return <Submit disabled={formik.isSubmitting} text="Confirm" />;
};

export const Footer: React.FC<{ key: string }> & {
    Cancel: typeof Cancel;
    Confirm: typeof Confirm;
} = ({ children, key }) => (
    <div className={styles.action} key={key}>
        {children}
    </div>
);

Footer.Cancel = Cancel;
Footer.Confirm = Confirm;
