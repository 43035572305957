import { useState, useCallback } from 'react';
import axios, { CancelTokenSource, CancelToken } from 'axios';

interface CancelationActions {
    createCancelToken: () => CancelToken;
    cancelRequest: () => void;
}

export const useRequestCancelation = (): CancelationActions => {
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | undefined>();

    const createCancelToken = useCallback(() => {
        const currentCancelSource = axios.CancelToken.source();

        setCancelTokenSource(currentCancelSource);

        return currentCancelSource.token;
    }, []);

    const cancelRequest = useCallback(() => {
        cancelTokenSource?.cancel();
    }, [cancelTokenSource]);

    return { createCancelToken, cancelRequest };
};
