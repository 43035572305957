import { useEffect, useState } from 'react';

// We need this hook because isLoading is triggered each time query params has changed
export const useListLoading = (isQueryLoading: boolean): boolean => {
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        if (!isQueryLoading) {
            setLoading(false);
        }
    }, [isQueryLoading]);

    return isLoading;
};
