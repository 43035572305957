import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    CapacityGroup,
    CapacityGroupsFilter,
    CapacityGroupCreateAttrs,
    CapacityGroupEditAttrs,
    CapacityGroupsMeta,
} from './types';

type CapacityGroupsQuery = {
    filter: CapacityGroupsFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'capacity_groups';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME, ['customers']);

const capacityGroupsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCapacityGroups: builder.query<
            SuccessResponse<CapacityGroup, CapacityGroupsMeta>,
            CapacityGroupsQuery
        >({
            query: ({ filter, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                filter,
                page,
                sort: sort || '-name',
            }),
            keepUnusedDataFor: 0,
            providesTags: tagsProvider.list,
        }),
        getCapacityGroup: builder.query<SuccessResponse<CapacityGroup>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
            providesTags: tagsProvider.show,
        }),
        postCapacityGroup: builder.mutation<SuccessResponse<CapacityGroup>, CapacityGroupCreateAttrs>({
            query: (attributes) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes,
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchCapacityGroup: builder.mutation<
            SuccessResponse<CapacityGroup>,
            { id: string; values: CapacityGroupEditAttrs }
        >({
            query: ({ id, values }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    attributes: values,
                },
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteCapacityGroup: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetCapacityGroupsQuery,
    useGetCapacityGroupQuery,
    usePostCapacityGroupMutation,
    usePatchCapacityGroupMutation,
    useDeleteCapacityGroupMutation,
} = capacityGroupsApi;
