import { useState, useCallback } from 'react';
import { useDispatch } from 'app/hooks';
import { actions } from '../../model';
import { AvailableTableConfig, useAvailableTable } from './use-available-table';
import { AssignedTableConfig, useAssignedTable } from './use-assigned-table';

interface ManageCustomersConfig {
    isPushing: boolean;
    assignToGroup: (ids: string[]) => Promise<void>;
    removeFromGroup: (ids: string[]) => Promise<void>;
    availableState: AvailableTableConfig;
    assignedState: Omit<
        AssignedTableConfig,
        'assignedCustomersIds' | 'addAssignedCustomers' | 'removeAssignedCustomers'
    >;
}

export const useManageCustomers = (groupId: string): ManageCustomersConfig => {
    const dispatch = useDispatch();

    const [isPushing, setPushing] = useState(false);

    const availableState = useAvailableTable(groupId);
    const { assignedCustomersIds, addAssignedCustomers, removeAssignedCustomers, ...assignedState } =
        useAssignedTable(groupId);

    const { resetPaginationOfAvailableCustomers, dataSource: availableCustomers } = availableState;

    const assignToGroup = useCallback(
        async (ids: string[]) => {
            setPushing(true);

            const mergedCustomersIds = [...assignedCustomersIds, ...ids];

            const res = await dispatch(actions.patchGroupCustomers(groupId, mergedCustomersIds));

            if (res.data) {
                const addedCustomers = availableCustomers.filter(({ id }) => ids.includes(id));

                addAssignedCustomers(addedCustomers);
            }

            setPushing(false);
            resetPaginationOfAvailableCustomers();
        },
        [
            addAssignedCustomers,
            assignedCustomersIds,
            availableCustomers,
            dispatch,
            groupId,
            resetPaginationOfAvailableCustomers,
        ],
    );

    const removeFromGroup = useCallback(
        async (ids: string[]) => {
            setPushing(true);

            const filteredCustomersIds = assignedCustomersIds.filter(
                (customerId) => !ids.includes(customerId),
            );

            const res = await dispatch(actions.patchGroupCustomers(groupId, filteredCustomersIds));

            if (res.data) {
                removeAssignedCustomers(ids);
            }

            setPushing(false);
            resetPaginationOfAvailableCustomers();
        },
        [
            assignedCustomersIds,
            dispatch,
            groupId,
            resetPaginationOfAvailableCustomers,
            removeAssignedCustomers,
        ],
    );

    return {
        isPushing,
        assignToGroup,
        removeFromGroup,
        availableState,
        assignedState,
    };
};
