import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    USERNAME_KEY,
    CUSTOMER_ALLOW_KEY,
    EXTERNAL_ACCOUNT_ALLOW_KEY,
    TWO_FACTOR_AUTH_ENABLE_KEY,
} from '../config';

interface AuthState {
    remember_pathname: boolean;
    username: string;
    customer_create_allowed: boolean;
    external_account_create_allowed: boolean;
    two_factor_auth_enabled: boolean;
}

const getBooleanStorageValue = (key: string): boolean => {
    const value = localStorage.getItem(key);

    return value === 'true';
};

const initialState = {
    remember_pathname: true,
    username: localStorage.getItem(USERNAME_KEY) || '',
    customer_create_allowed: getBooleanStorageValue(CUSTOMER_ALLOW_KEY),
    external_account_create_allowed: getBooleanStorageValue(EXTERNAL_ACCOUNT_ALLOW_KEY),
    two_factor_auth_enabled: getBooleanStorageValue(TWO_FACTOR_AUTH_ENABLE_KEY),
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        saveSession: (_, action: PayloadAction<Omit<AuthState, 'remember_pathname'>>) => ({
            ...action.payload,
            remember_pathname: false,
        }),
        clearSession: (_, action: PayloadAction<{ rememberPathname: boolean }>) => ({
            remember_pathname: action.payload.rememberPathname,
            username: '',
            customer_create_allowed: false,
            external_account_create_allowed: false,
            two_factor_auth_enabled: false,
        }),
    },
});

export const {
    reducer: authReducer,
    actions: { saveSession, clearSession },
} = authSlice;
