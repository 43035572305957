import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { SignIn } from 'pages/sign-in';
import { TwoFactorAuth } from 'pages/two-factor-auth';

export const Guest: React.FC = () => (
    <Switch>
        <Route exact path={routes.signIn}>
            <SignIn />
        </Route>
        <Route exact path={routes.twoFactorAuth}>
            <TwoFactorAuth />
        </Route>
        <Route path="*">
            <Redirect to={routes.signIn} />
        </Route>
    </Switch>
);
