import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch } from 'app/hooks';
import { AuthValues, signIn } from 'entities/auth';
import { FormContext } from 'entities/form-context';
import { ErrorCode } from 'shared/api';
import { Drawer } from './drawer';
import { Fields } from './fields';
import { routes } from 'shared/routes';
import { message } from 'shared/message';
import { usePostTwoFactorDeliveriesMutation } from 'entities/two-factor-auth';

export const Form: React.FC = () => {
    const dispatch = useDispatch();

    const [isLoggined, setLoggined] = useState(false);

    const { push } = useHistory();

    const [postTwoFactorDeliveries] = usePostTwoFactorDeliveriesMutation();

    const formik = useFormik<AuthValues>({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: async (values, { setErrors }) => {
            const response = await dispatch(signIn(values));

            if (response.data) {
                const [{ two_factor_auth_enabled }] = response.data.resources;

                if (two_factor_auth_enabled) {
                    await postTwoFactorDeliveries('')
                        .unwrap()
                        .then(() => {
                            push(routes.twoFactorAuth);

                            message.success('A verification code has been sent. Please check your email');
                        });
                } else {
                    setLoggined(true);
                }
            } else if (response.error.code === ErrorCode.Validation) {
                setErrors(response.error.errors);
            }
        },
    });

    return (
        <Drawer isHidden={isLoggined}>
            <FormContext formik={formik}>
                <Fields />
            </FormContext>
        </Drawer>
    );
};
