import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { CapacityGroup } from 'entities/capacity-groups';
import { TruncateTooltip } from 'shared/ui/truncate-tooltip';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<CapacityGroup> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Capacity Group ID',
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
        render: function NameCell(_, row) {
            return <TruncateTooltip text={row.name} />;
        },
    },
    {
        key: 'customers_count',
        dataIndex: 'customers_count',
        title: 'Customers count',
    },
    {
        key: 'capacity',
        dataIndex: 'capacity',
        title: 'Capacity',
        sorter: true,
        sortOrder: sorter.column === 'capacity' && sorter.order,
    },
];
