import { useEffect } from 'react';

export function useLockBodyScroll(isLocked = true): void {
    useEffect(() => {
        if (isLocked) {
            document.body.style.overflow = 'hidden';
        }

        return (): void => {
            document.body.style.overflow = 'unset';
        };
    }, [isLocked]);
}
