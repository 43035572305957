import { SuccessResponse, apiSlice, getTagsProviders } from 'shared/api';
import { OutgoingActiveCalls, OutgoingActiveCallsFilter } from './types';

const RESOURCE_NAME = 'outgoing_active_calls';

const tagsProvider = getTagsProviders(RESOURCE_NAME);

type QueryFilter = Omit<OutgoingActiveCallsFilter, 'view'>;

const activeCallsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getActiveCalls: builder.query<SuccessResponse<OutgoingActiveCalls>, QueryFilter>({
            query: (params) => ({
                url: `charts/${RESOURCE_NAME}`,
                method: 'GET',
                params,
            }),
            providesTags: tagsProvider.list,
        }),
    }),
});

export const { useGetActiveCallsQuery } = activeCallsApi;
