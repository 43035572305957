import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { AvailableNumbersListPage } from './list';
import { AvailableNumberShowPage } from './show';
import { AvailableNumberCreatePage } from './create';
import { AvailableNumberEditPage } from './edit';

export const AvailableNumbersPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.availableNumbers.list}>
            <AvailableNumbersListPage />
        </Route>
        <Route exact path={routes.availableNumbers.show}>
            <AvailableNumberShowPage />
        </Route>
        <Route exact path={routes.availableNumbers.create}>
            <AvailableNumberCreatePage />
        </Route>
        <Route exact path={routes.availableNumbers.edit}>
            <AvailableNumberEditPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.availableNumbers.list} />
        </Route>
    </Switch>
);
