import { AppThunk } from 'app/store';
import { loadByQuery } from 'shared/api';

export const fetchCustomersOptions =
    (page: number, name?: string): AppThunk =>
    (dispatch) => {
        return dispatch(
            loadByQuery('customers', {
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...(name ? { name } : undefined),
                },
                page: {
                    number: page,
                    size: 10,
                },
                sort: '-id',
            }),
        );
    };
