import React from 'react';
import { CHART_DATE_KEY } from '../../config';
import styles from './styles.module.scss';

export const CustomLegend: React.FC<{ total: number | null }> = ({ total }) => (
    <div className={styles.legend}>
        <div className={styles.callsNumber}>
            <div className={styles.blueCircle} />
            {CHART_DATE_KEY}
        </div>
        {total !== null && <div className={styles.total}>{`System Capacity: ${total}`}</div>}
    </div>
);
