import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { SipDeviceRoute, AllowedIPCell } from 'entities/sip-accounts';
import { BooleanCell } from 'shared/ui/cells';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<SipDeviceRoute> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'SIP-Account ID',
    },
    {
        key: 'username',
        dataIndex: 'username',
        title: 'Username',
        sorter: true,
        sortOrder: sorter.column === 'username' && sorter.order,
    },
    {
        key: 'password',
        dataIndex: 'password',
        title: 'Password',
    },
    {
        key: 'customer_id',
        title: 'Customer ID',
        render: (_, row) => row.customer.id,
    },
    {
        key: 'customer_name',
        title: 'Customer',
        render: (_, row) => row.customer.name,
    },
    {
        key: 'caller_id_name',
        dataIndex: 'caller_id_name',
        title: 'Caller ID name',
        sorter: true,
        sortOrder: sorter.column === 'caller_id_name' && sorter.order,
    },
    {
        key: 'allow_external_calls',
        dataIndex: 'allow_external_calls',
        title: 'Allow outbound calls',
        sorter: true,
        sortOrder: sorter.column === 'allow_external_calls' && sorter.order,
        align: 'center',
        render: function AllowOutboundCallsColumn(_, row) {
            return <BooleanCell value={row.allow_external_calls} />;
        },
    },
    {
        key: 'internal_caller_id',
        dataIndex: 'internal_caller_id',
        title: 'Internal caller ID',
    },
    {
        key: 'external_caller_id',
        dataIndex: 'external_caller_id',
        title: 'External caller ID',
    },
    {
        key: 'is_online',
        dataIndex: 'is_online',
        title: 'Is online',
        align: 'center',
        render: function IsOnlineColumn(_, row) {
            return <BooleanCell value={row.is_online} />;
        },
    },
    {
        key: 'user_agent',
        dataIndex: 'user_agent',
        title: 'User agent',
    },
    {
        key: 'allowed_ips',
        dataIndex: 'allowed_ips',
        title: 'Allowed IPs',
        defaultHidden: true,
        render: function AllowedIpsColumn(_, row) {
            return <AllowedIPCell sipDeviceRoute={row} />;
        },
    },
    {
        key: 'use_allowed_ips',
        dataIndex: 'use_allowed_ips',
        defaultHidden: true,
        title: 'Use allowed IPs',
        align: 'center',
        render: function UseAllowedIPsColumn(_, row) {
            return <BooleanCell value={row.use_allowed_ips} />;
        },
    },
    {
        key: 'incoming_internal',
        dataIndex: 'incoming_internal',
        defaultHidden: true,
        title: 'Rec inbound internal IPs',
        sorter: true,
        sortOrder: sorter.column === 'incoming_internal' && sorter.order,
        align: 'center',
        render: function InboundInternalColumn(_, row) {
            return <BooleanCell value={row.incoming_internal} />;
        },
    },
    {
        key: 'incoming_external',
        dataIndex: 'incoming_external',
        defaultHidden: true,
        title: 'Rec inbound external',
        sorter: true,
        sortOrder: sorter.column === 'incoming_external' && sorter.order,
        align: 'center',
        render: function InboundExternalColumn(_, row) {
            return <BooleanCell value={row.incoming_external} />;
        },
    },
    {
        key: 'outgoing_internal',
        dataIndex: 'outgoing_internal',
        defaultHidden: true,
        title: 'Rec outbound internal',
        sorter: true,
        sortOrder: sorter.column === 'outgoing_internal' && sorter.order,
        align: 'center',
        render: function OutboundInternalColumn(_, row) {
            return <BooleanCell value={row.outgoing_internal} />;
        },
    },
    {
        key: 'outgoing_external',
        dataIndex: 'outgoing_external',
        defaultHidden: true,
        title: 'Rec outbound external',
        sorter: true,
        sortOrder: sorter.column === 'outgoing_external' && sorter.order,
        align: 'center',
        render: function OutboundExternalColumn(_, row) {
            return <BooleanCell value={row.outgoing_external} />;
        },
    },
    {
        key: 'on_demand',
        dataIndex: 'on_demand',
        defaultHidden: true,
        title: 'Rec on demand',
        sorter: true,
        sortOrder: sorter.column === 'on_demand' && sorter.order,
        align: 'center',
        render: function OutboundExternalColumn(_, row) {
            return <BooleanCell value={row.on_demand} />;
        },
    },
];
