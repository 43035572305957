import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput } from 'shared/ui/fields';
import { RangeDatePickerField, useDefaults } from 'shared/ui/fields/range-date-picker-field';

const optionalFilters = [
    {
        name: 'customer_id',
        label: 'Customer',
        node: (
            <ConditionalFilter.Item>
                <CustomersSelect labelText="Customer" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'termination_gateway_id',
        label: 'Termination Gateway ID',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="termination_gateway_id" labelText="Termination Gateway ID" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'termination_route_id',
        label: 'Termination Route ID',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="termination_route_id" labelText="Termination Route ID" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'aleg_code',
        label: 'legA disconnect code',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="aleg_code" labelText="legA disconnect code" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'aleg_reason',
        label: 'legA disconnect reason',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="aleg_reason" labelText="legA disconnect reason" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'src_name_in',
        label: 'Src name in',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="src_name_in" labelText="Src name in" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'src_number_in',
        label: 'Src number in',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="src_number_in" labelText="Src number in" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'dst_number_in',
        label: 'Dst number in',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="dst_number_in" labelText="Dst number in" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'orig_call_id',
        label: 'Orig call ID',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="orig_call_id" labelText="Orig call ID" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'local_tag',
        label: 'Local tag',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="local_tag" labelText="Local tag" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'global_tag',
        label: 'Global tag',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="global_tag" labelText="Global tag" />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => {
    const datePickerDefaults = useDefaults();

    return (
        <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
            <ConditionalFilter.Item size="large">
                <RangeDatePickerField
                    labelText="Time start / end"
                    fromFieldName="time_start_gteq"
                    toFieldName="time_start_lteq"
                    withTime
                    {...datePickerDefaults}
                />
            </ConditionalFilter.Item>
        </ConditionalFilter>
    );
};
