import React from 'react';
import { Button } from 'shared/ui/buttons';
import { InlineSvg } from 'shared/ui/inline-svg';
import { useDispatch } from 'app/hooks';
import { useClick } from 'shared/lib/hooks/actions';
import { postCustomerAdminSession } from '../actions';

interface Props {
    id: string;
}

export const OpenPs: React.FC<Props> = ({ id }) => {
    const dispatch = useDispatch();

    const [launchPs, isPushing] = useClick(async () => {
        const response = await dispatch(postCustomerAdminSession(id));

        if (response.data) {
            const [{ uri }] = response.data.resources;

            window.open(uri, '_blank');
        }
    });

    return (
        <Button shape="circle" ghost onClick={launchPs} isPushing={isPushing}>
            <InlineSvg type="launch" size={16} />
        </Button>
    );
};
