import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteExternalAccountMutation } from 'entities/external-account-routes';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const ExternalAccountDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteExternalAccount] = useDeleteExternalAccountMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected External Account',
        onConfirm: async (): Promise<void> => {
            await deleteExternalAccount(id)
                .unwrap()
                .then(() => {
                    replace(routes.externalAccounts.list);

                    message.success('External Account deleted');
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
