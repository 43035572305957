import React, { useCallback } from 'react';
import { OnAutocompleteLoad } from '@didww/ui-kit';
import { ComponentProps } from './types';
import { Single } from './single';
import { Multi } from './multi';
import { useRequestCancelation } from './lib';
import { useFieldError } from 'shared/lib/hooks/forms/use-field-error';

export const Autocomplete: React.FC<ComponentProps> = ({ isMulti, onLoad, ...props }) => {
    const errorMessage = useFieldError(props.name);

    const { cancelRequest, createCancelToken } = useRequestCancelation();

    const onSelectLoad = useCallback<OnAutocompleteLoad>(
        (page, search) => {
            const cancelToken = createCancelToken();

            return onLoad(page, search, cancelToken);
        },
        [onLoad, createCancelToken],
    );

    const Component = isMulti ? Multi : Single;

    return (
        <Component
            placeholder="Select"
            onLoad={onSelectLoad}
            onUnmount={cancelRequest}
            {...props}
            errorMessage={errorMessage}
        />
    );
};
