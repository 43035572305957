import React from 'react';
import { useFormik } from 'formik';
import { validator } from '../lib/validator';
import { ModalForm } from 'shared/ui/modal';
import { Fields } from './fields';
import { Footer } from './footer';
import { ChangePasswordAttrs, usePostChangePasswordMutation } from 'entities/change-password';
import { ErrorCode, QueryError } from 'shared/api';
import { message } from 'shared/message';
import styles from './styles.module.scss';

interface Props {
    closeChangePasswordModal: () => void;
}

export const Modal: React.FC<Props> = ({ closeChangePasswordModal }) => {
    const [postChangePassword] = usePostChangePasswordMutation();

    const formik = useFormik<ChangePasswordAttrs>({
        initialValues: { password: '', new_password: '', password_confirmation: '' },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await postChangePassword(values)
                .unwrap()
                .then(() => {
                    message.success('Password updated successfully');

                    closeChangePasswordModal();
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <ModalForm
            title={'Change Password'}
            width={460}
            formik={formik}
            onCancel={closeChangePasswordModal}
            classNames={styles}
            footer={[
                <Footer.Cancel
                    key="cancel"
                    formik={formik}
                    closeChangePasswordModal={closeChangePasswordModal}
                />,
                <Footer.Confirm key="confirm" formik={formik} />,
            ]}
        >
            <Fields />
        </ModalForm>
    );
};
