import React from 'react';
import { CapacityGroup } from 'entities/capacity-groups';
import { GridLayout, PageLayout } from 'shared/ui/layouts';

interface Props {
    group: CapacityGroup;
}

export const GroupInfo: React.FC<Props> = ({ group }) => (
    <>
        <PageLayout.Header title={`Capacity Group ${group.name}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Capacity Group ID">{group.id}</GridLayout.Row>
                <GridLayout.Row title="Name">{group.name}</GridLayout.Row>
                <GridLayout.Row title="Customers count">{group.customers_count || 0}</GridLayout.Row>
                <GridLayout.Row title="Capacity">{group.capacity}</GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
    </>
);
