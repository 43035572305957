import React from 'react';
import * as Sentry from '@sentry/browser';

export class SentryWrapper extends React.PureComponent {
    componentDidCatch(error: unknown, errorInfo: unknown): void {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo as Record<string, unknown>);

            Sentry.captureException(error);
        });
    }

    render(): React.ReactNode {
        return this.props.children;
    }
}
