import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { TextInput, Toggle } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

export const Fields: React.FC = () => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="number" labelText={<LabelInfo title="Number" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput
                name="external_id"
                labelText={<LabelInfo title="External ID" text="This field is optional" />}
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <Toggle name="enabled" labelText="Enabled" />
        </FormLayout.Field>
    </FormLayout>
);
