import React from 'react';
import { FormLayout } from 'shared/ui/layouts';
import { Button } from 'shared/ui/buttons';
import { AccessIps } from './access-ips';
import { Divider } from 'shared/ui/divider';
import { Spacer } from 'shared/ui/spacer';

interface Props {
    openChangePasswordModal: () => void;
    isDisabled: boolean;
}

export const Fields: React.FC<Props> = ({ openChangePasswordModal, isDisabled }) => (
    <FormLayout>
        <FormLayout.Field>
            <AccessIps fieldName="acl_ips" isDisabled={isDisabled} />
        </FormLayout.Field>
        <Divider />
        <Spacer size={15} />
        <FormLayout.Field>
            <Button text="Change Password" onClick={openChangePasswordModal} disabled={isDisabled} />
        </FormLayout.Field>
    </FormLayout>
);
