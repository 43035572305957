import React from 'react';
import { CapacityGroupsList } from 'features/capacity-groups';
import { routes } from 'shared/routes';
import { Create } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const CapacityGroupsListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header
                title="Capacity Groups"
                actions={[<Create key="create" route={routes.capacityGroups.create} />]}
            />
            <PageLayout.Content>
                <CapacityGroupsList />
            </PageLayout.Content>
        </PageLayout>
    );
};
