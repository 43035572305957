import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    Customer,
    CustomerEditAttrs,
    usePatchCustomerMutation,
    canEditTerminationMode,
} from 'entities/customers';
import { routes } from 'shared/routes';
import { FormLayout, PageLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

interface Props {
    customer: Customer;
}

export const Form: React.FC<Props> = ({ customer }) => {
    const { id } = customer;

    const showPath = routes.customers.show.replace(':id', id);

    const initialGroups = customer.capacity_group ? [customer.capacity_group] : undefined;

    const { push } = useHistory();

    const [patchCustomer] = usePatchCustomerMutation();

    const formik = useFormik<CustomerEditAttrs>({
        initialValues: {
            name: customer.name,
            capacity_limit: customer.capacity_limit,
            sip_account_limit: customer.sip_account_limit,
            capacity_group_id: customer.capacity_group?.id || '',
            trm_mode: customer.trm_mode,
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await patchCustomer({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('Customer updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    const isTrmModeEditable = canEditTerminationMode(customer);

    return (
        <>
            <PageLayout.Header title={`Customer ${customer.name}`} />
            <PageLayout.Content>
                <FormContext formik={formik}>
                    <Fields initialGroups={initialGroups} isTrmModeEditable={isTrmModeEditable} />
                    <FormLayout.Footer backPath={showPath} submitText="Save" />
                </FormContext>
            </PageLayout.Content>
        </>
    );
};
