import { SUFFIX } from './constants';

const stringToValue = (value: string): string | boolean | null => {
    switch (value) {
        case 'null':
            return null;

        case 'true':
            return true;

        case 'false':
            return false;

        default:
            return value;
    }
};

export const getSavedFilters = <T extends Record<string, unknown>>(
    storageKey: string | undefined,
): Partial<T> => {
    if (!storageKey) return {};

    const visibleFieldsString = localStorage.getItem(`${storageKey}${SUFFIX}`);

    if (!visibleFieldsString) return {};

    return visibleFieldsString.split(',').reduce((accum, configString) => {
        const [fieldName, defaultValue] = configString.split('=');

        return {
            ...accum,
            [fieldName]: stringToValue(defaultValue),
        };
    }, {});
};
