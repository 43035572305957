import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    SipDeviceRoute,
    SipDeviceRouteEditAttrs,
    usePatchSipDeviceRouteMutation,
    getUniqAllowedIps,
} from 'entities/sip-accounts';
import { FormLayout } from 'shared/ui/layouts';
import { routes } from 'shared/routes';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';

interface Props {
    sipDeviceRoute: SipDeviceRoute;
}

export const Form: React.FC<Props> = ({ sipDeviceRoute }) => {
    const { id } = sipDeviceRoute;

    const showPath = routes.sipAccounts.show.replace(':id', id);

    const { push } = useHistory();

    const [patchSipDeviceRoute] = usePatchSipDeviceRouteMutation();

    const formik = useFormik<SipDeviceRouteEditAttrs>({
        initialValues: {
            use_allowed_ips: sipDeviceRoute.use_allowed_ips,
            allowed_ips: getUniqAllowedIps(sipDeviceRoute),
        },
        onSubmit: async (values, { setErrors }) => {
            await patchSipDeviceRoute({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('SIP-Account updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    const isAllowedIpsDisabled = !formik.values.use_allowed_ips;

    return (
        <FormContext formik={formik}>
            <Fields isAllowedIpsDisabled={isAllowedIpsDisabled} />
            <FormLayout.Footer backPath={showPath} submitText="Save" />
        </FormContext>
    );
};
