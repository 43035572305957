import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteAvailableNumberMutation } from 'entities/available-did-numbers';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const AvailableNumberDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteAvailableNumber] = useDeleteAvailableNumberMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected Available Number',
        onConfirm: async (): Promise<void> => {
            await deleteAvailableNumber(id)
                .unwrap()
                .then(() => {
                    replace(routes.availableNumbers.list);

                    message.success('Available Number deleted');
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
