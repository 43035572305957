import { IncomingTrunkCdr } from 'entities/incoming-trunk-cdrs';
import { ResourceColumnsType } from 'entities/resources-table';

export const columns: ResourceColumnsType<IncomingTrunkCdr> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Incoming Trunk CDR ID',
    },
    {
        key: 'duration',
        dataIndex: 'duration',
        title: 'Duration',
    },
    {
        key: 'src_name',
        dataIndex: 'src_name',
        title: 'Src name',
    },
    {
        key: 'src_number',
        dataIndex: 'src_number',
        title: 'Src number',
    },
    {
        key: 'dst_number',
        dataIndex: 'dst_number',
        title: 'Dst number',
    },
    {
        key: 'did_number',
        dataIndex: 'did_number',
        title: 'Did number',
    },
    {
        key: 'lega_disconnect_code',
        dataIndex: 'lega_disconnect_code',
        title: 'Lega disconnect code',
    },
    {
        key: 'lega_disconnect_reason',
        dataIndex: 'lega_disconnect_reason',
        title: 'Lega disconnect reason',
    },
    {
        key: 'lega_call_id',
        dataIndex: 'lega_call_id',
        title: 'Incoming Trunk ID',
    },
    {
        key: 'time_start',
        dataIndex: 'time_start',
        title: 'Time start',
    },
    {
        key: 'time_end',
        dataIndex: 'time_end',
        title: 'Time end',
    },
    {
        key: 'time_connect',
        dataIndex: 'time_connect',
        title: 'Time connect',
    },
    {
        key: 'customer_name',
        title: 'Customer',
        render: (_, row): string => row.customer.name,
    },
];
