import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { CapacityGroupsSelect } from 'entities/form-selects';
import { OPERATOR_TERMINATION_OPTIONS_ARRAY } from 'entities/customers';
import { TextInput, SingleSelect } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

const languageOptions = [
    { value: 'EN', label: 'English' },
    { value: 'RU', label: 'Russian' },
    { value: 'LT', label: 'Lietuvių' },
    { value: 'LV', label: 'Latviešu' },
];

const trmOptions = [{ value: 'default', label: 'Default' }, ...OPERATOR_TERMINATION_OPTIONS_ARRAY];

export const Fields: React.FC = () => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="id" labelText={<LabelInfo title="Customer ID" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <SingleSelect
                name="language"
                isSearchable={false}
                options={languageOptions}
                labelText={<LabelInfo title="UI language" />}
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <CapacityGroupsSelect labelText={<LabelInfo title="Capacity Group" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput
                name="capacity_limit"
                labelText={<LabelInfo title="Capacity limit" />}
                placeholder="Unlimited"
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput
                name="sip_account_limit"
                labelText={<LabelInfo title="SIP-Account limit" />}
                placeholder="Unlimited"
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <SingleSelect
                name="trm_mode"
                isSearchable={false}
                labelText={<LabelInfo title="Termination mode" />}
                options={trmOptions}
            />
        </FormLayout.Field>
    </FormLayout>
);
