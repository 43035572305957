import { ApiError, QueryError } from '../types';

export const getQueryError = (apiError: ApiError): QueryError => {
    const hasPayloadErrors = !!apiError.payload.errors;

    if (hasPayloadErrors) {
        return {
            code: apiError.code,
            payload: apiError.payload,
            errors: apiError.errors,
            primaryErrors: apiError.primaryErrors,
            isPrimary: apiError.isPrimary,
            humanErrors: apiError.humanErrors,
            fullHumanError: apiError.fullHumanError,
        };
    }

    return {
        code: apiError.code,
        payload: { errors: [] },
        errors: {},
        primaryErrors: [],
        isPrimary: false,
        humanErrors: [],
        fullHumanError: '',
    };
};
