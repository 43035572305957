import { useState } from 'react';

type HandleClick = () => Promise<void> | void;

export function useClick(cb: HandleClick | undefined): [HandleClick, boolean] {
    const [isPushing, setIsPushing] = useState(false);

    const handleClick: HandleClick = async () => {
        if (!cb) return;

        setIsPushing(true);

        try {
            await cb();
        } finally {
            setIsPushing(false);
        }
    };

    return [handleClick, isPushing];
}
