import React from 'react';
import { TerminationGatewaysList } from 'features/termination-gateways';
import { routes } from 'shared/routes';
import { Create } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const TerminationGatewaysListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header
                title="Termination Gateways"
                actions={[<Create key="create" route={routes.terminationGateways.create} />]}
            />
            <PageLayout.Content>
                <TerminationGatewaysList />
            </PageLayout.Content>
        </PageLayout>
    );
};
