import React from 'react';
import { ActiveIcon } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    onClick: () => void;
}

export const CloseMenu: React.FC<Props> = ({ onClick }) => (
    <div className={styles.closeMenu}>
        <ActiveIcon
            className={styles.closeButton}
            buttonType="button"
            icon="close-menu"
            iconSize={12}
            buttonSize={26}
            onClick={onClick}
        />
    </div>
);
