import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    TerminationRoute,
    TerminationRoutesFilter,
    TerminationRouteCreateAttrs,
    TerminationRouteEditAttrs,
} from './types';

type TerminationRoutesQuery = {
    filter: TerminationRoutesFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'termination_routes';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME);

const availaleNumbersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTerminationRoutes: builder.query<SuccessResponse<TerminationRoute>, TerminationRoutesQuery>({
            query: ({ filter: { customer_id, gateway_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer,gateway',
                fields: {
                    customers: 'name',
                    termination_gateways: 'name',
                },
                filter: {
                    ...filter,
                    ...(gateway_id ? { 'gateway.id': gateway_id } : undefined),
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getTerminationRoute: builder.query<SuccessResponse<TerminationRoute>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer,gateway',
                fields: {
                    customers: 'name',
                    termination_gateways: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postTerminationRoute: builder.mutation<
            SuccessResponse<TerminationRoute>,
            TerminationRouteCreateAttrs
        >({
            query: ({
                customer_id,
                gateway_id,
                src_rewrite_result,
                src_rewrite_rule,
                dst_rewrite_result,
                dst_rewrite_rule,
                ...attributes
            }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes: {
                        ...attributes,
                        src_rewrite_result: src_rewrite_result || null,
                        src_rewrite_rule: src_rewrite_rule || null,
                        dst_rewrite_result: dst_rewrite_result || null,
                        dst_rewrite_rule: dst_rewrite_rule || null,
                    },
                    relationships: {
                        customer: {
                            data: {
                                id: customer_id,
                                type: 'customers',
                            },
                        },
                        gateway: {
                            data: {
                                id: gateway_id,
                                type: 'termination_gateways',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchTerminationRoute: builder.mutation<
            SuccessResponse<TerminationRoute>,
            { id: string; values: TerminationRouteEditAttrs }
        >({
            query: ({
                id,
                values: {
                    gateway_id,
                    src_rewrite_result,
                    src_rewrite_rule,
                    dst_rewrite_result,
                    dst_rewrite_rule,
                    ...attributes
                },
            }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    attributes: {
                        ...attributes,
                        src_rewrite_result: src_rewrite_result || null,
                        src_rewrite_rule: src_rewrite_rule || null,
                        dst_rewrite_result: dst_rewrite_result || null,
                        dst_rewrite_rule: dst_rewrite_rule || null,
                    },
                    relationships: {
                        gateway: {
                            data: {
                                id: gateway_id,
                                type: 'termination_gateways',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteTerminationRoute: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetTerminationRoutesQuery,
    useGetTerminationRouteQuery,
    useDeleteTerminationRouteMutation,
    usePatchTerminationRouteMutation,
    usePostTerminationRouteMutation,
} = availaleNumbersApi;
