import { ColumnSelection, ResourceColumnsType } from '../types';

const SUFFIX = '_list';

// Columns are saved in storage such way - "storage_key": 'saved_column_one=true,not_saved_column=false,saved_column_two=true...'

const getVisibilityInfo = (savedSelectionString: string): Record<string, string> => {
    const columnsConfigArray = savedSelectionString.split(',');

    return columnsConfigArray.reduce((accum, configString) => {
        const [key, visible] = configString.split('=');

        return {
            ...accum,
            [key]: visible,
        };
    }, {});
};

const getColumnVisibility = (
    savedVisible: string | undefined,
    defaultHidden: boolean | undefined,
): boolean => {
    if (savedVisible === 'false') return false;
    if (savedVisible === 'true') return true;

    return !defaultHidden;
};

export const getInitialSelection = <T>(
    storageKey: string,
    columns: ResourceColumnsType<T>,
): ColumnSelection[] => {
    const savedSelectionString = localStorage.getItem(`${storageKey}${SUFFIX}`);

    const optionalColumns = columns.filter((column) => !!column.title);

    if (!savedSelectionString) {
        return optionalColumns.map(({ key, title, defaultHidden }) => ({
            key: key as string,
            title: title as string,
            visible: !defaultHidden,
        }));
    }

    const visibilityInfo = getVisibilityInfo(savedSelectionString);

    return optionalColumns.map(({ key, title, defaultHidden }) => ({
        key: key as string,
        title: title as string,
        visible: getColumnVisibility(visibilityInfo[key as string], defaultHidden),
    }));
};

export const saveSelectionToStorage = (storageKey: string, columnsSelection: ColumnSelection[]): void => {
    const selectionString = columnsSelection.reduce((accum, selection) => {
        const columnInfo = `${selection.key}=${selection.visible ? 'true' : 'false'}`;

        return accum ? `${accum},${columnInfo}` : columnInfo;
    }, '');

    localStorage.setItem(`${storageKey}${SUFFIX}`, selectionString);
};
