import React from 'react';
import { AccessIpsSelect } from 'shared/ui/fields/select';
import { LabelInfo } from '@didww/ui-kit';

interface Props {
    fieldName: string;
    isDisabled: boolean;
}

export const AccessIps: React.FC<Props> = ({ fieldName, isDisabled }) => {
    return (
        <AccessIpsSelect
            labelText={
                <LabelInfo
                    title="Allowed IP address"
                    text="IP addresses from which access to the API is allowed in the format (IPv4|[/mask] or IPv6|[/mask])."
                />
            }
            isDisabled={isDisabled}
            name={fieldName}
        />
    );
};
