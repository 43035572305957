import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { AvaiableDidNumber } from 'entities/available-did-numbers';
import { BooleanCell } from 'shared/ui/cells';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<AvaiableDidNumber> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Available Number ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'number',
        dataIndex: 'number',
        title: 'Number',
        sorter: true,
        sortOrder: sorter.column === 'number' && sorter.order,
    },
    {
        key: 'external_id',
        dataIndex: 'external_id',
        title: 'External ID',
        sorter: true,
        sortOrder: sorter.column === 'external_id' && sorter.order,
    },
    { key: 'customer_id', title: 'Customer ID', render: (_, row) => row.customer.id },
    { key: 'customer_name', title: 'Customer', render: (_, row) => row.customer.name },
    {
        key: 'enabled',
        dataIndex: 'enabled',
        title: 'Enabled',
        align: 'center',
        sorter: true,
        sortOrder: sorter.column === 'enabled' && sorter.order,
        render: function EnabledColumn(_, row) {
            return <BooleanCell value={row.enabled} />;
        },
    },
    {
        key: 'readonly',
        title: 'Read-only',
        align: 'center',
        render: function ReadonlyColumn(_, row) {
            return <BooleanCell value={row.readonly} />;
        },
    },
];
