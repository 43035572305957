import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { SingleSelect } from 'shared/ui/fields/select';

const options = [
    { value: 'TO_USERPART', label: 'TO_USERPART' },
    { value: 'RURI_USERPART', label: 'RURI_USERPART' },
];

export const DestinationSelect: React.FC = () => {
    return (
        <SingleSelect
            name="destination_field"
            isSearchable={false}
            labelText={<LabelInfo title="Destination field" />}
            options={options}
        />
    );
};
