import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetIncomingTrunkCdrQuery } from 'entities/incoming-trunk-cdrs';
import { CdrInfo } from './cdr-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetIncomingTrunkCdrQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [incomingTrunkCdr] = data.resources;

    return <CdrInfo incomingTrunkCdr={incomingTrunkCdr} />;
};
