import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TerminationRoutesListPage } from './list';
import { TerminationRouteShowPage } from './show';
import { TerminationRouteCreatePage } from './create';
import { TerminationRouteEditPage } from './edit';

export const TerminationRoutesPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.terminationRoutes.list}>
            <TerminationRoutesListPage />
        </Route>
        <Route exact path={routes.terminationRoutes.show}>
            <TerminationRouteShowPage />
        </Route>
        <Route exact path={routes.terminationRoutes.create}>
            <TerminationRouteCreatePage />
        </Route>
        <Route exact path={routes.terminationRoutes.edit}>
            <TerminationRouteEditPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.terminationRoutes.list} />
        </Route>
    </Switch>
);
