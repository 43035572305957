import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';
import styles from './styles.module.scss';

interface Props {
    type: 'success' | 'warning' | 'error' | 'info';
}

export const ToastBody: React.FC<Props> = ({ type, children }) => (
    <div className={styles.toast}>
        <div className={styles.icon}>
            <InlineSvg type={`toast-${type}`} size={16} />
        </div>
        <span>{children}</span>
    </div>
);
