import { apiSlice, loadByQuery, QueryThunk, ApiThunk, authorizedRequest, QueryParams } from 'shared/api';
import { Entity } from 'shared/types';
import { CustomerEntity, GroupEntity } from '../types';

export const fetchAvailableCustomers =
    (groupId: string, query: QueryParams): QueryThunk<CustomerEntity> =>
    (dispatch) => {
        return dispatch(
            loadByQuery<CustomerEntity>('customers', {
                ...query,
                filter: {
                    ...query.filter,
                    allow_assign_to_capacity_group_id: groupId,
                },
                include: 'capacity_group',
                fields: {
                    customers: 'name,capacity_group',
                    capacity_groups: 'name',
                },
            }),
        );
    };

export const fetchGroup =
    (id: string): QueryThunk<GroupEntity> =>
    (dispatch) => {
        return dispatch(
            loadByQuery<GroupEntity>(`capacity_groups/${id}`, {
                include: 'customers',
                fields: {
                    capacity_groups: 'name,customers',
                    customers: 'name',
                },
            }),
        );
    };

export const patchGroupCustomers =
    (id: string, customerIds: string[]): ApiThunk<Entity> =>
    async (dispatch) => {
        const result = await dispatch(
            authorizedRequest({
                url: `capacity_groups/${id}`,
                method: 'PATCH',
                params: {
                    fields: {
                        capacity_groups: 'id',
                    },
                },
                data: {
                    id,
                    type: 'capacity_groups',
                    relationships: {
                        customers: {
                            data: customerIds.map((customerId) => ({
                                id: customerId,
                                type: 'customers',
                            })),
                        },
                    },
                },
            }),
        );

        // We should clear customers and capacity_groups cache
        // after reassinging of customers
        dispatch(apiSlice.util.invalidateTags(['customers']));

        return result;
    };
