import React, { useCallback, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { Input } from '@didww/ui-kit';
import { TextInputProps } from './types';

export const TextInput: React.FC<TextInputProps> = React.forwardRef(
    (
        {
            name,
            placeholder,
            style,
            isFixed,
            autoFocus = false,
            className,
            id,
            labelText,
            disabled,
            onFieldBlur,
            onFieldFocus,
            submitOnEnter,
            type,
        },
        ref,
    ) => {
        const { submitForm, isSubmitting } = useFormikContext();
        const [field, meta] = useField({ name });
        const [isFocused, setFocused] = useState(autoFocus);

        const shouldShowPopup = !!meta.error && meta.touched;

        const error = shouldShowPopup ? meta.error : '';

        const handleOnFocus = useCallback(() => {
            setFocused(true);

            if (onFieldFocus) {
                onFieldFocus(true);
            }
        }, [onFieldFocus]);

        const handleSubmitForm = useCallback(
            async (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
                if (submitOnEnter && !isSubmitting && event.key === 'Enter') {
                    submitForm();
                }
            },
            [submitForm, isSubmitting, submitOnEnter],
        );

        const onBlur = useCallback(
            (e) => {
                if (field.onBlur) {
                    field.onBlur(e);
                }

                if (onFieldBlur) {
                    onFieldBlur(e);
                }

                if (onFieldFocus) {
                    onFieldFocus(false);
                }

                setFocused(false);
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [field.onBlur, onFieldBlur, onFieldFocus],
        );

        const props = {
            ref: ref as any,
            disabled,
            labelText,
            className,
            error,
            ...field,
            onFocus: handleOnFocus,
            onBlur,
            name,
            placeholder,
            autoFocus: isFocused,
            id: id || name,
            onKeyPress: handleSubmitForm,
            isFixed: isFixed && !!meta.initialValue,
            style,
        };

        switch (type) {
            case 'password':
                return <Input.Password {...props} />;
            case 'text':
            default:
                return <Input {...props} />;
        }
    },
);
