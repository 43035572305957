import { useEffect } from 'react';

export const useInterval = (cb: () => void | Promise<void>, interval: number): void => {
    useEffect(() => {
        const currentInterval = setInterval(cb, interval);

        return () => {
            clearInterval(currentInterval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
