import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import { SipDeviceRoute, SipDeviceRouteFilter, SipDeviceRouteEditAttrs } from './types';

type SipDeviceRouteQuery = {
    filter: SipDeviceRouteFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'sip_device_routes';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME);

const sipAccountsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSipDeviceRoutes: builder.query<SuccessResponse<SipDeviceRoute>, SipDeviceRouteQuery>({
            query: ({ filter: { customer_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...filter,
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort,
            }),
            providesTags: tagsProvider.list,
        }),
        getSipDeviceRoute: builder.query<SuccessResponse<SipDeviceRoute>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        patchSipDeviceRoute: builder.mutation<
            SuccessResponse<SipDeviceRoute>,
            { id: string; values: SipDeviceRouteEditAttrs }
        >({
            query: ({ id, values: { allowed_ips, ...attributes } }) => {
                return {
                    url: `${RESOURCE_NAME}/${id}`,
                    method: 'PATCH',
                    data: {
                        id,
                        type: RESOURCE_NAME,
                        attributes: {
                            ...attributes,
                            allowed_ips: allowed_ips.join(','),
                        },
                    },
                };
            },
            invalidatesTags: tagsInvalidators.update,
        }),
    }),
});

export const { useGetSipDeviceRoutesQuery, useGetSipDeviceRouteQuery, usePatchSipDeviceRouteMutation } =
    sipAccountsApi;
