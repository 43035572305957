import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { BooleanCell, Codecs } from 'shared/ui/cells';
import { TableSorter } from 'shared/types/common';
import { TerminationGateway } from 'entities/termination-gateways';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<TerminationGateway> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Termination Gateway ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
    },
    {
        key: 'host',
        dataIndex: 'host',
        title: 'Host',
        sorter: true,
        sortOrder: sorter.column === 'host' && sorter.order,
    },
    {
        key: 'port',
        dataIndex: 'port',
        title: 'Port',
        sorter: true,
        sortOrder: sorter.column === 'port' && sorter.order,
    },
    {
        key: 'network_protocol',
        dataIndex: 'network_protocol',
        title: 'Network protocol',
        sorter: true,
        sortOrder: sorter.column === 'network_protocol' && sorter.order,
    },
    {
        key: 'authorization_name',
        dataIndex: 'authorization_name',
        title: 'Auth name',
        sorter: true,
        sortOrder: sorter.column === 'authorization_name' && sorter.order,
    },
    {
        key: 'authorization_password',
        dataIndex: 'authorization_password',
        title: 'Auth password',
        sorter: true,
        sortOrder: sorter.column === 'authorization_password' && sorter.order,
    },
    {
        key: 'customer_id',
        title: 'Customer ID',
        render: (_, row) => row.customer?.id || '',
    },
    {
        key: 'customer_name',
        title: 'Customer',
        render: (_, row) => row.customer?.name || '',
    },
    {
        key: 'operator',
        title: 'Operator',
        align: 'center',
        render: function OperatorColumn(_, row) {
            return <BooleanCell value={row.operator} />;
        },
    },
    {
        key: 'codecs',
        dataIndex: 'codecs',
        title: 'Codecs',
        render: function CodecsCell(_, row) {
            return <Codecs codecs={row.codecs} />;
        },
    },
    {
        key: 'src_rewrite_rule',
        dataIndex: 'src_rewrite_rule',
        title: 'SRC rewrite rule',
        sorter: true,
        sortOrder: sorter.column === 'src_rewrite_rule' && sorter.order,
        defaultHidden: true,
    },
    {
        key: 'src_rewrite_result',
        dataIndex: 'src_rewrite_result',
        title: 'SRC rewrite result',
        sorter: true,
        sortOrder: sorter.column === 'src_rewrite_result' && sorter.order,
        defaultHidden: true,
    },
    {
        key: 'dst_rewrite_rule',
        dataIndex: 'dst_rewrite_rule',
        title: 'DST rewrite rule',
        sorter: true,
        sortOrder: sorter.column === 'dst_rewrite_rule' && sorter.order,
        defaultHidden: true,
    },
    {
        key: 'dst_rewrite_result',
        dataIndex: 'dst_rewrite_result',
        title: 'DST rewrite result',
        sorter: true,
        sortOrder: sorter.column === 'dst_rewrite_result' && sorter.order,
        defaultHidden: true,
    },
];
