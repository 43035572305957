import React from 'react';
import { FilterOption } from '../types';
import { FilterLayout } from 'shared/ui/layouts';
import { AddFilter } from './add-filter';
import { RemovableField } from './removable-field';
import { useOptionalFilters } from '../lib';

interface Props {
    storageKey: string;
    optionalFilters?: FilterOption[];
}

export const ConditionalFilter: React.FC<Props> & { Item: typeof FilterLayout.Item } = ({
    storageKey,
    optionalFilters = [],
    children,
}) => {
    const { visibleFields, hiddenFields, showField, hideField } = useOptionalFilters(
        storageKey,
        optionalFilters,
    );

    const options = hiddenFields.map(({ name, label }) => ({ name, label }));

    return (
        <FilterLayout actions={<AddFilter options={options} onOptionClick={showField} />}>
            {children}
            {visibleFields.map((field) => (
                <RemovableField key={field.name} field={field} hideField={hideField} />
            ))}
        </FilterLayout>
    );
};

ConditionalFilter.Item = FilterLayout.Item;
