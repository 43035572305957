import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput, SingleSelect } from 'shared/ui/fields';

const enabledOptions = [
    {
        value: '',
        label: 'All',
    },
    {
        value: true,
        label: 'Allow',
    },
    {
        value: false,
        label: 'Prevent',
    },
];

const optionalFilters = [
    {
        name: 'allow_external_calls',
        label: 'Allow external calls',
        defaultValue: false,
        node: (
            <ConditionalFilter.Item>
                <SingleSelect
                    name="allow_external_calls"
                    isSearchable={false}
                    options={enabledOptions}
                    labelText="Allow external calls"
                />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="External Account ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <CustomersSelect labelText="Customer" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <TextInput name="external_caller_id" labelText="External caller ID" />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
