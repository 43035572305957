import React from 'react';
import { Link } from 'react-router-dom';
import { Sidebar as UiSidebar, SidebarItem, SidebarLink as UiSidebarLink } from '@didww/ui-kit';
import { InlineSvg } from 'shared/ui/inline-svg';
import { CloseMenu } from './close-menu';
import styles from './styles.module.scss';

export type SidebarLink = UiSidebarLink & { icon: string };

interface Props {
    header?: React.ReactNode;
    isOpened: boolean;
    selectedOption: string;
    close: () => void;
    links: SidebarLink[];
}

export const Sidebar: React.FC<Props> = ({ isOpened, selectedOption, close, header, links }) => (
    <UiSidebar
        selectedOption={selectedOption}
        isOpen={isOpened}
        close={close}
        closeAction={<CloseMenu onClick={close} />}
        header={header}
        linkComponent={Link}
    >
        {links.map(({ icon, ...link }) => (
            <SidebarItem
                onClick={close}
                {...link}
                icon={
                    <div className={styles.icon}>
                        <InlineSvg size={24} type={icon} />
                    </div>
                }
                key={link.link}
            />
        ))}
    </UiSidebar>
);
