import { useState, useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import { useDebouncedCallback } from 'use-debounce';
import { useUpdate } from 'shared/lib/hooks/use-update';
import { removeEmptyValues } from 'shared/lib/remove-empty-values';
import { ListFilter, ListFilterConfig } from './types';
import { getSavedFilters } from './helpers';

export function useListFilter<FilterType = Record<string, unknown>>({
    initialValues,
    storageKey,
}: ListFilterConfig<FilterType>): ListFilter<FilterType> {
    const initialFilterValues = useMemo(() => {
        const savedValues = getSavedFilters(storageKey);

        return { ...initialValues, ...savedValues };
    }, [initialValues, storageKey]);

    const [filterValues, setFilterValues] = useState(() => {
        return removeEmptyValues(initialFilterValues) as FilterType;
    });

    const setClearFilterValues = useCallback((values: FilterType) => {
        const clearValues = removeEmptyValues(values) as FilterType;

        setFilterValues(clearValues);
    }, []);

    const saveFilter = useDebouncedCallback(setClearFilterValues, 1000);

    const formik = useFormik<FilterType>({
        initialValues: initialFilterValues as FilterType,
        onSubmit: (values: FilterType, { setSubmitting }) => {
            saveFilter(values);
            setSubmitting(false);
        },
    });

    const { values: formValues } = formik;

    useUpdate(() => {
        saveFilter(formValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    return { filterValues, formik };
}
