import React from 'react';
import { SipAccountsList } from 'features/sip-accounts';
import { PageLayout } from 'shared/ui/layouts';

export const SipAccountsListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header title="SIP-Accounts" />
            <PageLayout.Content>
                <SipAccountsList />
            </PageLayout.Content>
        </PageLayout>
    );
};
