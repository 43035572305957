export enum COLORS {
    RED = '#da412f',
    ORANGE = '#e3a631',
    GREEN = '#7cb947',
}

export type ComplexityRequirements = {
    uppercase: boolean;
    lowercase: boolean;
    digits: boolean;
    symbols: boolean;
};

export type ValidationResult = {
    color: COLORS;
    message: string;
    complexityRequirements: ComplexityRequirements;
    progress: number;
};

export type Result = {
    isValid: boolean;
    message: string;
};
