// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyValues = <T extends Record<string, any>>(obj: T): T | Record<string, any> => {
    return Object.keys(obj).reduce((accum, key) => {
        const value = obj[key];
        if (typeof value !== 'undefined' && value !== '') {
            return {
                ...accum,
                [key]: value,
            };
        }
        return accum;
    }, {});
};
