import React from 'react';
import { Button as UiButton } from '@didww/ui-kit';
import { routes } from 'shared/routes';
import { useLockBodyScroll } from 'shared/lib/hooks/use-lock-body-scroll';
import styles from './styles.module.scss';

interface Props {
    buttonText?: string;
    onClick?: () => Promise<void>;
    href?: string;
}

const Button: React.FC<Props> = ({ buttonText = 'Back to homepage', onClick, href = routes.dashboard }) => {
    useLockBodyScroll(true);

    return (
        <div className={styles.btnWrapper}>
            {onClick ? (
                <UiButton color="tangerine" text={buttonText} onClick={onClick} />
            ) : (
                <UiButton color="tangerine" text={buttonText} href={href} />
            )}
        </div>
    );
};

export const ErrorLayout: React.FC & { Button: typeof Button } = ({ children }) => (
    <div className={styles.wrapper}>{children}</div>
);

ErrorLayout.Button = Button;
