import React from 'react';
import { FormContext } from 'entities/form-context';
import { PageLoader, Spin } from 'shared/ui/spinners';
import { PageLayout } from 'shared/ui/layouts';
import { Filter } from '../filter';
import { AvailableTable } from '../available-table';
import { AssignedTable } from '../assigned-table';
import { useManageCustomers } from '../../lib';
import styles from './styles.module.scss';
import { useAsyncEffect } from 'shared/lib/hooks/use-async-effect';

interface Props {
    id: string;
}

export const Manage: React.FC<Props> = ({ id }) => {
    const { availableState, assignedState, assignToGroup, removeFromGroup, isPushing } =
        useManageCustomers(id);

    const { listState: availableListState } = availableState;

    const isPageLoading = useAsyncEffect(assignedState.fetchGroup);

    if (isPageLoading) {
        return <PageLoader />;
    }

    return (
        <>
            <PageLayout.Header title={`Manage Customers in Group ${assignedState.groupName}`} />
            <PageLayout.Content>
                <Spin spinning={isPushing}>
                    <div className={styles.subSection} data-testid="available-section">
                        <h2 className={styles.subTitle}>Available Customers</h2>
                        <Spin spinning={availableState.isFetching}>
                            <FormContext formik={availableListState.filter.formik}>
                                <Filter withCapacityFilter />
                            </FormContext>
                            <AvailableTable
                                dataSource={availableState.dataSource}
                                totalRecords={availableState.totalRecords}
                                tableSorter={availableListState.tableSorter}
                                pagination={availableListState.pagination}
                                isPushing={isPushing}
                                handleAssign={assignToGroup}
                            />
                        </Spin>
                    </div>

                    <div className={styles.subSection} data-testid="assigned-section">
                        <h2 className={styles.subTitle}>{`Customers in Group ${assignedState.groupName}`}</h2>
                        <FormContext formik={assignedState.filterFormik}>
                            <Filter />
                        </FormContext>
                        <AssignedTable
                            dataSource={assignedState.dataSource}
                            groupName={assignedState.groupName}
                            isPushing={isPushing}
                            handleRemove={removeFromGroup}
                        />
                    </div>
                </Spin>
            </PageLayout.Content>
        </>
    );
};
