import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface Props {
    vertical?: boolean;
}

export const Divider: React.FC<Props> = ({ vertical }) => {
    return <div className={cn(styles.divider, { [styles.vertical]: vertical })} />;
};
