import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput, SingleSelect } from 'shared/ui/fields';

const enabledOptions = [
    {
        value: '',
        label: 'All',
    },
    {
        value: true,
        label: 'Enabled',
    },
    {
        value: false,
        label: 'Disabled',
    },
];

const optionalFilters = [
    {
        name: 'enabled',
        label: 'Enabled',
        defaultValue: false,
        node: (
            <ConditionalFilter.Item size="small">
                <SingleSelect
                    isSearchable={false}
                    name="enabled"
                    options={enabledOptions}
                    labelText="Enabled"
                />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="Available Number ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <CustomersSelect labelText="Customer" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <TextInput name="number" labelText="Number" />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
