/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';

const renderSettings = { preserveAspectRatio: 'xMidYMid slice' };

interface Props {
    json: { [key: string]: any };
    loop?: boolean;
    height: string;
    width: string;
}
export const LottieLogo: React.FC<Props> = React.memo(function Logo({ json, loop = true, height, width }) {
    const style = { width, height };
    const ref = useRef<any>();

    useEffect(() => {
        ref.current.setSubframe(false);
    }, []);

    return (
        <Lottie
            lottieRef={ref}
            animationData={json}
            style={style}
            rendererSettings={renderSettings}
            renderer="svg"
            loop={loop}
        />
    );
});
