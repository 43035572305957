import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';

interface Props {
    value: boolean;
}

export const BooleanCell: React.FC<Props> = ({ value }) => {
    const icon = value ? 'checked' : 'crossed';

    return <InlineSvg type={icon} />;
};
