import { ReactNode } from 'react';

export type ConfirmModalConfig = {
    title?: string;
    content: string | ReactNode;
    onConfirm: () => Promise<void> | void;
};

type ConfigHandler = (config: ConfirmModalConfig | null) => void;

export class ConfirmModalManager {
    private static instance: ConfirmModalManager;

    private callback: ConfigHandler | null = null;

    public static getInstance(): ConfirmModalManager {
        if (!ConfirmModalManager.instance) {
            ConfirmModalManager.instance = new ConfirmModalManager();
        }

        return ConfirmModalManager.instance;
    }

    public subscribe = (fn: ConfigHandler): void => {
        this.callback = fn;
    };

    public unsubscribe = (): void => {
        this.callback = null;
    };

    public openModal(config: ConfirmModalConfig): void {
        if (this.callback) {
            this.callback(config);
        }
    }

    public closeModal(): void {
        if (this.callback) {
            this.callback(null);
        }
    }
}

export const ConfirmModalManagerInstance = ConfirmModalManager.getInstance();

export const showConfirmModal =
    (config: ConfirmModalConfig): (() => void) =>
    (): void =>
        ConfirmModalManagerInstance.openModal(config);
