import React from 'react';
import { CapacityGroupsSelect } from 'entities/form-selects';
import { FilterLayout } from 'shared/ui/layouts';
import { TextInput } from 'shared/ui/fields';

interface Props {
    withCapacityFilter?: boolean;
}

export const Filter: React.FC<Props> = ({ withCapacityFilter }) => (
    <FilterLayout>
        <FilterLayout.Item>
            <TextInput name="id" labelText="Customer ID" />
        </FilterLayout.Item>
        <FilterLayout.Item>
            <TextInput name="name" labelText="Name" />
        </FilterLayout.Item>
        {withCapacityFilter && (
            <FilterLayout.Item>
                <CapacityGroupsSelect labelText="Capacity Group" allowNotAssign />
            </FilterLayout.Item>
        )}
    </FilterLayout>
);
