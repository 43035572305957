import React from 'react';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput } from 'shared/ui/fields';

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="Capacity Group ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <TextInput name="name" labelText="Name" />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
