import React, { useCallback } from 'react';
import { useDispatch } from 'app/hooks';
import { Autocomplete, Option } from 'shared/ui/fields';
import { Customer } from '../types';
import { fetchCustomersOptions } from '../model';

interface Props {
    name?: string;
    labelText?: React.ReactNode;
    initialResources?: Customer[];
}

const makeCustomersOptions = (resources: Customer[]): Option[] =>
    resources.map((customer) => ({
        value: customer.id,
        label: `${customer.id} | ${customer.name}`,
    }));

export const CustomersSelect: React.FC<Props> = ({ name = 'customer_id', labelText, initialResources }) => {
    const dispatch = useDispatch();

    const fetchOptions = useCallback(
        (page: number, name?: string) => dispatch(fetchCustomersOptions(page, name)),
        [dispatch],
    );

    return (
        <Autocomplete
            name={name}
            onLoad={fetchOptions}
            labelText={labelText}
            initialOptions={initialResources}
            makeOptions={makeCustomersOptions}
            filterBy="none"
            isClearable
            valueOnClear=""
            isLoadOnCloseMenu
        />
    );
};
