import React from 'react';
import { ActionButton } from '../action-button';

interface Props {
    to: string;
    text?: string;
}

export const Back: React.FC<Props> = ({ to, text = 'Back' }) => (
    <ActionButton icon="back" text={text} color="red" ghost path={to} />
);
