import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TerminationGatewaysListPage } from './list';
import { TerminationGatewaysShowPage } from './show';
import { TerminationGatewayEditPage } from './edit';
import { TerminationGatewayCreatePage } from './create';

export const TerminationGatewaysPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.terminationGateways.list}>
            <TerminationGatewaysListPage />
        </Route>
        <Route exact path={routes.terminationGateways.show}>
            <TerminationGatewaysShowPage />
        </Route>
        <Route exact path={routes.terminationGateways.create}>
            <TerminationGatewayCreatePage />
        </Route>
        <Route exact path={routes.terminationGateways.edit}>
            <TerminationGatewayEditPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.terminationGateways.list} />
        </Route>
    </Switch>
);
