import React from 'react';
import { TerminationCdrsList } from 'features/termination-cdrs';
import { PageLayout } from 'shared/ui/layouts';

export const TerminationCdrsListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header title="Termination CDRs" />
            <PageLayout.Content>
                <TerminationCdrsList />
            </PageLayout.Content>
        </PageLayout>
    );
};
