import React from 'react';
import styles from './styles.module.scss';

const Header: React.FC = ({ children }) => <div className={styles.header}>{children}</div>;

const Content: React.FC = ({ children }) => <div className={styles.content}>{children}</div>;

const Footer: React.FC = ({ children }) => <div className={styles.footer}>{children}</div>;

export const List: React.FC & { Header: typeof Header; Content: typeof Content; Footer: typeof Footer } = ({
    children,
}) => <div className={styles.list}>{children}</div>;

List.Header = Header;
List.Content = Content;
List.Footer = Footer;
