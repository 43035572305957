import React from 'react';
import { useParams } from 'react-router-dom';
import { CustomerEdit } from 'features/customers';
import { PageLayout } from 'shared/ui/layouts';

export const CustomerEditPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <CustomerEdit id={id} />
        </PageLayout>
    );
};
