import { TERMINATION_MODE } from './types';

export const MAX_LIMIT = 2147483647;

export const TERMINATION_MODE_LABELS = {
    [TERMINATION_MODE.SYSTEM]: 'System',
    [TERMINATION_MODE.CUSTOMER]: 'Customer',
    [TERMINATION_MODE.SYSTEM_CUSTOMER]: 'System and Customer',
    [TERMINATION_MODE.OPERATOR]: 'Operator',
    [TERMINATION_MODE.OPERATOR_CUSTOMER]: 'Operator and Customer',
};

const TERMINATION_MODE_OPTIONS = {
    SYSTEM: {
        value: TERMINATION_MODE.SYSTEM,
        label: TERMINATION_MODE_LABELS[TERMINATION_MODE.SYSTEM],
    },
    CUSTOMER: {
        value: TERMINATION_MODE.CUSTOMER,
        label: TERMINATION_MODE_LABELS[TERMINATION_MODE.CUSTOMER],
    },
    SYSTEM_CUSTOMER: {
        value: TERMINATION_MODE.SYSTEM_CUSTOMER,
        label: TERMINATION_MODE_LABELS[TERMINATION_MODE.SYSTEM_CUSTOMER],
    },
    OPERATOR: {
        value: TERMINATION_MODE.OPERATOR,
        label: TERMINATION_MODE_LABELS[TERMINATION_MODE.OPERATOR],
    },
    OPERATOR_CUSTOMER: {
        value: TERMINATION_MODE.OPERATOR_CUSTOMER,
        label: TERMINATION_MODE_LABELS[TERMINATION_MODE.OPERATOR_CUSTOMER],
    },
};

export const TERMINATION_OPTIONS_ARRAY = Object.values(TERMINATION_MODE_OPTIONS);

export const OPERATOR_TERMINATION_OPTIONS_ARRAY = [
    TERMINATION_MODE_OPTIONS.OPERATOR,
    TERMINATION_MODE_OPTIONS.OPERATOR_CUSTOMER,
];
