import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { Submit } from 'shared/ui/buttons/submit';
import { TextInput } from 'shared/ui/fields/text-input';
import styles from './styles.module.scss';
import { usePostTwoFactorDeliveriesMutation } from 'entities/two-factor-auth';
import { message } from 'shared/message';
import { ResendCode } from '../resend-code';

export const Fields: React.FC = () => {
    const [postTwoFactorDeliveries] = usePostTwoFactorDeliveriesMutation();

    const handleResendCode = async (): Promise<void> => {
        const success = await postTwoFactorDeliveries('');

        if (success) {
            message.success('A new verification code has been sent');
        }
    };

    return (
        <div className={styles.form}>
            <div className={styles.field}>
                <TextInput name="otp_code" labelText={<LabelInfo title="Enter your verification code" />} />
            </div>
            <div>
                <div className={styles.resend}>
                    <ResendCode onResendCode={handleResendCode} />
                </div>
                <Submit text="Submit" />
            </div>
        </div>
    );
};
