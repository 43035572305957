import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    TerminationGateway,
    TerminationGatewayFilter,
    TerminationGatewayEditAttrs,
    TerminationGatewayCreateAttrs,
} from './types';

type TerminationGatewayQuery = {
    filter: TerminationGatewayFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'termination_gateways';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME, ['termination_routes']);

const terminationGatewaysApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTerminationGateways: builder.query<SuccessResponse<TerminationGateway>, TerminationGatewayQuery>({
            query: ({ filter: { customer_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...filter,
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getTerminationGateway: builder.query<SuccessResponse<TerminationGateway>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postTerminationGateway: builder.mutation<
            SuccessResponse<TerminationGateway>,
            TerminationGatewayCreateAttrs
        >({
            query: ({ customer_id, ...attributes }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes,
                    relationships: {
                        customer: {
                            data: {
                                id: customer_id,
                                type: 'customers',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchTerminationGateway: builder.mutation<
            SuccessResponse<TerminationGateway>,
            { id: string; values: TerminationGatewayEditAttrs }
        >({
            query: ({ id, values }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    attributes: values,
                },
                skipPrimary: true,
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteTerminationGateway: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
                skipPrimary: true,
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetTerminationGatewaysQuery,
    useGetTerminationGatewayQuery,
    usePostTerminationGatewayMutation,
    usePatchTerminationGatewayMutation,
    useDeleteTerminationGatewayMutation,
} = terminationGatewaysApi;
