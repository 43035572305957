import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'shared/routes';
import { useSelector } from 'app/hooks';

export const useRedirects = (): {
    redirectToUser: () => void;
    redirectToGuest: () => void;
} => {
    const shouldRememberPath = useSelector((state) => state.auth.remember_pathname);

    const {
        replace,
        location: { pathname },
    } = useHistory();

    const [redirectionPathname, setRedirectionPathname] = useState(() => {
        if (pathname !== routes.signIn && shouldRememberPath) {
            return pathname;
        }

        return routes.dashboard;
    });

    const redirectToGuest = useCallback(() => {
        if (shouldRememberPath) {
            setRedirectionPathname(pathname);
        }
        replace(routes.signIn);
    }, [replace, shouldRememberPath, pathname]);

    const redirectToUser = useCallback(() => {
        replace(redirectionPathname);

        setRedirectionPathname(routes.dashboard);
    }, [replace, redirectionPathname]);

    return { redirectToGuest, redirectToUser };
};
