import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CapacityGroupShow, CapacityGroupDelete } from 'features/capacity-groups';
import { Edit, ActionButton } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const CapacityGroupShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const managePath = routes.capacityGroups.manageCustomers.replace(':id', id);

    return (
        <PageLayout>
            <CapacityGroupShow id={id} />
            <PageLayout.Footer
                backPath={routes.capacityGroups.list}
                actions={[
                    <Edit key="edit" id={id} route={routes.capacityGroups.edit} />,
                    <ActionButton
                        key="manage"
                        icon="manage-customers"
                        path={managePath}
                        text="Manage Customers"
                    />,
                    <CapacityGroupDelete key="delete" id={id} />,
                ]}
            />
        </PageLayout>
    );
};
