import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { TerminationCdrsListPage } from './list';
import { TerminationCdrShowPage } from './show';

export const TerminationCdrsPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.terminationCdrs.list}>
            <TerminationCdrsListPage />
        </Route>
        <Route exact path={routes.terminationCdrs.show}>
            <TerminationCdrShowPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.terminationCdrs.list} />
        </Route>
    </Switch>
);
