import dayjs from 'dayjs';
import { Defaults } from './types';

export function useDefaults(): Defaults {
    const currentDate = dayjs();

    const ranges = [
        {
            label: 'Today',
            from: currentDate.startOf('day'),
            to: currentDate.endOf('day'),
        },
        {
            label: 'This week',
            from: currentDate.startOf('week'),
            to: currentDate.endOf('day'),
        },
        {
            label: 'This month',
            from: currentDate.startOf('month'),
            to: currentDate.endOf('day'),
        },
        {
            label: 'Previous month',
            from: currentDate.subtract(1, 'month').startOf('month'),
            to: currentDate.subtract(1, 'month').endOf('month'),
        },
    ];

    return { maxDate: currentDate.endOf('day'), ranges };
}
