import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';

interface Props {
    type: string;
    isChecked: boolean;
    label: string;
}

export const TooltipRule: React.FC<Props> = ({ type, isChecked, label }) => {
    return (
        <li data-testid={type}>
            <InlineSvg type={isChecked ? 'check-bold' : 'cross-bold'} />
            <span>{label}</span>
        </li>
    );
};
