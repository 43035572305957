import { useState, useCallback } from 'react';
import { Page } from 'shared/types';
import { useUpdate } from 'shared/lib/hooks/use-update';
import { ListFilter, useListFilter } from './use-list-filter';
import { ListSorter, useListSorter } from './use-list-sorter';

type ListQuery<FilterValues> = {
    filter: FilterValues;
    page: Page;
    sort?: string;
};

export type ListPagination = { page: Page; setPage: (page: Page) => void };

export type ListState<FilterValues, Resource> = {
    query: ListQuery<FilterValues>;
    pagination: ListPagination;
    filter: ListFilter<FilterValues>;
    tableSorter: ListSorter<Resource>;
};

export const useListState = <FilterValues, Resource = unknown>(config: {
    storageKey?: string;
    initialFilter: FilterValues;
    initialSort?: string;
}): ListState<FilterValues, Resource> => {
    const filter = useListFilter<FilterValues>({
        storageKey: config.storageKey,
        initialValues: config.initialFilter,
    });

    const tableSorter = useListSorter<Resource>(config.initialSort);

    const { filterValues } = filter;
    const { sort } = tableSorter.sorter;

    const [query, setQuery] = useState<ListQuery<FilterValues>>({
        filter: filterValues,
        sort: sort || undefined,
        page: { number: 1, size: 10 },
    });

    useUpdate(() => {
        setQuery((oldQuery) => ({ ...oldQuery, sort: sort || undefined }));
    }, [sort]);

    // When filter is changed, we should change page
    // number to 1
    useUpdate(() => {
        setQuery((oldQuery) => ({
            ...oldQuery,
            filter: filterValues,
            page: {
                ...oldQuery.page,
                number: 1,
            },
        }));
    }, [filterValues]);

    const setPage = useCallback((newPage: Page): void => {
        setQuery((query) => ({ ...query, page: newPage }));
    }, []);

    return {
        query,
        filter,
        tableSorter,
        pagination: { page: query.page, setPage },
    };
};
