import React from 'react';
import { IncomingTrunkCreate } from 'features/incoming-trunks';
import { PageLayout } from 'shared/ui/layouts';

export const IncomingTrunkCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Incoming Trunk" />
        <PageLayout.Content>
            <IncomingTrunkCreate />
        </PageLayout.Content>
    </PageLayout>
);
