import { useEffect, useCallback } from 'react';

type Callback = (key: string) => void;

export const useKeyPress = (callback: Callback): void => {
    const handleClick = useCallback(
        (e: KeyboardEvent) => {
            callback(e.key);
        },
        [callback],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleClick);

        return (): void => {
            document.removeEventListener('keydown', handleClick);
        };
    }, [handleClick]);
};
