import { useState, useEffect } from 'react';
import { ColumnsType } from '@didww/ui-kit';
import { ColumnSelection, ResourceColumnsType } from '../types';
import { getInitialSelection, saveSelectionToStorage } from './storage';

type ColumnsSelectionData<T> = {
    columnsSelection: ColumnSelection[];
    changeColumnVisibility: (columnKey: string, visible: boolean) => void;
    visibleColumns: ColumnsType<T>;
};

export const useColumnsSelection = <T>(
    storageKey: string,
    columns: ResourceColumnsType<T>,
): ColumnsSelectionData<T> => {
    const [columnsSelection, setColumnsSelection] = useState<ColumnSelection[]>(() =>
        getInitialSelection(storageKey, columns),
    );

    useEffect(() => {
        saveSelectionToStorage(storageKey, columnsSelection);
    }, [columnsSelection, storageKey]);

    const changeColumnVisibility = (columnKey: string, visible: boolean): void => {
        setColumnsSelection((selections) => {
            const areAllHidden = selections.every(
                (selection) => !selection.visible || selection.key === columnKey,
            );

            if (areAllHidden) {
                return selections.map((selection) => ({
                    ...selection,
                    visible: true,
                }));
            }

            return selections.map((selection) => {
                if (selection.key === columnKey) {
                    return {
                        ...selection,
                        visible,
                    };
                }

                return selection;
            });
        });
    };

    const visibleColumns = columns.reduce<ColumnsType<T>>((accum, column) => {
        const columnSelection = columnsSelection.find((selection) => selection.key === column.key);

        if (columnSelection?.visible === false) return accum;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { defaultHidden, ...tableColumn } = column;

        accum.push(tableColumn);
        return accum;
    }, []);

    return {
        columnsSelection,
        changeColumnVisibility,
        visibleColumns: visibleColumns,
    };
};
