import { useEffect, useState } from 'react';
import { throttle } from 'shared/lib/helpers';

export function useWindowWidth(): number {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const throttledCallback = throttle(() => {
            setWindowWidth(window.innerWidth);
        }, 200);

        window.addEventListener('resize', throttledCallback);

        return (): void => {
            window.removeEventListener('resize', throttledCallback);
        };
    }, []);

    return windowWidth;
}
