import { SuccessResponse, apiSlice } from 'shared/api';
import { Entity } from 'shared/types/resources';
import { ChangePasswordAttrs } from './types';

const RESOURCE_NAME = 'update_passwords';

const changePasswordApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        postChangePassword: builder.mutation<SuccessResponse<Entity>, ChangePasswordAttrs>({
            query: ({ password, new_password, password_confirmation }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes: {
                        password,
                        new_password,
                        password_confirmation,
                    },
                },
            }),
        }),
    }),
});

export const { usePostChangePasswordMutation } = changePasswordApi;
