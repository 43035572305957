import { Dispatch } from 'react';
import { saveSession } from 'entities/auth/model/slice';
import {
    CUSTOMER_ALLOW_KEY,
    EXTERNAL_ACCOUNT_ALLOW_KEY,
    TWO_FACTOR_AUTH_ENABLE_KEY,
    USERNAME_KEY,
} from 'entities/auth/config';

interface saveSession {
    type: string;
    payload: LoginAttrs;
}

type LoginAttrs = {
    username: string;
    customer_create_allowed: boolean;
    external_account_create_allowed: boolean;
    two_factor_auth_enabled: boolean;
};

export const setSession = (login: LoginAttrs, dispatch: Dispatch<saveSession>): void => {
    const { username, customer_create_allowed, external_account_create_allowed, two_factor_auth_enabled } =
        login;

    localStorage.setItem(USERNAME_KEY, username);
    localStorage.setItem(CUSTOMER_ALLOW_KEY, String(customer_create_allowed));
    localStorage.setItem(EXTERNAL_ACCOUNT_ALLOW_KEY, String(external_account_create_allowed));
    localStorage.setItem(TWO_FACTOR_AUTH_ENABLE_KEY, String(two_factor_auth_enabled));

    dispatch(
        saveSession({
            username,
            customer_create_allowed,
            external_account_create_allowed,
            two_factor_auth_enabled,
        }),
    );
};
