import React from 'react';
import { Back, Submit } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    backPath?: string;
    submitText: 'Save' | 'Create';
}

export const Footer: React.FC<Props> = ({ backPath, submitText }) => (
    <div className={styles.formFooter}>
        {backPath && <Back to={backPath} text="Cancel" />}
        <div className={styles.expandable}></div>
        <Submit isAction icon="save" text={submitText} />
    </div>
);
