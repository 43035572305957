import React, { useCallback, useMemo } from 'react';
import { ColumnsType } from '@didww/ui-kit';
import { Button } from 'shared/ui/buttons';
import { NamedEntity } from '../types';
import { useSelectionState } from '../lib';
import { CustomersTable } from './customers-table';

interface Props {
    groupName: string;
    isPushing: boolean;
    dataSource: NamedEntity[];
    handleRemove: (ids: string[]) => Promise<void>;
}

const defaultColumns: ColumnsType<NamedEntity> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Customer ID',
        width: 200,
        defaultSortOrder: 'descend',
        sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        width: 250,
        sorter: (a, b) => a.name.localeCompare(b.name, undefined, { numeric: false, sensitivity: 'base' }),
    },
];

export const AssignedTable: React.FC<Props> = ({ dataSource, isPushing, groupName, handleRemove }) => {
    const selection = useSelectionState();

    const { selected } = selection;

    const isRemoveDisabled = selected.length === 0 || isPushing;

    const columns = useMemo(
        () => [
            ...defaultColumns,
            {
                key: 'capacity_group',
                title: 'Capacity Group',
                width: 250,
                render: () => groupName,
            },
        ],
        [groupName],
    );

    const onButtonClick = useCallback(() => {
        handleRemove(selected);
    }, [handleRemove, selected]);

    return (
        <CustomersTable
            dataSource={dataSource}
            columns={columns}
            selection={selection}
            action={
                <Button
                    disabled={isRemoveDisabled}
                    text="Remove from Group"
                    color="red"
                    onClick={onButtonClick}
                />
            }
        />
    );
};
