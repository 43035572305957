import React from 'react';
import { Button } from 'shared/ui/buttons';
import { FieldConfig } from '../../types';
import styles from './styles.module.scss';

interface Props {
    field: FieldConfig;
    hideField: (fieldName: string) => void;
}

export const RemovableField: React.FC<Props> = ({ field, hideField }) => (
    <div className={styles.removableField} data-testid={`optional-field-${field.name}`}>
        <div className={styles.remove}>
            <Button
                icon="crossed"
                color="red"
                ghost
                shape="circle"
                size="small"
                onClick={() => hideField(field.name)}
            />
        </div>
        {field.node}
    </div>
);
