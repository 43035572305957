import React from 'react';
import { Spacer } from 'shared/ui/spacer';
import { ErrorLayout } from 'shared/ui/layouts';
import { LottieLogo } from 'shared/ui/lottie-logo';
import json from './animation.json';
import styles from './styles.module.scss';

export const ServerErrorPage: React.FC = () => {
    return (
        <ErrorLayout>
            <LottieLogo json={json} width="160px" height="140px" />
            <Spacer size={10} />
            <div className={styles.title}>500</div>
            <div className={styles.subTitle}>Internal server error</div>
            <Spacer size={31} />
            <ErrorLayout.Button />
            <Spacer size={34} />
            <div className={styles.footer}>
                We are sorry for the inconvenience but something went wrong and we are unable to launch this
                page. Our team has been notified about this error and will investigate as soon as possible.
            </div>
        </ErrorLayout>
    );
};
