import React from 'react';
import { useFormikContext } from 'formik';
import { Button, ButtonProps } from '../button';
import { ActionButton } from '../action-button';

interface Props extends ButtonProps {
    isAction?: boolean;
}

export const Submit: React.FC<Props> = ({ isAction, ...props }) => {
    const { isSubmitting } = useFormikContext();

    const buttonProps: ButtonProps = {
        ...props,
        buttonType: 'submit',
        isPushing: props.isPushing || isSubmitting,
        disabled: props.isPushing || props.disabled,
    };

    if (isAction && props.icon) {
        return <ActionButton {...buttonProps} icon={props.icon as string} />;
    }

    return <Button {...buttonProps} />;
};
