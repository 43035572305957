import { JsonApiConfig, JsonApiClient, HttpClient } from '@didww/jsonapi-client';
import { DefaultRequestMeta } from 'shared/types/resources';
import { ApiResponse, RequestOptions } from '../types';
import { DEFAULT_HEADERS, BASE_URL } from '../constants';
import { transformResponse } from './transform-response';

const operatorApiClient = new HttpClient({
    baseURL: BASE_URL,
    headers: DEFAULT_HEADERS,
    withCredentials: true,
});

const jsonOperatorApiClient = new JsonApiClient(operatorApiClient);

export function apiRequest<DataType = unknown, MetaType = DefaultRequestMeta>(
    options: RequestOptions,
): ApiResponse<DataType, MetaType> {
    const { method = 'GET', data, params, ...requestOptions } = options;

    const [type, id] = requestOptions.url.split('/');

    const config: JsonApiConfig = {
        ...requestOptions,
        query: params,
        headers: {
            ...DEFAULT_HEADERS,
            ...options.headers,
        },
    };

    let request;

    switch (method) {
        case 'POST':
            request = jsonOperatorApiClient.create(data, config);
            break;
        case 'PATCH':
            request = jsonOperatorApiClient.update(data, config);
            break;
        case 'DELETE':
            request = jsonOperatorApiClient.destroy(type, id, config);
            break;
        default:
            if (id) {
                request = jsonOperatorApiClient.readById(type, id, config);
            } else {
                request = jsonOperatorApiClient.readAll(type, config);
            }
            break;
    }

    return request.then((response) => {
        if (response.isOk) {
            return {
                data: transformResponse<DataType, MetaType>(response.result),
            };
        }

        return {
            error: response,
        };
    });
}
