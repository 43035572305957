import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { TerminationRouteCreateAttrs, usePostTerminationRouteMutation } from 'entities/termination-routes';
import { routes } from 'shared/routes';
import { FormLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

export const Form: React.FC = () => {
    const { push } = useHistory();

    const [postTerminationRoute] = usePostTerminationRouteMutation();

    const formik = useFormik<TerminationRouteCreateAttrs>({
        initialValues: {
            name: '',
            customer_id: '',
            gateway_id: '',
            src_prefix: '',
            dst_prefix: '',
            src_rewrite_rule: '',
            src_rewrite_result: '',
            dst_rewrite_rule: '',
            dst_rewrite_result: '',
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await postTerminationRoute(values)
                .unwrap()
                .then(({ resources }) => {
                    const [{ id }] = resources;

                    push(routes.terminationRoutes.show.replace(':id', id));

                    message.success('Termination Route created');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <FormContext formik={formik}>
            <Fields customerId={formik.values.customer_id} />
            <FormLayout.Footer backPath={routes.terminationRoutes.list} submitText="Create" />
        </FormContext>
    );
};
