import { SuccessResponse, apiSlice, getTagsProviders, getTagsInvalidators } from 'shared/api';
import { Settings, SettingsAttrs } from './types';

const RESOURCE_NAME = 'settings';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME);

const settingsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSettings: builder.query<SuccessResponse<Settings>, string>({
            query: () => ({
                url: RESOURCE_NAME,
                method: 'GET',
            }),
            providesTags: tagsProvider.show,
        }),
        patchSetting: builder.mutation<SuccessResponse<Settings>, SettingsAttrs>({
            query: ({ acl_ips }) => ({
                url: RESOURCE_NAME,
                method: 'PATCH',
                data: {
                    id: '',
                    type: RESOURCE_NAME,
                    attributes: {
                        acl_ips,
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
    }),
});

export const { useGetSettingsQuery, usePatchSettingMutation } = settingsApi;
