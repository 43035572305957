import React from 'react';
import { TerminationGatewayCreate } from 'features/termination-gateways';
import { PageLayout } from 'shared/ui/layouts';

export const TerminationGatewayCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Termination Gateway" />
        <PageLayout.Content>
            <TerminationGatewayCreate />
        </PageLayout.Content>
    </PageLayout>
);
