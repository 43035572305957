import { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { MultiSelectValueProps } from '@didww/ui-kit';
import { MultiSelectFieldProps } from './types';

export function useMultiSelectField(props: MultiSelectFieldProps): MultiSelectValueProps {
    const { name, isAutoSubmit = false } = props;

    const [{ value }] = useField(name);

    const { setFieldValue, setFieldError, setFieldTouched, submitForm } = useFormikContext();

    const onChange: MultiSelectValueProps['onChange'] = useCallback(
        (newValues) => {
            setFieldTouched(name, true);
            setFieldError(name, '');
            setFieldValue(name, newValues);

            isAutoSubmit && submitForm();
        },
        [name, setFieldError, setFieldTouched, setFieldValue, isAutoSubmit, submitForm],
    );

    return { onChange, value };
}
