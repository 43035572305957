import React, { ReactNode, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { Table, ColumnsType, TableProps } from '@didww/ui-kit';
import { NamedEntity, SelectionState } from '../../types';
import styles from './styles.module.scss';

interface Props {
    dataSource: NamedEntity[];
    columns: ColumnsType<NamedEntity>;
    onChange?: TableProps<NamedEntity>['onChange'];
    selection: SelectionState;
    footer?: ReactNode;
    action: ReactNode;
}

export const CustomersTable: React.FC<Props> = ({
    dataSource,
    onChange,
    columns,
    selection,
    action,
    footer = null,
}) => {
    const { rowSelection, selected, onSelectAction } = selection;

    useEffect(() => {
        // When table data changes, selection should be cleared
        onSelectAction([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource]);

    const onRow = useCallback(
        ({ id }: NamedEntity, index?: number) =>
            ({
                'data-testid': `row-${index}`,
                onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                        onSelectAction([id]);
                    } else {
                        onSelectAction(id);
                    }
                },
            } as React.HTMLAttributes<HTMLElement>),
        [, onSelectAction],
    );

    const setRowClassName = ({ id }: NamedEntity): string =>
        cn(styles.row, { [styles.selected]: selected.includes(id) });

    return (
        <div className={styles.card}>
            <div className={styles.table}>
                <Table
                    rowKey="value"
                    size="middle"
                    columns={columns}
                    dataSource={dataSource}
                    onRow={onRow}
                    onChange={onChange}
                    rowSelection={dataSource.length > 0 ? rowSelection : undefined}
                    rowClassName={setRowClassName}
                    borderBottom={false}
                    scroll={{ y: 341 }}
                />
            </div>
            {dataSource.length > 0 && (
                <div className={styles.footer}>
                    <div className={styles.footerContent}>
                        <span className={styles.count}>{`${selected.length} items selected`}</span>
                        {footer}
                        <div className={styles.button}>{action}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
