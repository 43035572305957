import { useState, useCallback } from 'react';
import { TableProps, ChangeEventInfo } from '@didww/ui-kit';
import { TableSorter } from 'shared/types/common';
import { getSortedInfo, getInitialSorter } from './helpers';

export type ListSorter<ResourceType> = {
    sorter: TableSorter;
    handleTableChange: TableProps<ResourceType>['onChange'];
};

export const useListSorter = <ResourceType>(initialSort = '-id'): ListSorter<ResourceType> => {
    const [sorter, setSorter] = useState(getInitialSorter(initialSort));

    const handleTableChange = useCallback((newSorter: ChangeEventInfo<ResourceType>['sorter']): void => {
        setSorter(getSortedInfo(newSorter));
    }, []);

    return { sorter, handleTableChange };
};
