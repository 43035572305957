import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteIncomingTrunkMutation } from 'entities/incoming-trunks';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const IncomingTrunkDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteIncomingTrunk] = useDeleteIncomingTrunkMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected Incoming Trunk',
        onConfirm: async (): Promise<void> => {
            await deleteIncomingTrunk(id)
                .unwrap()
                .then(() => {
                    replace(routes.incomingTrunks.list);

                    message.success('Incoming Trunk deleted');
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
