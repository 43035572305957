import React from 'react';
import { useSelector } from 'app/hooks';
import { CustomersList } from 'features/customers';
import { routes } from 'shared/routes';
import { Create } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const CustomersListPage: React.FC = () => {
    const isCreateAllowed = useSelector((state) => state.auth.customer_create_allowed);

    return (
        <PageLayout>
            <PageLayout.Header
                title="Customers"
                actions={
                    isCreateAllowed ? [<Create key="create" route={routes.customers.create} />] : undefined
                }
            />
            <PageLayout.Content>
                <CustomersList />
            </PageLayout.Content>
        </PageLayout>
    );
};
