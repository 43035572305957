import { loadByQuery, QueryThunk } from 'shared/api';
import { Gateway } from '../types';

export const fetchGatewaysOptions =
    (customerId: string, page: number, name: string | undefined): QueryThunk<Gateway> =>
    (dispatch) => {
        const filter: Record<string, string> = {
            'customer.id': customerId,
        };

        if (name) {
            filter.name = name;
        }

        return dispatch(
            loadByQuery<Gateway>('termination_gateways', {
                fields: {
                    termination_gateways: 'name',
                },
                filter,
                page: {
                    number: page,
                    size: 10,
                },
                sort: '-id',
            }),
        );
    };
