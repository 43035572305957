import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    AvaiableDidNumber,
    AvaiableDidNumberFilter,
    AvaiableDidNumberCreateAttrs,
    AvaiableDidNumberEditAttrs,
} from './types';

type AvailableNumberQuery = {
    filter: AvaiableDidNumberFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'available_did_numbers';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME, ['external_account_routes']);

const availaleNumbersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAvailableNumbers: builder.query<SuccessResponse<AvaiableDidNumber>, AvailableNumberQuery>({
            query: ({ filter: { customer_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...filter,
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getAvailableNumber: builder.query<SuccessResponse<AvaiableDidNumber>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postAvailableNumber: builder.mutation<
            SuccessResponse<AvaiableDidNumber>,
            AvaiableDidNumberCreateAttrs
        >({
            query: ({ customer_id, ...attributes }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes,
                    relationships: {
                        customer: {
                            data: {
                                id: customer_id,
                                type: 'customers',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchAvailableNumber: builder.mutation<
            SuccessResponse<AvaiableDidNumber>,
            { id: string; values: AvaiableDidNumberEditAttrs }
        >({
            query: ({ id, values }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    attributes: values,
                },
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteAvailableNumber: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetAvailableNumbersQuery,
    useGetAvailableNumberQuery,
    usePostAvailableNumberMutation,
    usePatchAvailableNumberMutation,
    useDeleteAvailableNumberMutation,
} = availaleNumbersApi;
