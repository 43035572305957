import React, { useState } from 'react';
import dayjs from 'dayjs';
import {
    ActiveCallsChart,
    ActiveCallsView,
    OutgoingActiveCallsFilter,
    useGetActiveCallsQuery,
} from 'entities/outgoing-active-calls';
import { fetchTotalCapacity } from 'entities/capacity-groups';
import { PageLoader } from 'shared/ui/spinners';
import { FormContext } from 'entities/form-context';
import { ListLayout } from 'shared/ui/layouts';
import { NoResults } from 'shared/ui/no-results';
import { Spin } from 'shared/ui/spinners';
import { useListLoading, useListFilter } from 'shared/lib/hooks/list';
import { useDispatch } from 'app/hooks';
import { useAsyncEffect } from 'shared/lib/hooks/use-async-effect';
import { STORAGE_KEY } from '../config';
import { Filter } from './filter';

export const List: React.FC = () => {
    const dispatch = useDispatch();

    const [totalCapacity, setTotalCapacity] = useState<null | number>(null);
    const [view, setView] = useState<ActiveCallsView>('day');

    const { filterValues, formik } = useListFilter<OutgoingActiveCallsFilter>({
        storageKey: STORAGE_KEY,
        initialValues: {
            from_datetime: dayjs().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            to_datetime: dayjs().add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            src_number_contains: '',
            dst_number_contains: '',
        },
    });

    const { data, isLoading, isFetching } = useGetActiveCallsQuery({
        ...filterValues,
        from_datetime: dayjs(filterValues.from_datetime).format('YYYY-MM-DD HH:mm:ss'),
        to_datetime: dayjs(filterValues.to_datetime).format('YYYY-MM-DD HH:mm:ss'),
    });

    const isTotalCapacityLoading = useAsyncEffect(async () => {
        const total = await dispatch(fetchTotalCapacity());

        setTotalCapacity(total);
    });

    const isListLoading = useListLoading(isLoading);

    if (isListLoading || isTotalCapacityLoading || !data) {
        return <PageLoader />;
    }

    const [{ lines }] = data.resources;

    return (
        <ListLayout>
            <ListLayout.Header>
                <FormContext formik={formik}>
                    <Filter storageKey={STORAGE_KEY} currentView={view} onViewChange={setView} />
                </FormContext>
            </ListLayout.Header>
            <ListLayout.Content>
                <Spin spinning={isFetching}>
                    {lines.length === 0 ? (
                        <NoResults />
                    ) : (
                        <ActiveCallsChart
                            lines={lines}
                            totalCapacity={totalCapacity}
                            fromDatetime={filterValues.from_datetime}
                            toDatetime={filterValues.to_datetime}
                            view={view}
                        />
                    )}
                </Spin>
            </ListLayout.Content>
        </ListLayout>
    );
};
