import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetCapacityGroupQuery } from 'entities/capacity-groups';
import { Form } from './form';

interface Props {
    id: string;
}

export const LoadableForm: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetCapacityGroupQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [group] = data.resources;

    return <Form group={group} />;
};
