import React from 'react';
import { Spacer } from 'shared/ui/spacer';
import { ErrorLayout } from 'shared/ui/layouts';
import { LottieLogo } from 'shared/ui/lottie-logo';
import json from './animation.json';
import styles from './styles.module.scss';

export const OfflinePage: React.FC = () => (
    <ErrorLayout>
        <LottieLogo json={json} width="160px" height="140px" />
        <Spacer size={10} />
        <div className={styles.title}>Internet connection lost</div>
        <div className={styles.subTitle}>
            Please check your internet settings or wait for the page to reload once the connection is
            established.
        </div>
        <Spacer size={34} />
    </ErrorLayout>
);
