import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { ExternalAccountShow, ExternalAccountDelete } from 'features/external-accounts';
import { Edit } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const ExternalAccountShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <PageLayout.Header title={`External Account #${id}`} />
            <PageLayout.Content>
                <ExternalAccountShow id={id} />
            </PageLayout.Content>
            <PageLayout.Footer
                backPath={routes.externalAccounts.list}
                actions={[
                    <Edit key="delete" id={id} route={routes.externalAccounts.edit} />,
                    <ExternalAccountDelete key="delete" id={id} />,
                ]}
            />
        </PageLayout>
    );
};
