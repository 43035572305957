import React from 'react';
import { ActiveCallsView } from 'entities/outgoing-active-calls';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput, ControlledSelect } from 'shared/ui/fields';
import { RangeDatePickerField, useDefaults } from 'shared/ui/fields/range-date-picker-field';

const viewOptions = [
    {
        value: 'day',
        label: 'Daily',
    },
    {
        value: 'hour',
        label: 'Hourly',
    },
    {
        value: 'hour_of_day',
        label: 'Grouped',
    },
];

const optionalFilters = [
    {
        name: 'src_number_contains',
        label: 'Source number',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="src_number_contains" labelText="Source number" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'dst_number_contains',
        label: 'Destination number',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="dst_number_contains" labelText="Destination number" />
            </ConditionalFilter.Item>
        ),
    },
];

interface Props {
    storageKey: string;
    currentView: ActiveCallsView;
    onViewChange: (newView: ActiveCallsView) => void;
}

export const Filter: React.FC<Props> = ({ storageKey, currentView, onViewChange }) => {
    const datePickerDefaults = useDefaults();

    return (
        <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
            <ConditionalFilter.Item size="large">
                <RangeDatePickerField
                    labelText="From / To"
                    fromFieldName="from_datetime"
                    toFieldName="to_datetime"
                    withTime
                    {...datePickerDefaults}
                />
            </ConditionalFilter.Item>
            <ConditionalFilter.Item size="small">
                <ControlledSelect
                    value={currentView}
                    onChange={onViewChange}
                    options={viewOptions}
                    isSearchable={false}
                    labelText="View"
                />
            </ConditionalFilter.Item>
            <ConditionalFilter.Item>
                <CustomersSelect labelText="Customer" />
            </ConditionalFilter.Item>
        </ConditionalFilter>
    );
};
