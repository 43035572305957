import React from 'react';
import { useTimer } from 'react-compound-timer';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    onResendCode: () => void;
}

export const ResendCode: React.FC<Props> = ({ onResendCode }) => {
    const timer = useTimer({
        initialTime: 40000,
        direction: 'backward',
        startImmediately: true,
    });

    function resendCodeTimer(): void {
        onResendCode();
        timer.controls.reset();
        timer.controls.start();
    }

    return (
        <div className={styles.resendWrap}>
            <span className={styles.resendText}>Didn&apos;t receive a code?</span>
            <div className={styles.expandable}></div>
            <Button
                text="Try again"
                type="text"
                color="blue"
                onClick={resendCodeTimer}
                disabled={timer.value.state === 'PLAYING'}
            />
            <span className={styles.resendTimer}>
                {timer.value.state === 'PLAYING' ? `in ${timer.value.s} seconds` : ''}
            </span>
        </div>
    );
};
