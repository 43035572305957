import React, { useCallback } from 'react';
import { FieldLabel, RangeDatePicker } from '@didww/ui-kit';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useField } from 'formik';
import { Props } from '../types';
import { convertDateToString } from '../lib';
import styles from './styles.module.scss';

dayjs.extend(utc);

export const RangeDatePickerField: React.FC<Props> = ({
    fromFieldName,
    toFieldName,
    labelText,
    onClick,
    withTime = false,
    ...props
}) => {
    const [{ value: fromFieldValue }, , helpersFrom] = useField(fromFieldName);
    const [{ value: toFieldValue }, , helpersTo] = useField(toFieldName);

    const onChange = useCallback(
        ({ from, to }: { from?: Dayjs; to?: Dayjs }) => {
            helpersFrom.setValue(from ? convertDateToString(from, withTime) : '');
            helpersTo.setValue(to ? convertDateToString(to, withTime) : '');
        },
        [helpersFrom, helpersTo, withTime],
    );

    const from = fromFieldValue ? dayjs(fromFieldValue).utc(true) : undefined;
    const to = toFieldValue ? dayjs(toFieldValue).utc(true) : undefined;

    return (
        <div className={styles.rangeDatePickerField} onClick={onClick}>
            {!!labelText && <FieldLabel title={labelText} />}
            <RangeDatePicker
                {...props}
                onChange={onChange}
                from={from}
                to={to}
                portalTargetId="select-portal"
                withTime={withTime}
            />
        </div>
    );
};
