import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { IncomingTrunkCdrsListPage } from './list';
import { IncomingTrunkCdrShowPage } from './show';

export const IncomingTrunkCdrsPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.incomingTrunkCdrs.list}>
            <IncomingTrunkCdrsListPage />
        </Route>
        <Route exact path={routes.incomingTrunkCdrs.show}>
            <IncomingTrunkCdrShowPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.incomingTrunkCdrs.list} />
        </Route>
    </Switch>
);
