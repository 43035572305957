import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { AvailableNumberSelect, CustomersSelect } from 'entities/form-selects';
import { TextInput } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

interface Props {
    customerId: string;
}

export const Fields: React.FC<Props> = ({ customerId }) => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="id" labelText={<LabelInfo title="External Account ID" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <CustomersSelect labelText={<LabelInfo title="Customer" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <AvailableNumberSelect
                customerId={customerId}
                labelText={<LabelInfo title="External caller ID" />}
            />
        </FormLayout.Field>
    </FormLayout>
);
