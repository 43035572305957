export const routes = {
    dashboard: '/',
    signIn: '/login',
    twoFactorAuth: '/login_verify',
    settings: '/settings',
    capacityGroups: {
        list: '/capacity_groups',
        show: '/capacity_groups/:id/show',
        edit: '/capacity_groups/:id/edit',
        manageCustomers: '/capacity_groups/:id/manage',
        create: '/capacity_groups/create',
    },
    customers: {
        list: '/customers',
        show: '/customers/:id/show',
        edit: '/customers/:id/edit',
        create: '/customers/create',
    },
    availableNumbers: {
        list: '/available_did_numbers',
        show: '/available_did_numbers/:id/show',
        edit: '/available_did_numbers/:id/edit',
        create: '/available_did_numbers/create',
    },
    sipAccounts: {
        list: '/sip_device_routes',
        show: '/sip_device_routes/:id/show',
        edit: '/sip_device_routes/:id/edit',
    },
    externalAccounts: {
        list: '/external_account_routes',
        show: '/external_account_routes/:id/show',
        edit: '/external_account_routes/:id/edit',
        create: '/external_account_routes/create',
    },
    incomingTrunks: {
        list: '/incoming_trunks',
        show: '/incoming_trunks/:id/show',
        edit: '/incoming_trunks/:id/edit',
        create: '/incoming_trunks/create',
    },
    incomingTrunkCdrs: {
        list: '/incoming_trunk_cdrs',
        show: '/incoming_trunk_cdrs/:id/show',
    },
    terminationCdrs: {
        list: '/termination_cdrs',
        show: '/termination_cdrs/:id/show',
    },
    terminationGateways: {
        list: '/termination_gateways',
        show: '/termination_gateways/:id/show',
        edit: '/termination_gateways/:id/edit',
        create: '/termination_gateways/create',
    },
    terminationRoutes: {
        list: '/termination_routes',
        show: '/termination_routes/:id/show',
        edit: '/termination_routes/:id/edit',
        create: '/termination_routes/create',
    },
    error: {
        deploy: '/server_deploy',
        somethingWrong: '/something_went_wrong',
        server: '/server_error',
        notFound: '/not_found',
        offline: '/offline',
    },
};
