import React from 'react';
import { TerminationCdr } from 'entities/termination-cdrs';
import { GridLayout } from 'shared/ui/layouts';

interface Props {
    terminationCdr: TerminationCdr;
}

export const CdrInfo: React.FC<Props> = ({ terminationCdr }) => (
    <GridLayout>
        <GridLayout.Row title="Termination CDR ID">{terminationCdr.id}</GridLayout.Row>
        <GridLayout.Row title="Time start">{terminationCdr.time_start}</GridLayout.Row>
        <GridLayout.Row title="Time connect">{terminationCdr.time_connect}</GridLayout.Row>
        <GridLayout.Row title="Time end">{terminationCdr.time_end}</GridLayout.Row>
        <GridLayout.Row title="Local tag">{terminationCdr.local_tag}</GridLayout.Row>
        <GridLayout.Row title="Global tag">{terminationCdr.global_tag}</GridLayout.Row>
        <GridLayout.Row title="Duration">{terminationCdr.duration}</GridLayout.Row>
        <GridLayout.Row title="legA disconnect code">{terminationCdr.aleg_code}</GridLayout.Row>
        <GridLayout.Row title="legA disconnect reason">{terminationCdr.aleg_reason}</GridLayout.Row>
        <GridLayout.Row title="Src name in">{terminationCdr.src_name_in}</GridLayout.Row>
        <GridLayout.Row title="Src number in">{terminationCdr.src_number_in}</GridLayout.Row>
        <GridLayout.Row title="Dst number in">{terminationCdr.dst_number_in}</GridLayout.Row>
        <GridLayout.Row title="Orig call ID">{terminationCdr.orig_call_id}</GridLayout.Row>
    </GridLayout>
);
