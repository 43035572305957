import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { apiSlice } from 'shared/api';
import { authReducer } from 'entities/auth';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStore = () =>
    configureStore({
        reducer: {
            auth: authReducer,
            [apiSlice.reducerPath]: apiSlice.reducer,
        },
        middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    });

export const store = getStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
