import React, { useCallback } from 'react';
import { ColumnsType } from '@didww/ui-kit';
import { GroupCell } from 'entities/customers';
import { Button } from 'shared/ui/buttons';
import { Pagination } from 'shared/ui/pagination';
import { ListSorter } from 'shared/lib/hooks/list/use-list-sorter';
import { ListPagination } from 'shared/lib/hooks/list';
import { CustomerEntity } from '../types';
import { useSelectionState } from '../lib';
import { CustomersTable } from './customers-table';

interface Props {
    dataSource: CustomerEntity[];
    handleAssign: (ids: string[]) => Promise<void>;
    totalRecords: number;
    isPushing: boolean;
    pagination: ListPagination;
    tableSorter: ListSorter<CustomerEntity>;
}

const getColumns = (sorter: ListSorter<CustomerEntity>['sorter']): ColumnsType<CustomerEntity> => [
    {
        key: 'id',
        dataIndex: 'id',
        width: 200,
        title: 'Customer ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        width: 250,
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
    },
    {
        key: 'capacity_group',
        title: 'Capacity Group',
        width: 250,
        render: function CapacityGroupColumn(_, row) {
            return <GroupCell group={row.capacity_group} />;
        },
    },
];

export const AvailableTable: React.FC<Props> = ({
    dataSource,
    isPushing,
    handleAssign,
    totalRecords,
    pagination,
    tableSorter,
}) => {
    const selection = useSelectionState();

    const { selected } = selection;

    const columns = getColumns(tableSorter.sorter);

    const isAssignDisabled = selected.length === 0 || isPushing;

    const onButtonClick = useCallback(() => {
        handleAssign(selected);
    }, [handleAssign, selected]);

    return (
        <CustomersTable
            dataSource={dataSource}
            columns={columns}
            selection={selection}
            onChange={tableSorter.handleTableChange}
            footer={
                <Pagination
                    page={pagination.page}
                    onChangePage={pagination.setPage}
                    totalRecords={totalRecords}
                />
            }
            action={<Button disabled={isAssignDisabled} text="Assign to Group" onClick={onButtonClick} />}
        />
    );
};
