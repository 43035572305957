import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CustomerShow, CustomerDelete } from 'features/customers';
import { Edit } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const CustomerShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <CustomerShow id={id} />
            <PageLayout.Footer
                backPath={routes.customers.list}
                actions={[
                    <Edit key="edit" id={id} route={routes.customers.edit} />,
                    <CustomerDelete key="delete" id={id} />,
                ]}
            />
        </PageLayout>
    );
};
