import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CapacityGroupsListPage } from './list';
import { CapacityGroupShowPage } from './show';
import { CapacityGroupEditPage } from './edit';
import { CapacityGroupManageCustomersPage } from './manage-customers';
import { CapacityGroupCreatePage } from './create';

export const CapacityGroupsPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.capacityGroups.list}>
            <CapacityGroupsListPage />
        </Route>
        <Route exact path={routes.capacityGroups.show}>
            <CapacityGroupShowPage />
        </Route>
        <Route exact path={routes.capacityGroups.create}>
            <CapacityGroupCreatePage />
        </Route>
        <Route exact path={routes.capacityGroups.edit}>
            <CapacityGroupEditPage />
        </Route>
        <Route exact path={routes.capacityGroups.manageCustomers}>
            <CapacityGroupManageCustomersPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.capacityGroups.list} />
        </Route>
    </Switch>
);
