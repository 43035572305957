import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { AccessIpsSelect } from 'shared/ui/fields';

interface Props {
    isDisabled: boolean;
}

export const AllowedIps: React.FC<Props> = ({ isDisabled }) => {
    return (
        <AccessIpsSelect
            isDisabled={isDisabled}
            name="allowed_ips"
            labelText={<LabelInfo title="Allowed IPs" />}
        />
    );
};
