import React from 'react';
import { Tooltip } from '@didww/ui-kit';
import { truncateText } from './truncate-text';

interface Props {
    text: string | null | undefined;
    maxLength?: number;
    className?: string;
    testId?: string;
}

export const TruncateTooltip: React.FC<Props> = ({ text, className, maxLength = 40, testId }) => {
    if (!text) return null;

    const showTooltip = text.trim().length > maxLength;
    const shortText = truncateText(text, maxLength);

    return (
        <Tooltip title={text} show={showTooltip}>
            <span className={className} data-testid={testId}>
                {shortText}
            </span>
        </Tooltip>
    );
};
