import React from 'react';
import { Tooltip } from '@didww/ui-kit';
import { TooltipTitle } from 'shared/ui/tooltip-title';
import { SipDeviceRoute } from '../../types';
import { getUniqAllowedIps } from '../../model';
import styles from './styles.module.scss';

interface Props {
    sipDeviceRoute: SipDeviceRoute;
}

export const AllowedIPCell: React.FC<Props> = ({ sipDeviceRoute }) => {
    const ips = getUniqAllowedIps(sipDeviceRoute);

    const showTooltip = ips.length > 1;

    const ip = ips[0]?.slice(0, 18); // Column looks ugly if IPv6 is first element in IPs array

    return (
        <Tooltip
            title={ips.map((ip) => (
                <TooltipTitle title={<div className={styles.text}>{ip}</div>} key={ip} />
            ))}
            show={showTooltip}
            maxHeight={190}
        >
            <div className={styles.text}>
                {ip}
                {showTooltip ? ', ...' : ''}
            </div>
        </Tooltip>
    );
};
