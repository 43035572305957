import React from 'react';
import { Login } from 'features/login';
import styles from './styles.module.scss';

export const Page: React.FC = () => {
    return (
        <div className={styles.page}>
            <Login />
        </div>
    );
};
