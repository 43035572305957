import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PageLoader } from 'shared/ui/spinners';
import { useGetAvailableNumberQuery } from 'entities/available-did-numbers';
import { routes } from 'shared/routes';
import { message } from 'shared/message';
import { Form } from './form';

interface Props {
    id: string;
}

export const LoadableForm: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const { data, isLoading } = useGetAvailableNumberQuery(id);

    const [availableNumber] = data?.resources || [];

    useEffect(() => {
        if (availableNumber?.readonly) {
            replace(routes.availableNumbers.list);

            message.warning(
                `${availableNumber?.number} cannot be edited because the Available Number is read-only`,
            );
        }
    }, [availableNumber, replace]);

    if (isLoading || !data || availableNumber?.readonly) {
        return <PageLoader />;
    }

    return <Form availableNumber={availableNumber} />;
};
