import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    href?: string;
    path?: string;
    disabled?: boolean;
    target?: string;
}

export const Link: React.FC<Props> = ({ path, href, children, target, ...props }) => {
    const defaultProps = {
        ...props,
        target,
        rel: !target ? undefined : 'noopener noreferrer',
    };
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.disabled) {
            e.preventDefault();
        }
    };

    return (
        <>
            {path && (
                <RouterLink {...defaultProps} onClick={handleClick} to={props.disabled ? '#' : path}>
                    {children}
                </RouterLink>
            )}
            {href && (
                <a {...defaultProps} href={props.disabled ? '#' : href} onClick={handleClick}>
                    {children}
                </a>
            )}
        </>
    );
};
