import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { ConditionalFilter } from 'entities/conditional-filter';
import { CapacityGroupsSelect } from 'entities/form-selects';
import { TextInput } from 'shared/ui/fields';

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="Customer ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <CapacityGroupsSelect labelText="Capacity Group" allowNotAssign />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <TextInput name="capacity_limit" labelText={<LabelInfo title="Capacity limit" />} />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <TextInput name="sip_account_limit" labelText={<LabelInfo title="SIP-Account limit" />} />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
