import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    ExternalAccountRouteCreateAttrs,
    usePostExternalAccountMutation,
} from 'entities/external-account-routes';
import { routes } from 'shared/routes';
import { FormLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

export const Form: React.FC = () => {
    const { push } = useHistory();

    const [postExternalAccount] = usePostExternalAccountMutation();

    const formik = useFormik<ExternalAccountRouteCreateAttrs>({
        initialValues: {
            id: '',
            customer_id: '',
            available_number_id: '',
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await postExternalAccount(values)
                .unwrap()
                .then(({ resources }) => {
                    const [{ id }] = resources;

                    push(routes.externalAccounts.show.replace(':id', id));

                    message.success('External Account created');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <FormContext formik={formik}>
            <Fields customerId={formik.values.customer_id} />
            <FormLayout.Footer backPath={routes.externalAccounts.list} submitText="Create" />
        </FormContext>
    );
};
