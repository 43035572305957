import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface ItemProps {
    size?: 'small' | 'medium' | 'large';
}

const Item: React.FC<ItemProps> = ({ size = 'medium', children }) => (
    <div className={cn(styles.item, styles[size])}>{children}</div>
);

interface FilterProps {
    actions?: React.ReactNode;
}

export const Filter: React.FC<FilterProps> & { Item: typeof Item } = ({ children, actions = null }) => (
    <div className={styles.filter} data-testid="filter">
        <div className={styles.fields}>{children}</div>
        {!!actions && <div className={styles.actions}>{actions}</div>}
    </div>
);

Filter.Item = Item;
