import React from 'react';
import { Button } from 'shared/ui/buttons';
import { InlineSvg } from 'shared/ui/inline-svg';
import styles from './styles.module.scss';

interface Props {
    onClick: () => void;
}

export const TableButton: React.FC<Props> = ({ onClick }) => (
    <Button ghost onClick={onClick} className={styles.columnsButton}>
        <div className={styles.icon}>
            <InlineSvg type="table" />
        </div>
    </Button>
);
