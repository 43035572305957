import React from 'react';
import styles from './styles.module.scss';

interface Props {
    title: string;
    actions?: React.ReactNode[];
}

export const Header: React.FC<Props> = ({ title, actions }) => (
    <div className={styles.header}>
        <h1 className={styles.title} data-testid="page-title">
            {title}
        </h1>
        {!!actions && (
            <div className={styles.actions}>
                {actions.map((action, index) => (
                    <div className={styles.action} key={index}>
                        {action}
                    </div>
                ))}
            </div>
        )}
    </div>
);
