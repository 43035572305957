import { SuccessResponse, BaseQueryOptions, apiSlice, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import { TerminationCdr, TerminationCdrsFilter } from './types';

type TerminationCdrsQuery = {
    filter: TerminationCdrsFilter;
    page: Page;
};

const RESOURCE_NAME = 'termination_cdrs';

const tagsProvider = getTagsProviders(RESOURCE_NAME);

const terminationCdrsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getTerminationCdrs: builder.query<SuccessResponse<TerminationCdr>, TerminationCdrsQuery>({
            query: ({
                filter: { customer_id, termination_gateway_id, termination_route_id, ...filter },
                page,
            }) => {
                const queryFilter: BaseQueryOptions['filter'] = filter;

                if (customer_id) {
                    queryFilter['customer.id'] = customer_id;
                }
                if (termination_gateway_id) {
                    queryFilter['termination_gateway.id'] = termination_gateway_id;
                }
                if (termination_route_id) {
                    queryFilter['termination_route.id'] = termination_route_id;
                }

                return {
                    url: RESOURCE_NAME,
                    method: 'GET',
                    filter: queryFilter,
                    page,
                };
            },
            providesTags: tagsProvider.list,
        }),
        getTerminationCdr: builder.query<SuccessResponse<TerminationCdr>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
            }),
            providesTags: tagsProvider.show,
        }),
    }),
});

export const { useGetTerminationCdrsQuery, useGetTerminationCdrQuery } = terminationCdrsApi;
