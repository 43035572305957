import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Sidebar } from 'shared/ui/sidebar';
import { ProfileMenu } from 'features/profile-menu';
import { Hamburger } from 'shared/ui/hamburger';
import { sideMenuLinks } from './config';
import styles from './styles.module.scss';

export const Layout: React.FC = ({ children }) => {
    const [isOpened, setOpened] = useState(false);

    const openSideMenu = (): void => {
        setOpened(true);
    };

    const closeSideMenu = (): void => {
        setOpened(false);
    };

    const { pathname } = useLocation();

    return (
        <div className={styles.layout}>
            <Hamburger onClick={openSideMenu} />
            <div className={styles.sideMenu}>
                <Sidebar
                    isOpened={isOpened}
                    selectedOption={pathname}
                    close={closeSideMenu}
                    header={
                        <>
                            <ProfileMenu close={closeSideMenu} />
                            <div className={styles.divider} />
                        </>
                    }
                    links={sideMenuLinks}
                />
            </div>
            <div className={styles.body}>{children}</div>
        </div>
    );
};
