import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    id: string;
    route: string;
    round?: boolean;
}

export const Show: React.FC<Props> = ({ id, route }) => {
    const path = route.replace(':id', encodeURIComponent(id));

    return (
        <div className={styles.show}>
            <Button shape="circle" path={path} ghost>
                <InlineSvg type="show" size={15} />
            </Button>
        </div>
    );
};
