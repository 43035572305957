import React from 'react';
import { TerminationGateway } from 'entities/termination-gateways';
import { BooleanCell, Codecs } from 'shared/ui/cells';
import { GridLayout, PageLayout } from 'shared/ui/layouts';

interface Props {
    terminationGateway: TerminationGateway;
}

export const TerminationGatewayInfo: React.FC<Props> = ({ terminationGateway }) => (
    <>
        <PageLayout.Header title={`Termination Gateway ${terminationGateway.name}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Termination Gateway ID">{terminationGateway.id}</GridLayout.Row>
                <GridLayout.Row title="Name">{terminationGateway.name}</GridLayout.Row>
                <GridLayout.Row title="Host">{terminationGateway.host}</GridLayout.Row>
                <GridLayout.Row title="Port">{terminationGateway.port}</GridLayout.Row>
                <GridLayout.Row title="Customer ID">{terminationGateway.customer?.id || ''}</GridLayout.Row>
                <GridLayout.Row title="Customer">{terminationGateway.customer?.name || ''}</GridLayout.Row>
                <GridLayout.Row title="Operator">
                    <BooleanCell value={terminationGateway.operator} />
                </GridLayout.Row>
                <GridLayout.Row title="Network protocol">
                    {terminationGateway.network_protocol}
                </GridLayout.Row>
                <GridLayout.Row title="Auth name">{terminationGateway.authorization_name}</GridLayout.Row>
                <GridLayout.Row title="Auth password">
                    {terminationGateway.authorization_password}
                </GridLayout.Row>
                <GridLayout.Row title="SRC rewrite rule">
                    {terminationGateway.src_rewrite_rule}
                </GridLayout.Row>
                <GridLayout.Row title="SRC rewrite result">
                    {terminationGateway.src_rewrite_result}
                </GridLayout.Row>
                <GridLayout.Row title="DST rewrite rule">
                    {terminationGateway.dst_rewrite_rule}
                </GridLayout.Row>
                <GridLayout.Row title="DST rewrite result">
                    {terminationGateway.dst_rewrite_result}
                </GridLayout.Row>
                <GridLayout.Row title="Codecs">
                    <Codecs codecs={terminationGateway.codecs} />
                </GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
    </>
);
