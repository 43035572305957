import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetTerminationGatewayQuery } from 'entities/termination-gateways';
import { Form } from './form';

interface Props {
    id: string;
}

export const LoadableForm: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetTerminationGatewayQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [terminationGateway] = data.resources;

    return <Form terminationGateway={terminationGateway} />;
};
