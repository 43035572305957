import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'app/hooks';
import { ListState, useListState } from 'shared/lib/hooks/list';
import { CustomerEntity, FilterValues } from '../../types';
import { actions } from '../../model';

export type AvailableTableConfig = {
    dataSource: CustomerEntity[];
    totalRecords: number;
    isFetching: boolean;
    resetPaginationOfAvailableCustomers: () => void;
    listState: ListState<FilterValues, CustomerEntity>;
};

export const useAvailableTable = (groupId: string): AvailableTableConfig => {
    const dispatch = useDispatch();

    const [isFetching, setFetching] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [dataSource, setDataSource] = useState<CustomerEntity[]>([]);

    const listState = useListState<FilterValues, CustomerEntity>({
        initialFilter: { id: '', name: '', capacity_group_id: '' },
    });

    const {
        query,
        pagination: { page, setPage },
    } = listState;

    const fetchData = useCallback(async () => {
        setFetching(true);

        const {
            data,
            meta: { record_count },
        } = await dispatch(actions.fetchAvailableCustomers(groupId, query));

        setDataSource(data);
        setTotalRecords(record_count);

        setFetching(false);
    }, [dispatch, groupId, query]);

    const resetPaginationOfAvailableCustomers = useCallback(() => {
        setPage({ size: page.size, number: 1 });
    }, [page, setPage]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return {
        dataSource,
        isFetching,
        resetPaginationOfAvailableCustomers,
        listState,
        totalRecords,
    };
};
