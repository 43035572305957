import React from 'react';
import { Autocomplete } from '@didww/ui-kit';
import { ComponentProps } from './types';
import { useMultiSelectField } from '../multi-select/hooks';

export const Multi: React.FC<ComponentProps> = ({
    name,
    isAutoSubmit,
    onLoad,
    onUnmount,
    ...selectProps
}) => {
    const { value, onChange } = useMultiSelectField({ name, isAutoSubmit });

    return (
        <Autocomplete
            {...selectProps}
            onLoad={onLoad}
            isMulti
            name={name}
            value={value}
            onUnmount={onUnmount}
            onChange={onChange}
        />
    );
};
