import React, { useState } from 'react';
import { MultiSelect } from 'shared/ui/fields/select';
import { useField } from 'formik';
import { getOptions } from 'shared/lib/helpers';
import { isValidIP } from 'shared/lib/validation';
import { SimpleValue } from 'shared/ui/fields/select';

interface Props {
    isDisabled: boolean;
    name: string;
    labelText: React.ReactNode;
}

export const AccessIpsSelect: React.FC<Props> = ({ isDisabled, name, labelText }) => {
    const [{ value }] = useField<SimpleValue[]>(name);
    const options = getOptions(value);

    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const handleIsValidNewOption = (inputValue: string) => {
        const isValid = isValidIP(inputValue, options);
        setMenuIsOpen(isValid);
        return isValid;
    };

    return (
        <MultiSelect
            labelText={labelText}
            isDisabled={isDisabled}
            name={name}
            isCreatable
            menuIsOpen={menuIsOpen}
            isValidNewOption={(inputValue: string) => handleIsValidNewOption(inputValue)}
            options={options}
            placeholder=""
            valueOnClear={[]}
        />
    );
};
