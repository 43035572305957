import React from 'react';
import { TooltipContent } from './tooltip-content';
import { Tooltip } from '@didww/ui-kit';
import { validatePassword } from '../validator';

type Props = {
    visible: boolean;
    fieldValue: string;
    children: React.ReactElement;
};

export const PasswordTooltip: React.FC<Props> = ({ children, visible, fieldValue }) => {
    const { color, message, progress, complexityRequirements } = validatePassword(fieldValue);

    return (
        <Tooltip
            position="bottom"
            width={300}
            visible={visible}
            title={
                <TooltipContent
                    color={color}
                    message={message}
                    progress={progress}
                    complexityRequirements={complexityRequirements}
                />
            }
        >
            {children}
        </Tooltip>
    );
};
