import React from 'react';
import { TERMINATION_MODE_LABELS, Customer, LimitCell, GroupCell } from 'entities/customers';
import { GridLayout, PageLayout } from 'shared/ui/layouts';

interface Props {
    customer: Customer;
}

export const CustomerInfo: React.FC<Props> = ({ customer }) => (
    <>
        <PageLayout.Header title={`Customer ${customer.name}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Customer ID">{customer.id}</GridLayout.Row>
                <GridLayout.Row title="Name">{customer.name}</GridLayout.Row>
                <GridLayout.Row title="UI language">{customer.language}</GridLayout.Row>
                <GridLayout.Row title="Capacity limit">
                    <LimitCell value={customer.capacity_limit} />
                </GridLayout.Row>
                <GridLayout.Row title="SIP-Account limit">
                    <LimitCell value={customer.sip_account_limit} />
                </GridLayout.Row>
                <GridLayout.Row title="Capacity Group">
                    <GroupCell group={customer.capacity_group} />
                </GridLayout.Row>
                <GridLayout.Row title="Termination mode">
                    {TERMINATION_MODE_LABELS[customer.trm_mode]}
                </GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
    </>
);
