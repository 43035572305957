import React from 'react';
import { SingleSelect, SingleSelectProps } from '@didww/ui-kit';

interface Props extends SingleSelectProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (value: any) => void;
}

export const ControlledSelect: React.FC<Props> = (props) => (
    <SingleSelect {...props} name="controlled-select" />
);
