import { useState, useMemo, useCallback } from 'react';
import { FormikContextType } from 'formik';
import { useDispatch } from 'app/hooks';
import { useListFilter } from 'shared/lib/hooks/list';
import { NamedEntity, FilterValues } from '../../types';
import { actions } from '../../model';

export type AssignedTableConfig = {
    filterFormik: FormikContextType<FilterValues>;
    groupName: string;
    dataSource: NamedEntity[];
    assignedCustomersIds: string[];
    fetchGroup: () => Promise<void>;
    addAssignedCustomers: (addedCustomers: NamedEntity[]) => void;
    removeAssignedCustomers: (removedIds: string[]) => void;
};

export const useAssignedTable = (groupId: string): AssignedTableConfig => {
    const dispatch = useDispatch();

    const [groupName, setGroupName] = useState('');
    const [customers, setCustomers] = useState<NamedEntity[]>([]);

    const { filterValues, formik } = useListFilter<FilterValues>({ initialValues: { id: '', name: '' } });

    const fetchGroup = useCallback(async () => {
        const {
            data: [capacityGroup],
        } = await dispatch(actions.fetchGroup(groupId));

        if (capacityGroup) {
            const { customers = [], name } = capacityGroup;

            setCustomers(customers);
            setGroupName(name);
        }
    }, [dispatch, groupId]);

    const dataSource = useMemo(
        () =>
            customers.filter(({ id, name }) => {
                if (filterValues.id && id !== filterValues.id) return false;
                if (filterValues.name && !name.toLowerCase().includes(filterValues.name.toLowerCase()))
                    return false;

                return true;
            }),
        [filterValues, customers],
    );

    const assignedCustomersIds = useMemo(() => customers.map(({ id }) => id), [customers]);

    const addAssignedCustomers = useCallback((addedCustomers: NamedEntity[]) => {
        setCustomers((oldCustomers) => [...oldCustomers, ...addedCustomers]);
    }, []);

    const removeAssignedCustomers = useCallback((removedIds: string[]) => {
        setCustomers((oldCustomers) => oldCustomers.filter((customer) => !removedIds.includes(customer.id)));
    }, []);

    return {
        groupName,
        dataSource,
        assignedCustomersIds,
        fetchGroup,
        addAssignedCustomers,
        removeAssignedCustomers,
        filterFormik: formik,
    };
};
