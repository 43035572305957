import React from 'react';
import { Redirect } from 'react-router-dom';
import { PageLoader } from 'shared/ui/spinners';
import { useGetExternalAccountQuery } from 'entities/external-account-routes';
import { routes } from 'shared/routes';
import { Form } from './form';

interface Props {
    id: string;
}

export const LoadableForm: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetExternalAccountQuery(id);

    if (isLoading) {
        return <PageLoader />;
    }

    if (!data) {
        // In case user tries to open external account with broken ID (including % or № sign)
        // because we're not able to decode correct id
        // https://jira.in.didww.com/browse/PBXOPUI-60 latest coment
        return <Redirect to={routes.error.somethingWrong} />;
    }

    const [externalAccount] = data.resources;

    return <Form externalAccount={externalAccount} />;
};
