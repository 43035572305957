import { Resource, Relationships, Entity, DefaultRequestMeta, Links } from 'shared/types/resources';
import { SuccessResponse } from '../types';

const normalizeRelationships = (
    rels: Relationships | undefined,
    groupedIncluded: GrouppedIncluded,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Record<string, any> => {
    if (!rels) return {};

    return Object.keys(rels).reduce((accum, relKey) => {
        const { data } = rels[relKey];

        if (!data) return accum;

        if (Array.isArray(data)) {
            const normalizedRel = data.map(({ id, type }) => {
                const relatedResource = groupedIncluded[type][id];

                return normalizeResource(relatedResource, groupedIncluded);
            });

            return {
                ...accum,
                [relKey]: normalizedRel,
            };
        }

        const relatedResource = groupedIncluded[data.type][data.id];

        return {
            ...accum,
            [relKey]: normalizeResource(relatedResource, groupedIncluded),
        };
    }, {});
};

const normalizeResource = <T>(resource: Resource, groupedIncluded: GrouppedIncluded): Entity<T> => {
    const { id, type, attributes, relationships, meta } = resource;

    const normalizedRels = normalizeRelationships(relationships, groupedIncluded);

    return {
        id,
        type,
        ...attributes,
        ...meta,
        ...normalizedRels,
    } as Entity<T>;
};

type GrouppedIncluded = {
    [resourceName: string]: {
        [id: string]: Resource;
    };
};

const groupIncludedByType = (included: Resource[]): GrouppedIncluded => {
    return included.reduce<GrouppedIncluded>((accum, resource) => {
        const { type, id } = resource;
        if (accum[type]) {
            accum[type][id] = resource;
        } else {
            accum[type] = {
                [id]: resource,
            };
        }

        return accum;
    }, {});
};

export function transformResponse<T = Record<string, unknown>, RequestMeta = DefaultRequestMeta>(
    json:
        | {
              data: Resource | Resource[];
              included?: Resource[];
              links?: Links;
              meta?: RequestMeta;
          }
        | undefined,
): SuccessResponse<T, RequestMeta> {
    if (!json) return { resources: [] };

    const { data, included = [], meta, links } = json;

    const groupedIncluded = groupIncludedByType(included);

    if (Array.isArray(data)) {
        return {
            resources: data.map((resource) => normalizeResource(resource, groupedIncluded)),
            meta,
            links,
        };
    }

    return { resources: [normalizeResource(data, groupedIncluded)] };
}
