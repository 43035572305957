import React from 'react';
import { Checkbox } from '@didww/ui-kit';
import { ColumnSelection } from '../../types';
import { Modal } from 'shared/ui/modal';
import styles from './styles.module.scss';

interface Props {
    selection: ColumnSelection[];
    changeSelection: (columnKey: string, visible: boolean) => void;
    onCancel: () => void;
}

export const ColumnsModal: React.FC<Props> = ({ selection, changeSelection, onCancel }) => (
    <Modal title="Columns" width={250} onCancel={onCancel}>
        <ul className={styles.list}>
            {selection.map(({ key, title, visible }) => (
                <li key={key} className={styles.item}>
                    <label className={styles.label}>
                        <Checkbox
                            checked={visible}
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            onChange={(e: any) => {
                                changeSelection(key, e.target.checked);
                            }}
                        />
                        <span className={styles.title}>{title}</span>
                    </label>
                </li>
            ))}
        </ul>
    </Modal>
);
