import { useState, useMemo, useCallback } from 'react';
import { TableRowSelection } from '@didww/ui-kit';
import { NamedEntity, SelectionState } from '../../types';

export const useSelectionState = (): SelectionState => {
    const [selected, setSelected] = useState<string[]>([]);

    const onSelectAction = useCallback((value: string | string[]): void => {
        if (Array.isArray(value)) {
            setSelected(value);
        } else {
            setSelected((ids) => {
                if (ids.includes(value)) {
                    return ids.filter((id) => id !== value);
                }

                return [...ids, value];
            });
        }
    }, []);

    const rowSelection = useMemo(
        (): TableRowSelection<NamedEntity> => ({
            selectedRowKeys: selected,
            onChange: (ids: React.ReactText[]): void => {
                onSelectAction(ids as string[]);
            },
            getCheckboxProps: (record) => ({
                name: record.id,
            }),
        }),
        [onSelectAction, selected],
    );

    return {
        selected,
        onSelectAction,
        rowSelection,
    };
};
