import React from 'react';
import dayjs from 'dayjs';
import { TerminationCdrsFilter, useGetTerminationCdrsQuery } from 'entities/termination-cdrs';
import { ResourcesTable } from 'entities/resources-table';
import { PageLoader } from 'shared/ui/spinners';
import { FormContext } from 'entities/form-context';
import { Pagination } from 'shared/ui/pagination';
import { ListLayout } from 'shared/ui/layouts';
import { routes } from 'shared/routes';
import { useListLoading, useListState } from 'shared/lib/hooks/list';
import { STORAGE_KEY } from '../config';
import { Filter } from './filter';
import { columns } from './columns';
import { getInitialFilter } from '../lib';

export const List: React.FC = () => {
    const {
        query,
        filter: { formik },
        pagination: { page, setPage },
    } = useListState<TerminationCdrsFilter>({
        storageKey: STORAGE_KEY,
        initialFilter: getInitialFilter(),
    });

    const { data, isLoading, isFetching } = useGetTerminationCdrsQuery({
        ...query,
        filter: {
            ...query.filter,
            time_start_gteq: dayjs(query.filter.time_start_gteq).format('YYYY-MM-DD HH:mm:ss'),
            time_start_lteq: dayjs(query.filter.time_start_lteq).format('YYYY-MM-DD HH:mm:ss'),
        },
    });

    const isListLoading = useListLoading(isLoading);

    if (isListLoading) {
        return <PageLoader />;
    }

    return (
        <ListLayout>
            <ListLayout.Header>
                <FormContext formik={formik}>
                    <Filter storageKey={STORAGE_KEY} />
                </FormContext>
            </ListLayout.Header>
            <ListLayout.Content>
                <ResourcesTable
                    storageKey={STORAGE_KEY}
                    showRoute={routes.terminationCdrs.show}
                    columns={columns}
                    dataSource={data?.resources}
                    loading={isFetching}
                />
            </ListLayout.Content>
            <ListLayout.Footer>
                <Pagination
                    onChangePage={setPage}
                    totalRecords={data?.meta?.record_count}
                    page={page}
                    pageSizeOptions={['10', '25', '50', '100', '200']}
                />
            </ListLayout.Footer>
        </ListLayout>
    );
};
