import React from 'react';
import { AvailableNumbersList } from 'features/available-numbers';
import { routes } from 'shared/routes';
import { Create } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const AvailableNumbersListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header
                title="Available Numbers"
                actions={[<Create key="create" route={routes.availableNumbers.create} />]}
            />
            <PageLayout.Content>
                <AvailableNumbersList />
            </PageLayout.Content>
        </PageLayout>
    );
};
