import { GetResultDescriptionFn } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_TYPES } from 'shared/api';
import { SuccessResponse, QueryError } from '../../types';

type Tag = typeof TAG_TYPES[number];

type TagsConverter<
    QueryArgs = unknown,
    Response = SuccessResponse<unknown, unknown>,
> = GetResultDescriptionFn<Tag, Response, QueryArgs, QueryError, Record<string, unknown> | undefined>;

type TagsProviders = {
    list: TagsConverter;
    show: TagsConverter<string>;
};

type TagsInvalidators = {
    create: [Tag];
    update: TagsConverter<{ id: string }>;
    remove: TagsConverter<string, undefined>;
};

export function getTagsProviders(resourceType: Tag): TagsProviders {
    const list: TagsConverter = (data) => {
        const listTag = { id: 'LIST', type: resourceType };

        const resourcesTags = data?.resources.map(({ id }) => ({ id, type: resourceType })) || [];

        return [...resourcesTags, listTag];
    };

    const show: TagsConverter<string> = (data, error, resourceId) => [
        { id: `SHOW_${resourceId}`, type: resourceType },
    ];

    return { list, show };
}

export function getTagsInvalidators(resourceType: Tag, extraInvalidatedTags?: Tag[]): TagsInvalidators {
    const extraTags = extraInvalidatedTags?.map((tag) => ({ type: tag })) || [];

    const update: TagsConverter<{ id: string }> = (data, error, { id: resourceId }) => [
        { id: `SHOW_${resourceId}`, type: resourceType },
        { id: resourceId, type: resourceType },
        ...extraTags,
    ];

    const remove: TagsConverter<string, undefined> = (data, error, resourceId) => [
        { id: resourceId, type: resourceType },
        { id: 'LIST', type: resourceType },
        ...extraTags,
    ];

    return { create: [resourceType], update, remove };
}
