import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput, SingleSelect } from 'shared/ui/fields';
import { RangeDatePickerField, useDefaults } from 'shared/ui/fields/range-date-picker-field';

const successOptions = [
    {
        value: '',
        label: 'Any',
    },
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
];

const optionalFilters = [
    {
        name: 'customer_id',
        label: 'Customer',
        node: (
            <ConditionalFilter.Item>
                <CustomersSelect labelText="Customer" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'src_number',
        label: 'Src number',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="src_number" labelText="Src number" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'dst_number',
        label: 'Dst number',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="dst_number" labelText="Dst number" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'success',
        label: 'Success',
        defaultValue: '',
        node: (
            <ConditionalFilter.Item size="small">
                <SingleSelect
                    isSearchable={false}
                    name="success"
                    options={successOptions}
                    labelText="Success"
                />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => {
    const datePickerDefaults = useDefaults();

    return (
        <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
            <ConditionalFilter.Item size="large">
                <RangeDatePickerField
                    labelText="Time start / end"
                    fromFieldName="time_start_gteq"
                    toFieldName="time_start_lteq"
                    withTime
                    {...datePickerDefaults}
                />
            </ConditionalFilter.Item>
        </ConditionalFilter>
    );
};
