import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    ExternalAccountRoute,
    ExternalAccountRoutesFilter,
    ExternalAccountRouteEditAttrs,
    ExternalAccountRouteCreateAttrs,
} from './types';

type ExternalAccountsQuery = {
    filter: ExternalAccountRoutesFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'external_account_routes';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME);

const availaleNumbersApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getExternalAccounts: builder.query<SuccessResponse<ExternalAccountRoute>, ExternalAccountsQuery>({
            query: ({ filter: { customer_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer,available_did_number',
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...filter,
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getExternalAccount: builder.query<SuccessResponse<ExternalAccountRoute>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer,available_did_number',
                fields: {
                    customers: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postExternalAccount: builder.mutation<
            SuccessResponse<ExternalAccountRoute>,
            ExternalAccountRouteCreateAttrs
        >({
            query: ({ customer_id, available_number_id, id }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    relationships: {
                        customer: {
                            data: {
                                id: customer_id,
                                type: 'customers',
                            },
                        },
                        available_did_number: {
                            data: {
                                id: available_number_id || null,
                                type: 'available_did_numbers',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchExternalAccount: builder.mutation<
            SuccessResponse<ExternalAccountRoute>,
            { id: string; values: ExternalAccountRouteEditAttrs }
        >({
            query: ({ id, values: { available_number_id } }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    relationships: {
                        available_did_number: {
                            data: {
                                id: available_number_id || null,
                                type: 'available_did_numbers',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteExternalAccount: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetExternalAccountsQuery,
    useGetExternalAccountQuery,
    useDeleteExternalAccountMutation,
    usePatchExternalAccountMutation,
    usePostExternalAccountMutation,
} = availaleNumbersApi;
