import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { CustomerCreateAttrs, usePostCustomerMutation } from 'entities/customers';
import { routes } from 'shared/routes';
import { FormLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

export const Form: React.FC = () => {
    const { push } = useHistory();

    const [postCustomer] = usePostCustomerMutation();

    const formik = useFormik<CustomerCreateAttrs>({
        initialValues: {
            id: '',
            name: '',
            language: 'EN',
            capacity_limit: '',
            sip_account_limit: '',
            capacity_group_id: '',
            trm_mode: 'default',
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await postCustomer(values)
                .unwrap()
                .then(({ resources }) => {
                    const [{ id }] = resources;

                    push(routes.customers.show.replace(':id', id));

                    message.success('Customer created');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <FormContext formik={formik}>
            <Fields />
            <FormLayout.Footer backPath={routes.customers.list} submitText="Create" />
        </FormContext>
    );
};
