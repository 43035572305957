import React from 'react';
import { SipDeviceRoute, AllowedIPCell } from 'entities/sip-accounts';
import { GridLayout } from 'shared/ui/layouts';
import { BooleanCell } from 'shared/ui/cells';

interface Props {
    sipDeviceRoute: SipDeviceRoute;
}

export const SipAccountInfo: React.FC<Props> = ({ sipDeviceRoute }) => (
    <GridLayout>
        <GridLayout.Row title="SIP-Account ID">{sipDeviceRoute.id}</GridLayout.Row>
        <GridLayout.Row title="Username">{sipDeviceRoute.username}</GridLayout.Row>
        <GridLayout.Row title="Password">{sipDeviceRoute.password}</GridLayout.Row>
        <GridLayout.Row title="Customer ID">{sipDeviceRoute.customer.id}</GridLayout.Row>
        <GridLayout.Row title="Customer">{sipDeviceRoute.customer.name}</GridLayout.Row>
        <GridLayout.Row title="Caller ID name">{sipDeviceRoute.caller_id_name}</GridLayout.Row>
        <GridLayout.Row title="Allow outbound calls">
            <BooleanCell value={sipDeviceRoute.allow_external_calls} />
        </GridLayout.Row>
        <GridLayout.Row title="Internal caller ID">{sipDeviceRoute.internal_caller_id}</GridLayout.Row>
        <GridLayout.Row title="External caller ID">{sipDeviceRoute.external_caller_id}</GridLayout.Row>
        <GridLayout.Row title="Is online">
            <BooleanCell value={sipDeviceRoute.is_online} />
        </GridLayout.Row>
        <GridLayout.Row title="User agent">{sipDeviceRoute.user_agent}</GridLayout.Row>
        <GridLayout.Row title="Allowed IPs">
            <AllowedIPCell sipDeviceRoute={sipDeviceRoute} />
        </GridLayout.Row>
        <GridLayout.Row title="Use allowed IPs">
            <BooleanCell value={sipDeviceRoute.use_allowed_ips} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec inbound internal">
            <BooleanCell value={sipDeviceRoute.incoming_internal} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec inbound external">
            <BooleanCell value={sipDeviceRoute.incoming_external} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec outbound internal">
            <BooleanCell value={sipDeviceRoute.outgoing_internal} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec outbound external">
            <BooleanCell value={sipDeviceRoute.outgoing_external} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec on demand">
            <BooleanCell value={sipDeviceRoute.on_demand} />
        </GridLayout.Row>
    </GridLayout>
);
