import React, { useCallback } from 'react';
import Switch, { ToggleProps } from 'react-toggle';
import cn from 'classnames';
import { InlineSvg } from 'shared/ui/inline-svg';
import './styles.scss';

interface Props extends Omit<ToggleProps, 'onChange'> {
    color?: 'base' | 'orange';
    loading?: boolean;
    onChange: (value: boolean) => void | Promise<void>;
}

export const Toggle: React.FC<Props> = ({
    onChange,
    loading,
    disabled,
    checked,
    color = 'base',
    ...componentProps
}) => {
    const handleChange = useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                await onChange(e.target.checked);
            }
        },
        [onChange],
    );

    const wrapperClassNames = cn('react-toggle__wrapper', `react-toggle__wrapper--color-${color}`, {
        'react-toggle__wrapper--checked': checked,
    });

    return (
        <div className={wrapperClassNames}>
            {loading && (
                <div className="react-toggle__spinner">
                    <InlineSvg type="loading" />
                </div>
            )}
            <Switch
                aria-checked={checked}
                onChange={handleChange}
                icons={false}
                disabled={disabled || loading}
                role="switch"
                checked={checked}
                {...componentProps}
            />
        </div>
    );
};
