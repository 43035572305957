import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    AvaiableDidNumber,
    AvaiableDidNumberEditAttrs,
    usePatchAvailableNumberMutation,
} from 'entities/available-did-numbers';
import { routes } from 'shared/routes';
import { FormLayout, PageLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

interface Props {
    availableNumber: AvaiableDidNumber;
}

export const Form: React.FC<Props> = ({ availableNumber }) => {
    const { id } = availableNumber;

    const showPath = routes.availableNumbers.show.replace(':id', id);

    const { push } = useHistory();

    const [pathAvailableNumber] = usePatchAvailableNumberMutation();

    const formik = useFormik<AvaiableDidNumberEditAttrs>({
        initialValues: {
            number: availableNumber.number,
            enabled: availableNumber.enabled,
            external_id: availableNumber.external_id,
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await pathAvailableNumber({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('Available Number updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <>
            <PageLayout.Header title={`Available Number ${availableNumber.number}`} />
            <PageLayout.Content>
                <FormContext formik={formik}>
                    <Fields />
                    <FormLayout.Footer backPath={showPath} submitText="Save" />
                </FormContext>
            </PageLayout.Content>
        </>
    );
};
