import { useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { SingleSelectValueProps } from '@didww/ui-kit';
import { SelectFieldProps } from './types';

export function useSingleSelectField(props: SelectFieldProps): SingleSelectValueProps {
    const { name, isAutoSubmit = false, touchOnChange = true } = props;
    const [{ value }] = useField(name);

    const { setFieldValue, setFieldError, setFieldTouched, submitForm } = useFormikContext();

    const onChange: SingleSelectValueProps['onChange'] = useCallback(
        (newValue): void => {
            if (touchOnChange) setFieldTouched(name, true);
            setFieldValue(name, newValue);
            if (touchOnChange) setFieldError(name, '');

            isAutoSubmit && submitForm();
        },
        [name, setFieldError, setFieldTouched, setFieldValue, isAutoSubmit, submitForm, touchOnChange],
    );

    return { onChange, value };
}
