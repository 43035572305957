import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { CapacityGroupManageCustomers } from 'features/capacity-groups';
import { PageLayout } from 'shared/ui/layouts';

export const CapacityGroupManageCustomersPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <CapacityGroupManageCustomers id={id} />
            <PageLayout.Footer backPath={routes.capacityGroups.show.replace(':id', id)} />
        </PageLayout>
    );
};
