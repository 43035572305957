import React from 'react';
import styles from './styles.module.scss';

interface RowProps {
    title: string;
}

const Row: React.FC<RowProps> = ({ title, children }) => {
    return (
        <div className={styles.row}>
            <span className={styles.title}>{`${title}:`}</span>
            <span className={styles.value}>{children}</span>
        </div>
    );
};

export const Grid: React.FC & { Row: typeof Row } = ({ children }) => (
    <div className={styles.grid}>{children}</div>
);

Grid.Row = Row;
