import { SUFFIX, getSavedFilters } from 'shared/lib/hooks/list/use-list-filter';
import { FilterOption, FieldConfig } from '../types';

export const getInitialFilters = (storageKey: string, filterOptions: FilterOption[]): FieldConfig[] => {
    const visibleFilters = getSavedFilters(storageKey);

    return filterOptions.map(({ defaultValue = '', ...field }) => ({
        ...field,
        visible: field.name in visibleFilters,
        defaultValue,
    }));
};

const getStringValue = (defaultValue: unknown | undefined): string => {
    if (defaultValue === undefined) return '';

    return String(defaultValue);
};

export const saveFiltersToStorage = (storageKey: string, fieldsConfig: FieldConfig[]): void => {
    const visibleFieldsString = fieldsConfig.reduce((accum, field) => {
        if (!field.visible) {
            return accum;
        }

        const defaulValue = getStringValue(field.defaultValue);

        const fieldData = `${field.name}=${defaulValue}`;

        return accum ? `${accum},${fieldData}` : fieldData;
    }, '');

    localStorage.setItem(`${storageKey}${SUFFIX}`, visibleFieldsString);
};
