import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteCapacityGroupMutation } from 'entities/capacity-groups';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const GroupDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteCapacityGroup] = useDeleteCapacityGroupMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected Capacity Group',
        onConfirm: async (): Promise<void> => {
            await deleteCapacityGroup(id)
                .unwrap()
                .then(() => {
                    replace(routes.capacityGroups.list);

                    message.success('Capacity Group deleted');
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
