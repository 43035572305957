import React from 'react';
import cn from 'classnames';
import { Spin } from '@didww/ui-kit';
import styles from './styles.module.scss';

interface Props {
    className?: string;
}

export const PageLoader: React.FC<Props> = ({ className }) => (
    <div className={cn(styles.spinner, className)} data-testid="spinner">
        <Spin spinning size="large" />
    </div>
);
