import React, { useCallback } from 'react';
import { useField } from 'formik';
import { Toggle as ToggleButton } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Toggle {
    name: string;
    labelText: string;
}

export const Toggle: React.FC<Toggle> = ({ name, labelText }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ value, onChange, ...fieldProps }, , { setValue }] = useField({ name });

    const handleChange = useCallback(
        (checked: boolean) => {
            setValue(checked);
        },
        [setValue],
    );

    return (
        <div className={styles.toggle}>
            <ToggleButton
                {...fieldProps}
                onChange={handleChange}
                checked={value}
                id={name}
                data-testid={name}
            />
            <label htmlFor={name} className={styles.label}>
                {labelText}
            </label>
        </div>
    );
};
