import { ResourceColumnsType } from 'entities/resources-table';
import { TerminationCdr } from 'entities/termination-cdrs';

export const columns: ResourceColumnsType<TerminationCdr> = [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Termination CDR ID',
    },
    {
        key: 'time_start',
        dataIndex: 'time_start',
        title: 'Time start',
    },
    {
        key: 'time_connect',
        dataIndex: 'time_connect',
        title: 'Time connect',
    },
    {
        key: 'time_end',
        dataIndex: 'time_end',
        title: 'Time end',
    },
    {
        key: 'local_tag',
        dataIndex: 'local_tag',
        title: 'Local tag',
    },
    {
        key: 'global_tag',
        dataIndex: 'global_tag',
        title: 'Global tag',
    },
    {
        key: 'duration',
        dataIndex: 'duration',
        title: 'Duration',
    },
    {
        key: 'aleg_code',
        dataIndex: 'aleg_code',
        title: 'legA disconnect code',
    },
    {
        key: 'aleg_reason',
        dataIndex: 'aleg_reason',
        title: 'legA disconnect reason',
    },
    {
        key: 'src_name_in',
        dataIndex: 'src_name_in',
        title: 'Src name in',
    },
    {
        key: 'src_number_in',
        dataIndex: 'src_number_in',
        title: 'Src number in',
    },
    {
        key: 'dst_number_in',
        dataIndex: 'dst_number_in',
        title: 'Dst number in',
    },
    {
        key: 'orig_call_id',
        dataIndex: 'orig_call_id',
        title: 'Orig call ID',
    },
];
