import React, { useMemo } from 'react';
import { VirtualSelect, VirtualSelectProps } from '@didww/ui-kit';
import { Button } from 'shared/ui/buttons';
import { AddOption } from '../types';

interface Props {
    options: AddOption[];
    onOptionClick: (fieldName: string) => void;
}

export const AddFilter: React.FC<Props> = ({ options, onOptionClick }) => {
    const selectOptions: VirtualSelectProps['options'] = useMemo(
        () =>
            options.map((option) => ({
                value: option.name,
                label: option.label,
            })),
        [options],
    );

    if (options.length === 0) {
        return null;
    }

    return (
        <VirtualSelect options={selectOptions} onChange={onOptionClick} maxMenuHeight={400}>
            <Button icon="filter" text="Add Filter" ghost />
        </VirtualSelect>
    );
};
