import React from 'react';
import { IncomingTrunkCdrsList } from 'features/incoming-trunk-cdrs';
import { PageLayout } from 'shared/ui/layouts';

export const IncomingTrunkCdrsListPage: React.FC = () => {
    return (
        <PageLayout>
            <PageLayout.Header title="Incoming Trunk CDRs" />
            <PageLayout.Content>
                <IncomingTrunkCdrsList />
            </PageLayout.Content>
        </PageLayout>
    );
};
