import React from 'react';
import { ExternalAccountRoute } from 'entities/external-account-routes';
import { GridLayout } from 'shared/ui/layouts';
import { BooleanCell } from 'shared/ui/cells';

interface Props {
    externalAccount: ExternalAccountRoute;
}

export const AccountInfo: React.FC<Props> = ({ externalAccount }) => (
    <GridLayout>
        <GridLayout.Row title="External Account ID">{externalAccount.id}</GridLayout.Row>
        <GridLayout.Row title="Customer ID">{externalAccount.customer.id}</GridLayout.Row>
        <GridLayout.Row title="Customer">{externalAccount.customer.name}</GridLayout.Row>
        <GridLayout.Row title="Caller ID name">{externalAccount.caller_id_name}</GridLayout.Row>
        <GridLayout.Row title="Allow outbound calls">
            <BooleanCell value={externalAccount.allow_external_calls} />
        </GridLayout.Row>
        <GridLayout.Row title="External caller ID">{externalAccount.external_caller_id}</GridLayout.Row>
        <GridLayout.Row title="Internal caller ID">{externalAccount.internal_caller_id}</GridLayout.Row>
        <GridLayout.Row title="Rec inbound internal">
            <BooleanCell value={externalAccount.incoming_internal} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec inbound external">
            <BooleanCell value={externalAccount.incoming_external} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec outbound internal">
            <BooleanCell value={externalAccount.outgoing_internal} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec outbound external">
            <BooleanCell value={externalAccount.outgoing_external} />
        </GridLayout.Row>
        <GridLayout.Row title="Rec on demand">
            <BooleanCell value={externalAccount.on_demand} />
        </GridLayout.Row>
    </GridLayout>
);
