import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { SingleSelect } from 'shared/ui/fields/select';

const options = [
    { value: 'IPv4_only', label: 'IPv4 Only' },
    { value: 'IPv6_only', label: 'IPv6 Only' },
    { value: 'Dualstack', label: 'Dual Stack' },
    { value: 'IPv4_pref', label: 'IPv4 Preferred' },
    { value: 'IPv6_pref', label: 'IPv6 Preferred' },
];

export const NetProtocolSelect: React.FC = () => {
    return (
        <SingleSelect
            name="network_protocol"
            labelText={<LabelInfo title="Network protocol" />}
            options={options}
        />
    );
};
