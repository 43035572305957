import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { SingleSelect } from 'shared/ui/fields/select';

const options = [
    { value: 'UDP', label: 'UDP' },
    { value: 'TCP', label: 'TCP' },
];

export const TransportSelect: React.FC = () => {
    return (
        <SingleSelect
            isSearchable={false}
            name="transport_protocol"
            labelText={<LabelInfo title="Transport" />}
            options={options}
        />
    );
};
