import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'app/hooks';
import { Spacer } from 'shared/ui/spacer';
import { LottieLogo } from 'shared/ui/lottie-logo';
import { ErrorLayout } from 'shared/ui/layouts';
import { authorizedRequest } from 'shared/api';
import { routes } from 'shared/routes';
import { useInterval } from 'shared/lib/hooks/use-interval';
import json from './animation.json';
import styles from './styles.module.scss';

export const ServerDeployPage: React.FC = () => {
    const { replace } = useHistory();
    const dispatch = useDispatch();

    const pingServer = useCallback(async () => {
        const response = await dispatch(
            authorizedRequest({
                url: 'customers',
                params: {
                    page: { size: 1, number: 1 },
                    fields: { customers: 'id' },
                },
            }),
        );

        if (response.data) {
            replace(routes.dashboard);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useInterval(pingServer, 30000);

    return (
        <ErrorLayout>
            <LottieLogo json={json} width="140px" height="140px" />
            <Spacer size={13} />
            <div className={styles.title}>We are updating right now.</div>
            <Spacer size={17} />
            <div className={styles.subTitle}>
                Please visit us again in a few minutes or wait for the page to reload.
            </div>
        </ErrorLayout>
    );
};
