import React from 'react';
import { CustomerCreate } from 'features/customers';
import { PageLayout } from 'shared/ui/layouts';

export const CustomerCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Customer" />
        <PageLayout.Content>
            <CustomerCreate />
        </PageLayout.Content>
    </PageLayout>
);
