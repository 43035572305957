import { SuccessResponse, apiSlice, getTagsProviders } from 'shared/api';
import { Login } from './types';

const RESOURCE_NAME = 'logins';

const tagsProvider = getTagsProviders(RESOURCE_NAME);

const loginApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getLogin: builder.query<SuccessResponse<Login>, string>({
            query: () => ({
                url: RESOURCE_NAME,
                method: 'GET',
            }),
            providesTags: tagsProvider.show,
        }),
    }),
});

export const { useGetLoginQuery } = loginApi;
