import React from 'react';
import { Back } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    backPath: string;
    actions?: React.ReactNode[];
}

export const Footer: React.FC<Props> = ({ backPath, actions }) => (
    <div className={styles.extraSpace}>
        <div className={styles.footer}>
            <div className={styles.back}>
                <Back to={backPath} />
            </div>
            {!!actions && (
                <div className={styles.actions}>
                    {actions.map((action, index) => (
                        <div className={styles.action} key={index}>
                            {action}
                        </div>
                    ))}
                </div>
            )}
        </div>
    </div>
);
