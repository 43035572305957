import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetSettingsQuery } from 'entities/settings';
import { Form } from './form';

export const LoadableForm: React.FC = () => {
    const { data, isLoading } = useGetSettingsQuery('');

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [settings] = data.resources;

    return <Form settings={settings} />;
};
