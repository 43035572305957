import { SidebarLink } from 'shared/ui/sidebar';
import { routes } from 'shared/routes';

export const sideMenuLinks: SidebarLink[] = [
    {
        link: routes.dashboard,
        text: 'Dashboard',
        icon: 'dashboard',
    },
    {
        link: routes.customers.list,
        text: 'Customers',
        icon: 'customers',
    },
    {
        link: routes.capacityGroups.list,
        text: 'Capacity Groups',
        icon: 'capacity-groups',
    },
    {
        link: routes.availableNumbers.list,
        text: 'Available Numbers',
        icon: 'available-numbers',
    },
    {
        link: routes.sipAccounts.list,
        text: 'SIP-Accounts',
        icon: 'sip-accounts',
    },
    {
        link: routes.externalAccounts.list,
        text: 'External Accounts',
        icon: 'external-accounts',
    },
    {
        link: routes.incomingTrunks.list,
        text: 'Incoming Trunks',
        icon: 'incoming-trunks',
    },
    {
        link: routes.incomingTrunkCdrs.list,
        text: 'Incoming Trunk CDRs',
        icon: 'incoming-trunk-cdrs',
    },
    {
        link: routes.terminationCdrs.list,
        text: 'Termination CDRs',
        icon: 'termination-cdrs',
    },
    {
        link: routes.terminationGateways.list,
        text: 'Termination Gateways',
        icon: 'termination-gateways',
    },
    {
        link: routes.terminationRoutes.list,
        text: 'Termination Routes',
        icon: 'termination-routes',
    },
];
