import React from 'react';
import { Button } from 'shared/ui/buttons';
import styles from './styles.module.scss';

interface Props {
    onClick: () => void;
}

export const Hamburger: React.FC<Props> = ({ onClick }) => (
    <div className={styles.hamburger}>
        <Button shape="circle" icon="hamburger" onClick={onClick} />
    </div>
);
