import React from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { ReactComponent as CloseModal } from 'shared/assets/icons/close-modal.svg';
import { ReactComponent as Remove } from 'shared/assets/icons/remove.svg';
import { ReactComponent as Launch } from 'shared/assets/icons/launch.svg';
import { ReactComponent as SelectArrow } from 'shared/assets/icons/select-arrow.svg';

// Toasts
import { ReactComponent as ToastError } from 'shared/assets/icons/toast/toast-error.svg';
import { ReactComponent as ToastWarning } from 'shared/assets/icons/toast/toast-warning.svg';
import { ReactComponent as ToastSuccess } from 'shared/assets/icons/toast/toast-success.svg';
import { ReactComponent as ToastInfo } from 'shared/assets/icons/toast/toast-info.svg';
import { ReactComponent as CloseToast } from 'shared/assets/icons/toast/close-toast.svg';

// Side Menu
import { ReactComponent as CloseMenu } from 'shared/assets/icons/side-menu/close-menu.svg';
import { ReactComponent as Dashboard } from 'shared/assets/icons/side-menu/dashboard.svg';
import { ReactComponent as CapacityGroups } from 'shared/assets/icons/side-menu/capacity-groups.svg';
import { ReactComponent as Customers } from 'shared/assets/icons/side-menu/customers.svg';
import { ReactComponent as AvailableNumbers } from 'shared/assets/icons/side-menu/available-numbers.svg';
import { ReactComponent as SipAccounts } from 'shared/assets/icons/side-menu/sip-accounts.svg';
import { ReactComponent as ExternalAccounts } from 'shared/assets/icons/side-menu/external-accounts.svg';
import { ReactComponent as IncomingTrunks } from 'shared/assets/icons/side-menu/incoming-trunks.svg';
import { ReactComponent as IncomingTrunkCdrs } from 'shared/assets/icons/side-menu/incoming-trunk-cdrs.svg';
import { ReactComponent as TerminationCdrs } from 'shared/assets/icons/side-menu/termination-cdrs.svg';
import { ReactComponent as TerminationGateways } from 'shared/assets/icons/side-menu/termination-gateways.svg';
import { ReactComponent as TerminationRoutes } from 'shared/assets/icons/side-menu/termination-routes.svg';
import { ReactComponent as Settings } from 'shared/assets/icons/side-menu/settings.svg';
import { ReactComponent as CheckBold } from 'shared/assets/icons/modal/check-bold.svg';
import { ReactComponent as CrossBold } from 'shared/assets/icons/modal/cross-bold.svg';

import {
    CheckOutlined,
    CloseOutlined,
    EyeOutlined,
    LogoutOutlined,
    LoginOutlined,
    MenuOutlined,
    EditOutlined,
    DeleteOutlined,
    LoadingOutlined,
    SaveOutlined,
    PlusOutlined,
    TableOutlined,
    FilterOutlined,
    CalendarOutlined,
    UsergroupAddOutlined,
    RollbackOutlined,
} from '@ant-design/icons';

type SizeShape = {
    fontSize?: number;
    width: number;
    maxWidth: number;
    minWidth: number;
    height: number;
    maxHeight: number;
    minHeight: number;
};

export type IconSize = number | { width: number; height: number };

function getSizeShape(size?: IconSize): SizeShape | Record<never, never> {
    if (!size) return {};

    if (typeof size === 'number')
        return {
            fontSize: size,
            width: size,
            maxWidth: size,
            minWidth: size,
            height: size,
            maxHeight: size,
            minHeight: size,
        };

    return {
        width: size.width,
        maxWidth: size.width,
        minWidth: size.width,
        height: size.height,
        maxHeight: size.height,
        minHeight: size.height,
    };
}

interface InlineSvgProps {
    type: string;
    className?: string;
    size?: IconSize;
}

const _InlineSvg: React.FC<InlineSvgProps> = ({ type, className, size }) => {
    let Component = null;

    switch (type) {
        case 'close-modal': {
            Component = CloseModal;
            break;
        }

        case 'close-menu': {
            Component = CloseMenu;
            break;
        }

        case 'remove': {
            Component = Remove;
            break;
        }

        case 'launch': {
            Component = Launch;
            break;
        }

        case 'select-arrow': {
            Component = SelectArrow;
            break;
        }

        //Toasts
        case 'toast-error': {
            Component = ToastError;
            break;
        }

        case 'toast-warning': {
            Component = ToastWarning;
            break;
        }

        case 'toast-success': {
            Component = ToastSuccess;
            break;
        }

        case 'toast-info': {
            Component = ToastInfo;
            break;
        }

        case 'close-toast': {
            Component = CloseToast;
            break;
        }

        // Ant icons
        case 'back': {
            Component = RollbackOutlined;
            break;
        }

        case 'loading': {
            Component = LoadingOutlined;
            break;
        }

        case 'checked': {
            Component = CheckOutlined;
            break;
        }

        case 'crossed': {
            Component = CloseOutlined;
            break;
        }

        case 'show': {
            Component = EyeOutlined;
            break;
        }

        case 'edit': {
            Component = EditOutlined;
            break;
        }

        case 'delete': {
            Component = DeleteOutlined;
            break;
        }

        case 'login': {
            Component = LoginOutlined;
            break;
        }

        case 'logout': {
            Component = LogoutOutlined;
            break;
        }

        case 'hamburger': {
            Component = MenuOutlined;
            break;
        }

        case 'save': {
            Component = SaveOutlined;
            break;
        }

        case 'plus': {
            Component = PlusOutlined;
            break;
        }

        case 'table': {
            Component = TableOutlined;
            break;
        }

        case 'filter': {
            Component = FilterOutlined;
            break;
        }

        case 'calendar': {
            Component = CalendarOutlined;
            break;
        }

        case 'manage-customers': {
            Component = UsergroupAddOutlined;
            break;
        }

        case 'dashboard': {
            Component = Dashboard;
            break;
        }

        case 'capacity-groups': {
            Component = CapacityGroups;
            break;
        }

        case 'customers': {
            Component = Customers;
            break;
        }

        case 'available-numbers': {
            Component = AvailableNumbers;
            break;
        }

        case 'sip-accounts': {
            Component = SipAccounts;
            break;
        }

        case 'external-accounts': {
            Component = ExternalAccounts;
            break;
        }

        case 'incoming-trunks': {
            Component = IncomingTrunks;
            break;
        }

        case 'incoming-trunk-cdrs': {
            Component = IncomingTrunkCdrs;
            break;
        }

        case 'termination-cdrs': {
            Component = TerminationCdrs;
            break;
        }

        case 'termination-gateways': {
            Component = TerminationGateways;
            break;
        }

        case 'termination-routes': {
            Component = TerminationRoutes;
            break;
        }

        case 'settings': {
            Component = Settings;
            break;
        }

        case 'check-bold': {
            Component = CheckBold;
            break;
        }

        case 'cross-bold': {
            Component = CrossBold;
            break;
        }

        default:
            Component = null;
            break;
    }

    return Component ? (
        <Component
            className={cn(styles.inline, className)}
            style={getSizeShape(size)}
            data-testid={`svg-icon-${type}`}
        />
    ) : null;
};

export const InlineSvg = React.memo(_InlineSvg);
