import { ValidationFunc } from './types';
import { ipRegex, cidrRegex } from './regexp';
import { SimpleOption } from '@didww/ui-kit/build/components/select/types';

export { passwordValidator } from 'shared/ui/password-tooltip';

export const required: ValidationFunc = (value) => {
    return value === undefined ||
        value === null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
        ? 'This value is required'
        : undefined;
};

export const requiredIfSelected =
    (fieldName: string): ValidationFunc =>
    (value, allValues) => {
        const relatedFieldValue = allValues[fieldName];

        return relatedFieldValue ? required(value, allValues) : undefined;
    };

export const id: ValidationFunc = (value) => {
    return value && /[^a-zA-Z0-9]/.test(value) ? 'ID is invalid' : undefined;
};

export const username: ValidationFunc = (value) =>
    value && value.match(/[:;@]/) ? 'Name is invalid' : undefined;

export const number: ValidationFunc = (value) =>
    value && /[^0-9]/.test(value) ? 'This value should be a valid number' : undefined;

export const integer: ValidationFunc = (value) =>
    value && !/^-?\d+$/.test(value) ? 'This value should be a valid integer' : undefined;

export const max =
    (max: number): ValidationFunc =>
    (value) =>
        value && value > max ? `Must be less than or equal to ${max}` : undefined;

export const maxLength =
    (max: number): ValidationFunc =>
    (value) =>
        value && value.length > max ? `Maximum length is ${max} characters` : undefined;

export const exactLength =
    (exact: number): ValidationFunc =>
    (value) =>
        value && value.length != exact ? `Length should be ${exact} characters` : undefined;

const validateIPv6 = (value: string): boolean => {
    const ipv6Mask =
        /^(?:(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){6})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:::(?:(?:(?:[0-9a-fA-F]{1,4})):){5})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){4})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,1}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){3})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,2}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:(?:[0-9a-fA-F]{1,4})):){2})(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,3}(?:(?:[0-9a-fA-F]{1,4})))?::(?:(?:[0-9a-fA-F]{1,4})):)(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,4}(?:(?:[0-9a-fA-F]{1,4})))?::)(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9]))\.){3}(?:(?:25[0-5]|(?:[1-9]|1[0-9]|2[0-4])?[0-9])))))))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,5}(?:(?:[0-9a-fA-F]{1,4})))?::)(?:(?:[0-9a-fA-F]{1,4})))|(?:(?:(?:(?:(?:(?:[0-9a-fA-F]{1,4})):){0,6}(?:(?:[0-9a-fA-F]{1,4})))?::))))$/;

    return ipv6Mask.test(value);
};

export const host: ValidationFunc = (host) => {
    if (host === undefined || host === '' || host === null) return undefined;
    if (!(typeof host === 'string')) return 'Host is invalid';
    if (validateIPv6(host)) return undefined;
    if (host.indexOf('.') === -1) return 'Host is invalid';
    if (
        /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))*$/.test(
            host,
        )
    ) {
        return undefined;
    }
    return 'Host is invalid';
};

export const biggerOrEqualZero: ValidationFunc = (value) => {
    return value !== null && value !== '' && value < 0
        ? 'This value should be greater than or equal 0'
        : undefined;
};

export const min =
    (min: number): ValidationFunc =>
    (value: number) =>
        value && value < min ? `Must be more than or equal to ${min}` : undefined;

export const between =
    (min: number, max: number): ValidationFunc =>
    (value: number) => {
        return value && (Number(value) < min || Number(value) > max)
            ? `This value should be between ${min} and ${max}`
            : undefined;
    };

export const passwordConfirmation: ValidationFunc = (value, allValues) => {
    if (value !== allValues.new_password) {
        return 'Passwords do not match';
    }

    return undefined;
};

export const isValidIP = (value: string, options: SimpleOption[]): boolean => {
    if (options.some((option) => option.value === value)) {
        return false;
    }

    return cidrRegex.test(value) || ipRegex.test(value);
};
