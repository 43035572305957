import React, { useEffect } from 'react';
import { JsonApiClient, HttpClient, ErrorCode } from '@didww/jsonapi-client';
import { signOut } from 'entities/auth';
import { routes } from 'shared/routes';
import { message } from 'shared/message';
import { throttle } from 'shared/lib/helpers';
import { useDispatch } from './hooks';
import { useHistory } from 'react-router';
import { useNetworkStatus } from 'shared/lib/hooks/events/use-network-status';
import { useUpdate } from 'shared/lib/hooks/use-update';

export const GlobalSetup: React.FC = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isOnline = useNetworkStatus();

    const isLoginLocation = (): boolean => {
        return [routes.signIn, routes.twoFactorAuth].includes(history.location.pathname);
    };

    useUpdate(() => {
        if (isOnline) {
            history.replace(routes.dashboard);
        } else {
            history.replace(routes.error.offline);
        }
    }, [isOnline]);

    useEffect(() => {
        HttpClient.onError(
            throttle(
                (error) => {
                    switch (error.code) {
                        case ErrorCode.NotFound: {
                            history.replace(routes.error.notFound);
                            break;
                        }

                        case ErrorCode.Forbidden: {
                            message.error('This action is not allowed on your account');
                            break;
                        }

                        case ErrorCode.InternalServer: {
                            history.replace(routes.error.server);
                            break;
                        }

                        case ErrorCode.NotAuthorized: {
                            dispatch(signOut({ rememberPathname: true }));
                            message.error('Session is expired. Please sign in to continue');
                            break;
                        }

                        case ErrorCode.Locked: {
                            if (isLoginLocation()) {
                                dispatch(signOut());
                                history.push(routes.signIn);
                                message.error(
                                    'Your account has been locked after too many login attempts. Please contact support to access your account.',
                                );
                            }
                            break;
                        }

                        case ErrorCode.BadGateway: {
                            history.push(routes.error.deploy);
                            break;
                        }

                        case ErrorCode.Unknown: {
                            history.replace(routes.error.offline);
                            break;
                        }
                    }
                },
                2000,
                false,
            ),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        JsonApiClient.onError((error) => {
            switch (error.code) {
                case ErrorCode.Validation: {
                    if (error.isPrimary) {
                        error.primaryErrors.forEach((error) => message.warning(error));
                    }
                    break;
                }
                case ErrorCode.Locked: {
                    if (error.fullHumanError && !isLoginLocation()) {
                        message.warning(error.fullHumanError);
                    }
                    break;
                }
                case ErrorCode.BadRequest: {
                    if (error.fullHumanError) {
                        message.warning(error.fullHumanError);
                    }
                    history.replace(routes.error.somethingWrong);
                    break;
                }
                default:
                    break;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>{children}</>;
};
