import React from 'react';
import { NewPasswordField } from './new-password-field';
import { Spacer } from 'shared/ui/spacer';
import { TextInput } from 'shared/ui/fields';

export const Fields: React.FC = () => {
    return (
        <>
            <Spacer size={10} />
            <TextInput
                labelText="Provide your password"
                name="password"
                type="password"
                autoComplete="password"
            />
            <Spacer size={13} />
            <NewPasswordField
                labelText="New password"
                name="new_password"
                type="password"
                autoComplete="new-password"
            />
            <Spacer size={13} />
            <TextInput
                labelText="Confirm new password"
                name="password_confirmation"
                type="password"
                autoComplete="new-password"
            />
        </>
    );
};
