import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { ExternalAccountRoute } from 'entities/external-account-routes';
import { BooleanCell } from 'shared/ui/cells';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<ExternalAccountRoute> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'External Account ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    { key: 'customer_id', title: 'Customer ID', render: (_, row) => row.customer.id },
    { key: 'customer_name', title: 'Customer', render: (_, row) => row.customer.name },
    {
        key: 'caller_id_name',
        dataIndex: 'caller_id_name',
        title: 'Caller ID name',
        sorter: true,
        sortOrder: sorter.column === 'caller_id_name' && sorter.order,
    },
    {
        key: 'allow_external_calls',
        dataIndex: 'allow_external_calls',
        title: 'Allow outbound calls',
        align: 'center',
        render: function AllowExternalColumn(_, row) {
            return <BooleanCell value={row.allow_external_calls} />;
        },
    },
    {
        key: 'external_caller_id',
        dataIndex: 'external_caller_id',
        title: 'External caller ID',
    },
    {
        key: 'internal_caller_id',
        dataIndex: 'internal_caller_id',
        title: 'Internal caller ID',
    },
    {
        key: 'incoming_internal',
        dataIndex: 'incoming_internal',
        title: 'Rec inbound internal',
        align: 'center',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'incoming_internal' && sorter.order,
        render: function IncomingInternalColumn(_, row) {
            return <BooleanCell value={row.incoming_internal} />;
        },
    },
    {
        key: 'incoming_external',
        dataIndex: 'incoming_external',
        title: 'Rec inbound external',
        align: 'center',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'incoming_external' && sorter.order,
        render: function IncomingExternalColumn(_, row) {
            return <BooleanCell value={row.incoming_external} />;
        },
    },
    {
        key: 'outgoing_internal',
        dataIndex: 'outgoing_internal',
        title: 'Rec outbound internal',
        align: 'center',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'outgoing_internal' && sorter.order,
        render: function OutgoingInternalColumn(_, row) {
            return <BooleanCell value={row.outgoing_internal} />;
        },
    },
    {
        key: 'outgoing_external',
        dataIndex: 'outgoing_external',
        title: 'Rec outbound external',
        align: 'center',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'outgoing_external' && sorter.order,
        render: function OutboundExternalColumn(_, row) {
            return <BooleanCell value={row.outgoing_external} />;
        },
    },
    {
        key: 'on_demand',
        dataIndex: 'on_demand',
        title: 'Rec on demand',
        align: 'center',
        defaultHidden: true,
        sorter: true,
        sortOrder: sorter.column === 'on_demand' && sorter.order,
        render: function OnDemandColumn(_, row) {
            return <BooleanCell value={row.on_demand} />;
        },
    },
];
