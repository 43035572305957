import React from 'react';
import { Field } from './field';
import { Footer } from './footer';
import styles from './styles.module.scss';

export const Form: React.FC & { Field: typeof Field; Footer: typeof Footer } = ({ children }) => {
    return <div className={styles.form}>{children}</div>;
};

Form.Field = Field;
Form.Footer = Footer;
