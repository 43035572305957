import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetTerminationCdrQuery } from 'entities/termination-cdrs';
import { CdrInfo } from './cdr-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetTerminationCdrQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [terminationCdr] = data.resources;

    return <CdrInfo terminationCdr={terminationCdr} />;
};
