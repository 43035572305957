/* eslint-disable @typescript-eslint/no-explicit-any */
export const throttle = <F extends (...params: any[]) => void>(fn: F, wait: number, trailing = true): F => {
    if (trailing) {
        let inThrottle: boolean;
        let lastFn: any;
        let lastTime: number;

        return function (this: any, ...args: any) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const context = this;

            if (!inThrottle) {
                fn.apply(context, args);
                lastTime = Date.now();
                inThrottle = true;
            } else {
                clearTimeout(lastFn);
                lastFn = setTimeout(function () {
                    if (Date.now() - lastTime >= wait) {
                        fn.apply(context, args);
                        lastTime = Date.now();
                    }
                }, Math.max(wait - (Date.now() - lastTime), 0));
            }
        } as F;
    } else {
        let isWaiting = false;
        return function (this: any, ...args: any) {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const context = this;

            if (!isWaiting) {
                // If we're not waiting
                fn.apply(context, args); // Execute users function
                isWaiting = true; // Prevent future invocations
                setTimeout(function () {
                    // After a period of time
                    isWaiting = false; // And allow future invocations
                }, wait);
            }
        } as F;
    }
};
