import React, { useMemo } from 'react';
import cn from 'classnames';
import { Button as UiButton } from '@didww/ui-kit';
import { InlineSvg } from 'shared/ui/inline-svg';
import { ButtonProps } from './types';
import { Link } from './link';
import styles from './styles.module.scss';

export * from './types';

export const Button: React.FC<ButtonProps> = ({ suffix, icon, className, ...props }) => {
    const iconNode = useMemo(() => {
        if (icon) {
            const iconType = typeof icon === 'string' ? icon : icon.type;
            const iconSize = typeof icon === 'string' ? undefined : icon.size;

            return <InlineSvg type={iconType} size={iconSize} />;
        }
    }, [icon]);

    const suffixNode = useMemo(() => {
        if (suffix) {
            return <InlineSvg type={suffix} />;
        }
    }, [suffix]);

    const classNames = cn(className, { [styles.disabled]: props.disabled || props.isPushing });

    return (
        <UiButton
            {...props}
            className={classNames}
            icon={iconNode}
            suffix={suffixNode}
            linkComponent={Link}
        />
    );
};
