import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { ToastBody } from 'shared/ui/toast';

export const message = {
    success(content: React.ReactChild, options?: ToastOptions): void {
        toast.success(<ToastBody type="success">{content}</ToastBody>, options);
    },

    error(content: React.ReactChild, options?: ToastOptions): void {
        toast.error(<ToastBody type="error">{content}</ToastBody>, options);
    },

    warning(content: React.ReactChild, options?: ToastOptions): void {
        toast.warning(<ToastBody type="warning">{content}</ToastBody>, options);
    },

    info(content: React.ReactChild, options?: ToastOptions): void {
        toast.info(<ToastBody type="info">{content}</ToastBody>, options);
    },
};
