import React from 'react';
import { Pagination } from '@didww/ui-kit';

interface Props {
    pageSizeOptions?: string[];
    onChange: (page: number, size: number | undefined) => void;
    pageSize: number;
    total?: number;
    current: number;
}
export const UiPagination: React.FC<Props> = ({ onChange, current, total, pageSize, pageSizeOptions }) => {
    return (
        <Pagination
            pageSizeOptions={pageSizeOptions}
            onChange={onChange}
            current={current}
            showQuickJumper
            showSizeChanger
            total={total}
            pageSize={pageSize}
        />
    );
};
