import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteTerminationGatewayMutation } from 'entities/termination-gateways';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { QueryError, ErrorCode } from 'shared/api';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const TerminationGatewayDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteTerminationGateway] = useDeleteTerminationGatewayMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected Termination Gateway',
        onConfirm: async (): Promise<void> => {
            await deleteTerminationGateway(id)
                .unwrap()
                .then(() => {
                    replace(routes.terminationGateways.list);

                    message.success('Termination Gateway deleted');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.BadRequest) {
                        message.warning(
                            'The Termination Gateway was created by a Customer and therefore cannot be edited',
                        );
                    }
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
