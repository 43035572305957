import React, { useState } from 'react';
import { FormContext } from 'entities/form-context';
import { Fields } from './fields';
import { useFormik } from 'formik';
import { ChangePasswordModal } from 'features/settings/change-password';
import { FormLayout } from 'shared/ui/layouts';
import { Settings, SettingsAttrs, usePatchSettingMutation } from 'entities/settings';
import { message } from 'shared/message';
import { ErrorCode, QueryError } from 'shared/api';
import { useHistory } from 'react-router-dom';
import { routes } from 'shared/routes';

interface Props {
    settings: Settings;
}

export const Form: React.FC<Props> = ({ settings }) => {
    const [isModalVisible, setModalVisible] = useState(false);

    const openChangePasswordModal = () => {
        setModalVisible(true);
    };

    const closeChangePasswordModal = () => {
        setModalVisible(false);
    };

    const [pathSettings] = usePatchSettingMutation();

    const { push } = useHistory();

    const formik = useFormik<SettingsAttrs>({
        initialValues: {
            acl_ips: settings.acl_ips,
        },
        onSubmit: async (values, { setErrors }) => {
            await pathSettings(values)
                .unwrap()
                .then(() => {
                    push(routes.settings);

                    message.success('Settings updated successfully');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <>
            <FormContext formik={formik}>
                <Fields openChangePasswordModal={openChangePasswordModal} isDisabled={formik.isSubmitting} />
                <FormLayout.Footer submitText="Save" />
            </FormContext>
            {isModalVisible && <ChangePasswordModal closeChangePasswordModal={closeChangePasswordModal} />}
        </>
    );
};
