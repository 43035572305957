import { Entity } from 'shared/types';
import { ApiThunk, ErrorCode, authorizedRequest } from 'shared/api';
import { message } from 'shared/message';

type CustomerAdminSession = Entity<{
    uri: string;
}>;

export const postCustomerAdminSession =
    (id: string): ApiThunk<CustomerAdminSession> =>
    async (dispatch) => {
        const response = await dispatch(
            authorizedRequest<CustomerAdminSession>({
                method: 'POST',
                url: 'customer_admin_sessions',
                data: {
                    type: 'customer_admin_sessions',
                    relationships: {
                        customer: {
                            data: {
                                id,
                                type: 'customers',
                            },
                        },
                    },
                },
                skipPrimary: true,
            }),
        );

        if (response.error?.code === ErrorCode.BadRequest) {
            message.warning(response.error.fullHumanError);
        }

        return response;
    };
