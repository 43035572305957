import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { Submit } from 'shared/ui/buttons/submit';
import { TextInput } from 'shared/ui/fields/text-input';
import styles from './styles.module.scss';

export const Fields: React.FC = () => (
    <div className={styles.form}>
        <div className={styles.field}>
            <TextInput name="username" labelText={<LabelInfo title="Username" />} />
        </div>
        <div className={styles.field}>
            <TextInput name="password" type="password" labelText={<LabelInfo title="Password" />} />
        </div>
        <div>
            <Submit icon="login" text="Sign In" />
        </div>
    </div>
);
