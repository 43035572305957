import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ConfirmModalManagerInstance, ConfirmModalConfig } from 'shared/lib/services';
import { ConfirmModal } from './confirm-modal';
import { useClick } from 'shared/lib/hooks/actions';
import { useLockBodyScroll } from 'shared/lib/hooks/use-lock-body-scroll';

export const Manager: React.FC = () => {
    const [currentConfig, setCurrentConfig] = useState<ConfirmModalConfig | null>(null);

    useEffect(() => {
        ConfirmModalManagerInstance.subscribe(setCurrentConfig);

        return (): void => {
            ConfirmModalManagerInstance.unsubscribe();
        };
    }, []);

    const [handleConfirm, isPushing] = useClick(async () => {
        if (!currentConfig) return;

        try {
            await currentConfig?.onConfirm();
        } finally {
            ConfirmModalManagerInstance.closeModal();
        }
    });

    // to remove scrolling of body when modal is open
    useLockBodyScroll(!!currentConfig);

    if (!currentConfig) return null;

    const modal = (
        <ConfirmModal
            {...currentConfig}
            isPushing={isPushing}
            onCancel={() => ConfirmModalManagerInstance.closeModal()}
            onConfirm={handleConfirm}
        />
    );

    return ReactDOM.createPortal(modal, document.body);
};
