import React from 'react';
import { MultiSelect as BaseMultiSelect, MultiSelectProps } from '@didww/ui-kit';
import { useFieldError } from 'shared/lib/hooks/forms';
import { MultiSelectFieldProps } from './types';
import { useMultiSelectField } from './hooks';

type Props = Omit<MultiSelectProps, 'errorMessage' | 'dataTestId' | 'value' | 'onChange'> &
    MultiSelectFieldProps;

export const MultiSelect: React.FC<Props> = ({ name, isAutoSubmit, options, ...selectProps }) => {
    const errorMessage = useFieldError(name);
    const { value, onChange } = useMultiSelectField({ name, isAutoSubmit });

    return (
        <BaseMultiSelect
            {...selectProps}
            name={name}
            options={options}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            dataTestId={`field-${name}`}
        />
    );
};
