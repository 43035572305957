import React from 'react';
import { TerminationRoute } from 'entities/termination-routes';
import { GridLayout, PageLayout } from 'shared/ui/layouts';
import { BooleanCell } from 'shared/ui/cells';

interface Props {
    terminationRoute: TerminationRoute;
}

export const RouteInfo: React.FC<Props> = ({ terminationRoute }) => (
    <>
        <PageLayout.Header title={`Termination Route ${terminationRoute.name}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Termination Route ID">{terminationRoute.id}</GridLayout.Row>
                <GridLayout.Row title="Name">{terminationRoute.name}</GridLayout.Row>
                <GridLayout.Row title="Gateway">{terminationRoute.gateway.name}</GridLayout.Row>
                <GridLayout.Row title="Customer">{terminationRoute.customer?.name || ''}</GridLayout.Row>
                <GridLayout.Row title="Operator">
                    <BooleanCell value={terminationRoute.operator} />
                </GridLayout.Row>
                <GridLayout.Row title="SRC prefix">{terminationRoute.src_prefix}</GridLayout.Row>
                <GridLayout.Row title="DST prefix">{terminationRoute.dst_prefix}</GridLayout.Row>
                <GridLayout.Row title="SRC rewrite rule">{terminationRoute.src_rewrite_rule}</GridLayout.Row>
                <GridLayout.Row title="SRC rewrite result">
                    {terminationRoute.src_rewrite_result}
                </GridLayout.Row>
                <GridLayout.Row title="DST rewrite rule">{terminationRoute.dst_rewrite_rule}</GridLayout.Row>
                <GridLayout.Row title="DST rewrite result">
                    {terminationRoute.dst_rewrite_result}
                </GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
    </>
);
