import dayjs, { Dayjs } from 'dayjs';
import isSameOrBefor from 'dayjs/plugin/isSameOrBefore';
import { Line, ActiveCallsView } from '../types';
import { CHART_DATE_KEY } from '../config';

dayjs.extend(isSameOrBefor);

type PopulatedLine = {
    count: number;
    snapshot_timestamp: string;
};

type PopulatedLines = Record<string, PopulatedLine> | PopulatedLine[];

export type FormattedLine = {
    snapshot_timestamp: string | number;
    [CHART_DATE_KEY]: number;
};

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

function getCorrectTimestamp(timestamp: string | Dayjs, view: ActiveCallsView): string {
    if (view === 'day') return dayjs(timestamp, DATE_FORMAT).format('YYYY-MM-DD 00:00:00');
    if (view === 'hour') return dayjs(timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:00:00');
    if (view === 'hour_of_day') return dayjs(timestamp, DATE_FORMAT).format('H');

    throw new Error('Incorrect view value is provided');
}

function getBiggerTimestamp(currentTimestamp: PopulatedLine, nextTimestamp: PopulatedLine): PopulatedLine {
    if (!currentTimestamp) return nextTimestamp;

    return currentTimestamp.count > nextTimestamp.count ? currentTimestamp : nextTimestamp;
}

function groupDataBy(lines: Line[], by: ActiveCallsView): Record<string, PopulatedLine> {
    const hash: Record<string, PopulatedLine> = Object.create(null);

    lines.forEach(({ count, snapshot_timestamp }) => {
        const timestamp =
            snapshot_timestamp.indexOf('UTC') !== -1 ? snapshot_timestamp.slice(0, -3) : snapshot_timestamp;

        const correctTimestamp = getCorrectTimestamp(timestamp, by);

        const lineTimestamp = {
            count,
            snapshot_timestamp: correctTimestamp,
        };

        hash[correctTimestamp] = getBiggerTimestamp(hash[correctTimestamp], lineTimestamp);
    });

    return hash;
}

function getPopulatedLines(from: string, to: string, view: ActiveCallsView): PopulatedLines {
    if (view === 'hour_of_day') {
        const populatedHours = [];

        for (let i = 0; i <= 24; i += 1) {
            populatedHours[i] = {
                count: 0,
                snapshot_timestamp: String(i),
            };
        }

        return populatedHours;
    }

    const dateHash = Object.create(null) as Record<string, PopulatedLine>;

    let currentDate = dayjs(from, DATE_FORMAT);
    const stopDate = dayjs(to, DATE_FORMAT);

    while (currentDate.isSameOrBefore(stopDate)) {
        const date = getCorrectTimestamp(currentDate, view);

        dateHash[date] = {
            count: 0,
            snapshot_timestamp: date,
        };

        currentDate = dayjs(currentDate, DATE_FORMAT).add(1, view);
    }

    return dateHash;
}

interface LinesConfig {
    lines: Line[];
    fromDatetime: string;
    toDatetime: string;
    view: ActiveCallsView;
}

export function getFormatedLines({ lines, fromDatetime, toDatetime, view }: LinesConfig): FormattedLine[] {
    if (!Array.isArray(lines) || lines.length === 0) return [];

    const populatedDates = getPopulatedLines(fromDatetime, toDatetime, view);
    const groupedDataByTimestamp = groupDataBy(lines, view);

    const allLines = Object.values({ ...populatedDates, ...groupedDataByTimestamp });

    const sortedData = allLines
        .sort((a, b) => {
            if (view === 'hour_of_day') return Number(b.snapshot_timestamp) - Number(a.snapshot_timestamp);

            return b.snapshot_timestamp.localeCompare(a.snapshot_timestamp);
        })
        .reverse();

    const dataWithTransformedDataField = sortedData.map((item) => ({
        snapshot_timestamp:
            view === 'hour_of_day' ? Number(item.snapshot_timestamp) : item.snapshot_timestamp,
        [CHART_DATE_KEY]: item.count,
    }));

    return dataWithTransformedDataField;
}
