import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { TextInput } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

export const Fields: React.FC = () => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="capacity" labelText={<LabelInfo title="Capacity" />} />
        </FormLayout.Field>
    </FormLayout>
);
