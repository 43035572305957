import React from 'react';
import { useSelector } from 'app/hooks';
import { ExternalAccountsList } from 'features/external-accounts';
import { routes } from 'shared/routes';
import { Create } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const ExternalAccountsListPage: React.FC = () => {
    const isCreateAllowed = useSelector((state) => state.auth.external_account_create_allowed);

    return (
        <PageLayout>
            <PageLayout.Header
                title="External Accounts"
                actions={
                    isCreateAllowed
                        ? [<Create key="create" route={routes.externalAccounts.create} />]
                        : undefined
                }
            />
            <PageLayout.Content>
                <ExternalAccountsList />
            </PageLayout.Content>
        </PageLayout>
    );
};
