import { SuccessResponse, apiSlice } from 'shared/api';
import { Entity } from 'shared/types/resources';
import { TwoFactorAuthVerifyAttrs } from './types';

const VERIFICATION_RESOURCE_NAME = 'verifications';
const DELIVERY_RESOURCE_NAME = 'deliveries';
const NAMESPACE_NAME = 'two_factor';

const twoFactorAuthApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        postTwoFactorVerification: builder.mutation<SuccessResponse<Entity>, TwoFactorAuthVerifyAttrs>({
            query: ({ otp_code }) => ({
                url: `${NAMESPACE_NAME}/${VERIFICATION_RESOURCE_NAME}`,
                method: 'POST',
                data: {
                    type: VERIFICATION_RESOURCE_NAME,
                    attributes: {
                        otp_code,
                    },
                },
            }),
        }),
        postTwoFactorDeliveries: builder.mutation<SuccessResponse<Entity>, string>({
            query: () => ({
                url: `${NAMESPACE_NAME}/${DELIVERY_RESOURCE_NAME}`,
                method: 'POST',
                data: {
                    type: DELIVERY_RESOURCE_NAME,
                },
            }),
        }),
    }),
});

export const { usePostTwoFactorVerificationMutation, usePostTwoFactorDeliveriesMutation } = twoFactorAuthApi;
