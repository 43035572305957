import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { IncomingTrunkDelete, IncomingTrunkShow } from 'features/incoming-trunks';
import { Edit } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const IncomingTrunksShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <IncomingTrunkShow id={id} />
            <PageLayout.Footer
                backPath={routes.incomingTrunks.list}
                actions={[
                    <Edit key="delete" id={id} route={routes.incomingTrunks.edit} />,
                    <IncomingTrunkDelete key="delete" id={id} />,
                ]}
            />
        </PageLayout>
    );
};
