import React, { useMemo } from 'react';
import { BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Line, ActiveCallsView } from '../../types';
import { CHART_DATE_KEY } from '../../config';
import { CustomLegend } from '../legend';
import { getFormatedLines, getTickFormatter } from '../../lib';
import styles from './styles.module.scss';

interface Props {
    lines: Line[];
    fromDatetime: string;
    toDatetime: string;
    totalCapacity: number | null;
    view: ActiveCallsView;
}

export const Chart: React.FC<Props> = ({ lines, fromDatetime, toDatetime, totalCapacity, view }) => {
    const formattedLines = useMemo(
        () => getFormatedLines({ lines, fromDatetime, toDatetime, view }),
        [lines, fromDatetime, toDatetime, view],
    );

    return (
        <ResponsiveContainer height={500} width="95%" className={styles.container}>
            <BarChart data={formattedLines}>
                <CartesianGrid vertical={false} stroke="#f3f2f3" />

                <XAxis
                    tickLine={false}
                    axisLine={false}
                    tickCount={0}
                    dataKey="snapshot_timestamp"
                    minTickGap={20}
                    tickFormatter={getTickFormatter(view)}
                />

                <YAxis tickLine={false} axisLine={false} allowDecimals={false} domain={[0, 'auto']} />

                <Tooltip />

                <Legend
                    iconType="circle"
                    align="left"
                    wrapperStyle={{ left: 50, paddingTop: 25, paddingRight: 35, paddingBottom: 15 }}
                    content={<CustomLegend total={totalCapacity} />}
                />

                <Legend align="right" wrapperStyle={{ right: 50, paddingTop: 25, paddingBottom: 15 }} />

                <Bar key={CHART_DATE_KEY} stackId="a" dataKey={CHART_DATE_KEY} fill="rgba(0, 148, 254, 1)" />
            </BarChart>
        </ResponsiveContainer>
    );
};
