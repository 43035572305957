import React from 'react';
import { MenuItem, MenuItemProps } from '@didww/ui-kit';
import { InlineSvg } from 'shared/ui/inline-svg';
import styles from './styles.module.scss';

interface Props extends MenuItemProps {
    icon?: string;
}

export const Item: React.FC<Props> = ({ icon, children, ...props }) => {
    const iconNode = icon ? (
        <div className={styles.icon}>
            <InlineSvg type={icon} size={16} />
        </div>
    ) : undefined;

    return (
        <MenuItem {...props} icon={iconNode}>
            {children}
        </MenuItem>
    );
};
