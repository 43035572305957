import { HOST } from 'shared/config';

const API_BASE_PATH = '/api/rest/public/operator';
const MEDIA_TYPE = 'application/vnd.api+json';

export const BASE_URL = `${HOST}${API_BASE_PATH}/`;

export const DEFAULT_HEADERS = {
    'Content-Type': MEDIA_TYPE,
};
