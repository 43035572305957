import { Entity, DefaultRequestMeta } from 'shared/types/resources';
import { QueryParams, RequestOptions, ApiThunk, QueryThunk } from './types';
import { apiRequest } from './lib';

export const authorizedRequest =
    <T extends Entity, Meta = DefaultRequestMeta>(options: RequestOptions): ApiThunk<T, Meta> =>
    (dispatch) => {
        return apiRequest<T, Meta>({
            ...options,
            headers: { ...options.headers },
        });
    };

export const loadByQuery =
    <T extends Entity>(url: string, query: QueryParams): QueryThunk<T> =>
    async (dispatch) => {
        const response = await dispatch(
            authorizedRequest<T>({
                method: 'GET',
                url,
                params: query,
            }),
        );

        if (response.data) {
            const { resources, links, meta } = response.data;

            return {
                data: resources,
                links: links,
                meta: meta || { record_count: 0 },
            };
        } else {
            return {
                data: [],
                meta: { record_count: 0 },
            };
        }
    };
