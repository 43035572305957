import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    TerminationRoute,
    TerminationRouteEditAttrs,
    usePatchTerminationRouteMutation,
} from 'entities/termination-routes';
import { routes } from 'shared/routes';
import { FormLayout, PageLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

interface Props {
    terminationRoute: TerminationRoute;
}

export const Form: React.FC<Props> = ({ terminationRoute }) => {
    const { id } = terminationRoute;

    const showPath = routes.terminationRoutes.show.replace(':id', id);

    const { push } = useHistory();

    const [patchTerminationRoute] = usePatchTerminationRouteMutation();

    const formik = useFormik<TerminationRouteEditAttrs>({
        initialValues: {
            name: terminationRoute.name,
            gateway_id: terminationRoute.gateway.id,
            src_prefix: terminationRoute.src_prefix,
            dst_prefix: terminationRoute.dst_prefix,
            src_rewrite_rule: terminationRoute.src_rewrite_rule,
            src_rewrite_result: terminationRoute.src_rewrite_result,
            dst_rewrite_rule: terminationRoute.dst_rewrite_rule,
            dst_rewrite_result: terminationRoute.dst_rewrite_result,
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await patchTerminationRoute({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('Termination Route updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <>
            <PageLayout.Header title={`Termination Route ${terminationRoute.name}`} />
            <PageLayout.Content>
                <FormContext formik={formik}>
                    <Fields
                        customerId={terminationRoute.customer?.id}
                        initialGateway={terminationRoute.gateway}
                    />
                    <FormLayout.Footer backPath={showPath} submitText="Save" />
                </FormContext>
            </PageLayout.Content>
        </>
    );
};
