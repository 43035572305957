import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { MultiSelect } from 'shared/ui/fields/select';

const options = [
    { value: 'OPUS', label: 'OPUS' },
    { value: 'PCMU', label: 'PCMU' },
    { value: 'PCMA', label: 'PCMA' },
    { value: 'telephone-event', label: 'telephone-event' },
    { value: 'g722', label: 'g722' },
    { value: 'g729', label: 'g729' },
    { value: 'GSM', label: 'GSM' },
    { value: 'telephone-event/48000', label: 'telephone-event/48000' },
    { value: 'telephone-event/32000', label: 'telephone-event/32000' },
    { value: 'telephone-event/16000', label: 'telephone-event/16000' },
];

export const CodecsSelect: React.FC = () => {
    return (
        <MultiSelect
            name="codecs"
            labelText={<LabelInfo title="Codecs" />}
            sortable
            options={options}
            valueOnClear={[]}
        />
    );
};
