import React from 'react';
import { ActionButton } from '../action-button';

interface Props {
    onClick: () => void;
}

export const Delete: React.FC<Props> = ({ onClick }) => (
    <ActionButton icon="delete" text="Delete" color="red" onClick={onClick} />
);
