import { ChangeEventInfo, SorterResult } from '@didww/ui-kit';
import { TableSorter } from 'shared/types/common';

function getSorter<T>(sorter: ChangeEventInfo<T>['sorter']): SorterResult<T> {
    if (Array.isArray(sorter)) {
        return sorter[0];
    } else {
        return sorter;
    }
}

export function getSortedInfo<T>(sorter: ChangeEventInfo<T>['sorter']): TableSorter {
    const { order, field } = getSorter<T>(sorter);

    const direction = order === 'descend' ? '-' : '';

    return {
        sort: order && field ? `${direction}${field}` : undefined,
        column: String(field) || '',
        order: order || null,
    };
}

export function getInitialSorter(sort: string): TableSorter {
    const order = sort.startsWith('-') ? 'descend' : 'ascend';

    const column = sort.replace('-', '');

    return {
        sort,
        column,
        order,
    };
}
