import React from 'react';
import { TwoFactorAuth } from 'features/two-factor-auth';
import styles from './styles.module.scss';

export const Page: React.FC = () => {
    return (
        <div className={styles.page}>
            <TwoFactorAuth />
        </div>
    );
};
