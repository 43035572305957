import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { ENVIRONMENT } from 'shared/config';
import App from './app';
import * as serviceWorker from './serviceWorker';

Sentry.init({
    environment: ENVIRONMENT,
    dsn:
        process.env.NODE_ENV === 'production'
            ? 'https://38811e327f5b46beb0d1747fbc4f11a0@sentry2.in.didww.com/14'
            : '',
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
    ],
    autoSessionTracking: false,
    tracesSampleRate: 0,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
