import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    IncomingTrunkEditAttrs,
    IncomingTrunk,
    usePatchIncomingTrunkMutation,
} from 'entities/incoming-trunks';
import { routes } from 'shared/routes';
import { FormLayout, PageLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';

interface Props {
    incomingTrunk: IncomingTrunk;
}

export const Form: React.FC<Props> = ({ incomingTrunk }) => {
    const { id } = incomingTrunk;

    const showPath = routes.incomingTrunks.show.replace(':id', id);

    const { push } = useHistory();

    const [patchIncomingTrunk] = usePatchIncomingTrunkMutation();

    const formik = useFormik<IncomingTrunkEditAttrs>({
        initialValues: {
            name: incomingTrunk.name,
            transport_protocol: incomingTrunk.transport_protocol,
            codecs: incomingTrunk.codecs,
            destination_field: incomingTrunk.destination_field,
        },
        onSubmit: async (values, { setErrors }) => {
            await patchIncomingTrunk({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('Incoming Trunk updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <>
            <PageLayout.Header title={`Incoming Trunk ${incomingTrunk.name}`} />
            <PageLayout.Content>
                <FormContext formik={formik}>
                    <Fields />
                    <FormLayout.Footer backPath={showPath} submitText="Save" />
                </FormContext>
            </PageLayout.Content>
        </>
    );
};
