import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetSipDeviceRouteQuery } from 'entities/sip-accounts';
import { SipAccountInfo } from './sip-account-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetSipDeviceRouteQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [sipDeviceRoute] = data.resources;

    return <SipAccountInfo sipDeviceRoute={sipDeviceRoute} />;
};
