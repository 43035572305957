import React from 'react';
import { SingleSelect as UiSingleSelect, SingleSelectProps } from '@didww/ui-kit';
import { SelectFieldProps } from './types';
import { useFieldError } from 'shared/lib/hooks/forms';
import { useSingleSelectField } from './hooks';

type Props = Omit<SingleSelectProps, 'errorMessage' | 'dataTestId' | 'value' | 'onChange'> & SelectFieldProps;

export const SingleSelect: React.FC<Props> = ({
    name,
    isAutoSubmit,
    touchOnChange,
    options,
    ...selectProps
}) => {
    const errorMessage = useFieldError(name);
    const { value, onChange } = useSingleSelectField({ name, isAutoSubmit, touchOnChange });

    return (
        <UiSingleSelect
            {...selectProps}
            name={name}
            options={options}
            errorMessage={errorMessage}
            value={value}
            onChange={onChange}
            dataTestId={`field-${name}`}
        />
    );
};
