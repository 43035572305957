import React from 'react';
import { IncomingTrunk } from 'entities/incoming-trunks';
import { GridLayout, PageLayout } from 'shared/ui/layouts';
import { Codecs } from 'shared/ui/cells';

interface Props {
    incomingTrunk: IncomingTrunk;
}

export const TrunkInfo: React.FC<Props> = ({ incomingTrunk }) => (
    <>
        <PageLayout.Header title={`Incoming Trunk ${incomingTrunk.name}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Incoming Trunk ID">{incomingTrunk.id}</GridLayout.Row>
                <GridLayout.Row title="Name">{incomingTrunk.name}</GridLayout.Row>
                <GridLayout.Row title="Domain">{incomingTrunk.domain}</GridLayout.Row>
                <GridLayout.Row title="Customer ID">{incomingTrunk.customer.id}</GridLayout.Row>
                <GridLayout.Row title="Customer">{incomingTrunk.customer.name}</GridLayout.Row>
                <GridLayout.Row title="Codecs">
                    <Codecs codecs={incomingTrunk.codecs} />
                </GridLayout.Row>
                <GridLayout.Row title="Transport">{incomingTrunk.transport_protocol}</GridLayout.Row>
                <GridLayout.Row title="Destination field">{incomingTrunk.destination_field}</GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
    </>
);
