import React from 'react';
import { Spacer } from 'shared/ui/spacer';
import { LottieLogo } from 'shared/ui/lottie-logo';
import { ErrorLayout } from 'shared/ui/layouts';
import json from './animation.json';
import styles from './styles.module.scss';

export const NotFoundPage: React.FC = () => (
    <ErrorLayout>
        <LottieLogo json={json} height="140px" width="160px" />
        <Spacer size={13} />
        <div className={styles.title}>404</div>
        <div className={styles.subTitle}>Page not found</div>
        <Spacer size={31} />
        <ErrorLayout.Button />
    </ErrorLayout>
);
