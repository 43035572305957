import React from 'react';
import { FormikContext, Form, FormikProps } from 'formik';

interface CustomForm {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
    className?: string;
    dataTestId?: string;
}

export const FormContext: React.FC<CustomForm> = ({ children, dataTestId, formik, className }) => (
    <FormikContext.Provider value={formik}>
        <Form className={className} data-testid={dataTestId || 'formik-form'}>
            {children}
        </Form>
    </FormikContext.Provider>
);
