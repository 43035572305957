import React from 'react';
import { Button } from 'shared/ui/buttons';
import { Modal } from 'shared/ui/modal';
import { ConfirmModalConfig } from 'shared/lib/services';
import styles from './styles.module.scss';

interface Props extends ConfirmModalConfig {
    isPushing: boolean;
    onCancel: () => void;
}

export const ConfirmModal: React.FC<Props> = ({
    title = 'Are you sure?',
    content,
    isPushing,
    onConfirm,
    onCancel,
}) => (
    <Modal
        title={title}
        width={450}
        onCancel={onCancel}
        footer={[
            <div className={styles.action} key="back">
                <Button type="text" color="blue" onClick={onCancel} isAutoFocus text="Cancel" />
            </div>,
            <div className={styles.action} key="confirm">
                <Button
                    disabled={isPushing}
                    isPushing={isPushing}
                    onClick={onConfirm}
                    color="red"
                    text="Confirm"
                />
            </div>,
        ]}
    >
        {content}
    </Modal>
);
