import React from 'react';
import cn from 'classnames';
import { ActiveIconProps, Size } from './types';
import { InlineSvg } from 'shared/ui/inline-svg';
import styles from './styles.module.scss';

function getButtonSize(buttonSize: Size): React.CSSProperties | undefined {
    if (!buttonSize) return;

    if (typeof buttonSize === 'object') {
        return buttonSize;
    }

    return {
        width: buttonSize,
        minWidth: buttonSize,
        height: buttonSize,
    };
}

export const ActiveIcon: React.FC<ActiveIconProps> = ({
    isLoading = false,
    onClick,
    icon,
    className,
    iconSize,
    buttonSize = 0,
    buttonType = 'button',
    test,
    color = 'default',
    disabled,
}) => {
    const cls = cn(styles.button, className, styles[color], {
        [styles.isLoading]: isLoading,
    });

    return (
        <button
            data-testid={test}
            type={buttonType}
            className={cls}
            onClick={onClick}
            disabled={disabled}
            style={getButtonSize(buttonSize)}
        >
            <InlineSvg type={icon} size={iconSize} />
        </button>
    );
};
