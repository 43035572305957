import React from 'react';
import { SubMenu } from '@didww/ui-kit';
import { signOut } from 'entities/auth';
import { MenuItem } from 'shared/ui/menu';
import { useDispatch } from 'app/hooks';
import { routes } from 'shared/routes';
import styles from './styles.module.scss';

interface Props {
    close: () => void;
}

export const ProfileMenu: React.FC<Props> = ({ close }) => {
    const dispatch = useDispatch();

    const logout = (): void => {
        dispatch(signOut());
    };

    return (
        <SubMenu title="Operator UI" headerClassName={styles.userInfoHeader}>
            <MenuItem key="logout" onClick={logout} icon="logout">
                Logout
            </MenuItem>
            <MenuItem key="settings" onClick={close} link={routes.settings} icon="settings">
                Settings
            </MenuItem>
        </SubMenu>
    );
};
