import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'app/hooks';
import { routes } from 'shared/routes';
import { ExternalAccountsListPage } from './list';
import { ExternalAccountShowPage } from './show';
import { ExternalAccountCreatePage } from './create';
import { ExternalAccountEditPage } from './edit';

export const ExternalAccountsPages: React.FC = () => {
    const isCreateAllowed = useSelector((state) => state.auth.external_account_create_allowed);

    return (
        <Switch>
            <Route exact path={routes.externalAccounts.list}>
                <ExternalAccountsListPage />
            </Route>
            <Route exact path={routes.externalAccounts.show}>
                <ExternalAccountShowPage />
            </Route>
            {isCreateAllowed && (
                <Route exact path={routes.externalAccounts.create}>
                    <ExternalAccountCreatePage />
                </Route>
            )}
            <Route exact path={routes.externalAccounts.edit}>
                <ExternalAccountEditPage />
            </Route>
            <Route path="*">
                <Redirect to={routes.externalAccounts.list} />
            </Route>
        </Switch>
    );
};
