import { createApi, BaseQueryFn } from '@reduxjs/toolkit/query/react';
import { SuccessResponse, BaseQueryOptions } from './types';
import { QueryError } from './types';
import { apiRequest, getQueryError } from './lib';

const axiosBaseQuery: BaseQueryFn<BaseQueryOptions, SuccessResponse<unknown>, QueryError> = async (
    options,
) => {
    const { filter, fields, include, page, sort, params, ...requestOptions } = options;

    const queryParams = {
        ...params,
        include,
        filter,
        fields,
        page,
        sort,
    };

    const res = await apiRequest({
        ...requestOptions,
        params: queryParams,
    });

    if (res.error) {
        return {
            ...res,
            error: getQueryError(res.error),
        };
    }

    return res;
};

export const TAG_TYPES = [
    'capacity_groups',
    'termination_routes',
    'termination_cdrs',
    'external_account_routes',
    'available_did_numbers',
    'customers',
    'outgoing_active_calls',
    'incoming_trunks',
    'sip_device_routes',
    'incoming_trunk_cdrs',
    'termination_gateways',
    'logins',
    'settings',
] as const;

export const apiSlice = createApi({
    reducerPath: 'pbxApi',
    baseQuery: axiosBaseQuery,
    tagTypes: TAG_TYPES,
    endpoints: () => ({}),
});
