import React from 'react';
import { ActionButton } from '../action-button';

interface Props {
    id: string;
    route: string;
}

export const Edit: React.FC<Props> = ({ id, route }) => {
    const path = route.replace(':id', encodeURIComponent(id));

    return <ActionButton icon="edit" text="Edit" path={path} />;
};
