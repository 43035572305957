export const truncateText = (str: string | null, maxLength = 40): string => {
    const trimmedText = (str || '').trim();
    const trimmedTextLength = trimmedText.length;

    // It`s necessary for correct rendering trimmedText if(maxLength > 5) ? (www...) : wwwww
    if (trimmedTextLength < 6) return trimmedText;

    const shouldElips = trimmedTextLength > maxLength;

    return shouldElips ? `${trimmedText.slice(0, maxLength - 3)}...` : trimmedText;
};
