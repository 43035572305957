import React from 'react';
import { useState } from 'react';
import { useField } from 'formik';
import { TextInput } from 'shared/ui/fields/text-input';
import { TextInputProps } from 'shared/ui/fields/text-input/types';
import { PasswordTooltip } from 'shared/ui/password-tooltip';

export const NewPasswordField: React.FC<TextInputProps> = (props) => {
    const [isTooltipVisible, setTooltipVisibility] = useState(false);
    const [{ value }] = useField(props.name);

    return (
        <PasswordTooltip visible={isTooltipVisible} fieldValue={value}>
            <TextInput {...props} onFieldFocus={setTooltipVisibility} />
        </PasswordTooltip>
    );
};
