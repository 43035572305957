import { useState, useMemo, useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FilterOption, FieldConfig } from '../../types';
import { getInitialFilters, saveFiltersToStorage } from '../storage';

type Fields = {
    visibleFields: FieldConfig[];
    hiddenFields: FieldConfig[];
};

type FieldsActions = {
    showField: (fieldName: string) => void;
    hideField: (fieldName: string) => void;
};

type ToggleField = (fieldName: string, visible: boolean) => void;

const useFieldsActions = ({
    visibleFields,
    hiddenFields,
    setFilterConfigs,
}: Fields & { setFilterConfigs: React.Dispatch<React.SetStateAction<FieldConfig[]>> }): FieldsActions => {
    const { setFieldValue } = useFormikContext<Record<string, unknown>>();

    const toggleFieldVisibility = useCallback<ToggleField>(
        (fieldName, visible) => {
            setFilterConfigs((configs) =>
                configs.map((config) => {
                    if (config.name === fieldName) {
                        return {
                            ...config,
                            visible,
                        };
                    }

                    return config;
                }),
            );
        },
        [setFilterConfigs],
    );

    const showField = useCallback(
        (fieldName: string) => {
            const fieldConfig = hiddenFields.find((config) => config.name === fieldName);

            if (!fieldConfig) return;

            const { defaultValue = '' } = fieldConfig;

            setFieldValue(fieldName, defaultValue);

            toggleFieldVisibility(fieldName, true);
        },
        [hiddenFields, setFieldValue, toggleFieldVisibility],
    );

    const hideField = useCallback(
        (fieldName: string) => {
            const isFieldExist = visibleFields.some((config) => config.name === fieldName);

            if (!isFieldExist) return;

            setFieldValue(fieldName, '');

            toggleFieldVisibility(fieldName, false);
        },
        [setFieldValue, toggleFieldVisibility, visibleFields],
    );

    return { showField, hideField };
};

export const useOptionalFilters = (
    storageKey: string,
    optionalFilters: FilterOption[],
): Fields & FieldsActions => {
    const [filterConfigs, setFilterConfigs] = useState(() => getInitialFilters(storageKey, optionalFilters));

    useEffect(() => {
        saveFiltersToStorage(storageKey, filterConfigs);
    }, [storageKey, filterConfigs]);

    const { visibleFields, hiddenFields } = useMemo(
        () =>
            filterConfigs.reduce<Fields>(
                (accum, config) => {
                    if (config.visible) {
                        accum.visibleFields.push(config);
                    } else {
                        accum.hiddenFields.push(config);
                    }

                    return accum;
                },
                { visibleFields: [], hiddenFields: [] },
            ),
        [filterConfigs],
    );

    const { showField, hideField } = useFieldsActions({
        visibleFields,
        hiddenFields,
        setFilterConfigs,
    });

    return { visibleFields, hiddenFields, showField, hideField };
};
