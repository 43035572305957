import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { SipAccountsListPage } from './list';
import { SipAccountShowPage } from './show';
import { SipAccountEditPage } from './edit';

export const SipAccountsPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.sipAccounts.list}>
            <SipAccountsListPage />
        </Route>
        <Route exact path={routes.sipAccounts.show}>
            <SipAccountShowPage />
        </Route>
        <Route exact path={routes.sipAccounts.edit}>
            <SipAccountEditPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.sipAccounts.list} />
        </Route>
    </Switch>
);
