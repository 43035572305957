import { SuccessResponse, BaseQueryOptions, apiSlice, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import { IncomingTrunkCdr, IncomingTrunkCdrsFilter } from './types';

type IncomingTrunkCdrsQuery = {
    filter: IncomingTrunkCdrsFilter;
    page: Page;
};

const RESOURCE_NAME = 'incoming_trunk_cdrs';

const tagsProvider = getTagsProviders(RESOURCE_NAME);

const incomingTrunkCdrsApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getIncomingTrunkCdrs: builder.query<SuccessResponse<IncomingTrunkCdr>, IncomingTrunkCdrsQuery>({
            query: ({ filter: { customer_id, ...filter }, page }) => {
                const queryFilter: BaseQueryOptions['filter'] = filter;

                if (customer_id) {
                    queryFilter['customer.id'] = customer_id;
                }

                return {
                    url: RESOURCE_NAME,
                    method: 'GET',
                    include: 'customer',
                    filter: queryFilter,
                    page,
                };
            },
            providesTags: tagsProvider.list,
        }),
        getIncomingTrunkCdr: builder.query<SuccessResponse<IncomingTrunkCdr>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer',
            }),
            providesTags: tagsProvider.show,
        }),
    }),
});

export const { useGetIncomingTrunkCdrsQuery, useGetIncomingTrunkCdrQuery } = incomingTrunkCdrsApi;
