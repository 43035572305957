import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';
import { useWindowWidth } from 'shared/lib/hooks/events';
import { ButtonProps, Button } from '../button';

interface Props extends ButtonProps {
    icon: string;
}

const MOBILE_BREAKPOINT = 800;

export const ActionButton: React.FC<Props> = ({ text, icon, ...props }) => {
    const windowWidth = useWindowWidth();

    if (windowWidth > MOBILE_BREAKPOINT) {
        return <Button {...props} text={text} icon={icon} />;
    }

    return (
        <Button {...props}>
            <InlineSvg type={icon} size={15} />
        </Button>
    );
};
