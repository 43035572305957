import React from 'react';
import { CapacityGroup, CapacityGroupsFilter, useGetCapacityGroupsQuery } from 'entities/capacity-groups';
import { ResourcesTable } from 'entities/resources-table';
import { PageLoader } from 'shared/ui/spinners';
import { FormContext } from 'entities/form-context';
import { Pagination } from 'shared/ui/pagination';
import { ListLayout } from 'shared/ui/layouts';
import { routes } from 'shared/routes';
import { useListLoading, useListState } from 'shared/lib/hooks/list';
import { STORAGE_KEY } from '../config';
import { StatsInfo } from './stats-info';
import { Filter } from './filter';
import { getColumns } from './columns';

export const List: React.FC = () => {
    const {
        query,
        filter: { formik },
        pagination: { page, setPage },
        tableSorter: { sorter, handleTableChange },
    } = useListState<CapacityGroupsFilter, CapacityGroup>({
        storageKey: STORAGE_KEY,
        initialFilter: {
            id: '',
            name: '',
        },
        initialSort: '',
    });

    const { data, isLoading, isFetching } = useGetCapacityGroupsQuery(query);

    const isPageLoading = useListLoading(isLoading);

    if (isPageLoading) {
        return <PageLoader />;
    }

    const columns = getColumns(sorter);

    return (
        <ListLayout>
            <ListLayout.Header>
                {data?.meta?.total_capacity !== null && (
                    <StatsInfo
                        totalCapacity={data?.meta?.total_capacity}
                        assignedCapacity={data?.meta?.assigned_capacity}
                    />
                )}
                <FormContext formik={formik}>
                    <Filter storageKey={STORAGE_KEY} />
                </FormContext>
            </ListLayout.Header>
            <ListLayout.Content>
                <ResourcesTable
                    storageKey={STORAGE_KEY}
                    showRoute={routes.capacityGroups.show}
                    columns={columns}
                    onChange={handleTableChange}
                    dataSource={data?.resources}
                    loading={isFetching}
                />
            </ListLayout.Content>
            <ListLayout.Footer>
                <Pagination onChangePage={setPage} totalRecords={data?.meta?.record_count} page={page} />
            </ListLayout.Footer>
        </ListLayout>
    );
};
