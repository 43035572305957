import React from 'react';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import {
    CapacityGroup,
    CapacityGroupEditAttrs,
    usePatchCapacityGroupMutation,
} from 'entities/capacity-groups';
import { routes } from 'shared/routes';
import { FormLayout, PageLayout } from 'shared/ui/layouts';
import { FormContext } from 'entities/form-context';
import { QueryError, ErrorCode } from 'shared/api';
import { message } from 'shared/message';
import { Fields } from './fields';
import { validator } from '../lib/validator';

interface Props {
    group: CapacityGroup;
}

export const Form: React.FC<Props> = ({ group }) => {
    const { id } = group;

    const showPath = routes.capacityGroups.show.replace(':id', id);

    const { push } = useHistory();

    const [patchCapacityGroup] = usePatchCapacityGroupMutation();

    const formik = useFormik<CapacityGroupEditAttrs>({
        initialValues: {
            name: group.name,
            capacity: group.capacity,
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await patchCapacityGroup({ id, values })
                .unwrap()
                .then(() => {
                    push(showPath);

                    message.success('Capacity Group updated');
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                });
        },
    });

    return (
        <>
            <PageLayout.Header title={`Capacity Group ${group.name}`} />
            <PageLayout.Content>
                <FormContext formik={formik}>
                    <Fields />
                    <FormLayout.Footer backPath={showPath} submitText="Save" />
                </FormContext>
            </PageLayout.Content>
        </>
    );
};
