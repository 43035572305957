import React, { useState } from 'react';
import { ColumnsType, Table, TableProps } from '@didww/ui-kit';
import { ResourceColumnsType } from '../types';
import { Entity } from 'shared/types/resources';
import { NoResults } from 'shared/ui/no-results';
import { ColumnsModal } from './columns-modal';
import { TableButton } from './table-button';
import { Show } from './show';
import { useColumnsSelection } from '../lib';

type Props<RecordType> = Omit<TableProps<RecordType>, 'dataSource' | 'columns'> & {
    showRoute: string;
    storageKey: string;
    dataSource: TableProps<RecordType>['dataSource'] | undefined;
    columns: ResourceColumnsType<RecordType>;
};

export function ResourcesTable<RecordType extends Entity>({
    showRoute,
    storageKey,
    columns,
    dataSource = [],
    ...tableProps
}: Props<RecordType>): JSX.Element {
    const [isModalVisible, setModalVisible] = useState(false);

    const { visibleColumns, columnsSelection, changeColumnVisibility } = useColumnsSelection(
        storageKey,
        columns,
    );

    const showModal = () => setModalVisible(true);

    const closeModal = () => setModalVisible(false);

    const tableColumns: ColumnsType<RecordType> = [
        {
            key: 'show',
            align: 'center',
            width: '55px',
            title: <TableButton onClick={showModal} />,
            render: function ShowColumn(_, row) {
                return <Show route={showRoute} id={row.id} round />;
            },
        },
        ...visibleColumns,
    ];

    return (
        <div data-testid="resources-table">
            {dataSource.length > 0 ? (
                <Table
                    size="middle"
                    columns={tableColumns}
                    dataSource={dataSource}
                    scroll={{ x: 'max-content' }}
                    {...tableProps}
                />
            ) : (
                <NoResults />
            )}
            {isModalVisible && (
                <ColumnsModal
                    selection={columnsSelection}
                    changeSelection={changeColumnVisibility}
                    onCancel={closeModal}
                />
            )}
        </div>
    );
}
