import React from 'react';
import { AvailableNumberCreate } from 'features/available-numbers';
import { PageLayout } from 'shared/ui/layouts';

export const AvailableNumberCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Available Number" />
        <PageLayout.Content>
            <AvailableNumberCreate />
        </PageLayout.Content>
    </PageLayout>
);
