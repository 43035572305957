import { loadByQuery, QueryThunk } from 'shared/api';
import { AvailableNumber } from '../types';

export const fetchNumbersOptions =
    (customerId: string, page: number, number: string | undefined): QueryThunk<AvailableNumber> =>
    (dispatch) => {
        const filter: Record<string, string> = {
            'customer.id': customerId,
        };

        if (number) {
            filter.number = number;
        }

        return dispatch(
            loadByQuery<AvailableNumber>('available_did_numbers', {
                fields: {
                    available_did_numbers: 'number',
                },
                filter,
                page: {
                    number: page,
                    size: 10,
                },
                sort: '-id',
            }),
        );
    };
