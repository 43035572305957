import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { TERMINATION_MODE_LABELS, Customer, OpenPs, LimitCell, GroupCell } from 'entities/customers';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<Customer> => [
    {
        key: 'show',
        align: 'center',
        width: '55px',
        title: '',
        render: function OpenUiColumn(_, row) {
            return <OpenPs id={row.id} />;
        },
    },
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Customer ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
    },
    {
        key: 'language',
        dataIndex: 'language',
        title: 'UI language',
        sorter: true,
        sortOrder: sorter.column === 'language' && sorter.order,
    },
    {
        key: 'capacity_limit',
        dataIndex: 'capacity_limit',
        title: 'Capacity limit',
        sorter: true,
        sortOrder: sorter.column === 'capacity_limit' && sorter.order,
        render: function CapacityLimitColumn(_, row) {
            return <LimitCell value={row.capacity_limit} />;
        },
    },
    {
        key: 'sip_account_limit',
        dataIndex: 'sip_account_limit',
        title: 'SIP-Account limit',
        sorter: true,
        sortOrder: sorter.column === 'sip_account_limit' && sorter.order,
        render: function SipAccountLimitColumn(_, row) {
            return <LimitCell value={row.sip_account_limit} />;
        },
    },
    {
        key: 'capacity_group',
        title: 'Capacity Group',
        render: function CapacityGroupColumn(_, row) {
            return <GroupCell group={row.capacity_group} />;
        },
    },
    {
        key: 'trm_mode',
        title: 'Termination mode',
        render: (_, row) => TERMINATION_MODE_LABELS[row.trm_mode],
    },
];
