import { FormikErrors } from 'formik';
import { FormConfig } from './types';

export function buildValidator<T>(config: FormConfig<T>): (values: T) => FormikErrors<T> {
    const validator = (formValues: T): FormikErrors<T> => {
        let formikErrors = {};

        for (const field in formValues) {
            const fieldValue = formValues[field];

            const validators = config[field];

            if (!validators) continue;

            const errors = validators.reduce((accum: string[], validator) => {
                const errorText = validator(fieldValue, formValues);

                if (errorText) {
                    return [...accum, errorText];
                }
                return accum;
            }, []);

            if (errors[0]) {
                formikErrors = {
                    ...formikErrors,
                    [field]: errors[0],
                };
            }
        }
        return formikErrors;
    };

    return validator;
}
