import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'shared/routes';
import { Layout } from './layout';
import { AvailableNumbersPages } from 'pages/available-numbers';
import { CustomersPages } from 'pages/customers';
import { DashboardPage } from 'pages/dashboard';
import { ExternalAccountsPages } from 'pages/external-accounts';
import { IncomingTrunksPages } from 'pages/incoming-trunks';
import { TerminationRoutesPages } from 'pages/termination-routes';
import { SipAccountsPages } from 'pages/sip-accounts';
import { TerminationCdrsPages } from 'pages/termination-cdrs';
import { IncomingTrunkCdrsPages } from 'pages/incoming-trunk-cdrs';
import { TerminationGatewaysPages } from 'pages/termination-gateways';
import { CapacityGroupsPages } from 'pages/capacity-groups';
import { SettingsPage } from 'pages/settings';

export const User: React.FC = () => {
    return (
        <Layout>
            <Switch>
                <Route exact path={routes.dashboard}>
                    <DashboardPage />
                </Route>
                <Route exact path={routes.settings}>
                    <SettingsPage />
                </Route>
                <Route path={routes.capacityGroups.list}>
                    <CapacityGroupsPages />
                </Route>
                <Route path={routes.customers.list}>
                    <CustomersPages />
                </Route>
                <Route path={routes.availableNumbers.list}>
                    <AvailableNumbersPages />
                </Route>
                <Route path={routes.externalAccounts.list}>
                    <ExternalAccountsPages />
                </Route>
                <Route path={routes.incomingTrunks.list}>
                    <IncomingTrunksPages />
                </Route>
                <Route path={routes.terminationRoutes.list}>
                    <TerminationRoutesPages />
                </Route>
                <Route path={routes.sipAccounts.list}>
                    <SipAccountsPages />
                </Route>
                <Route path={routes.terminationCdrs.list}>
                    <TerminationCdrsPages />
                </Route>
                <Route path={routes.incomingTrunkCdrs.list}>
                    <IncomingTrunkCdrsPages />
                </Route>
                <Route path={routes.terminationGateways.list}>
                    <TerminationGatewaysPages />
                </Route>
                <Route path="*">
                    <Redirect to={routes.dashboard} />
                </Route>
            </Switch>
        </Layout>
    );
};
