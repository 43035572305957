import React from 'react';
import { InlineSvg } from 'shared/ui/inline-svg';
import styles from './styles.module.scss';

interface Props {
    closeToast: () => void;
}

// We need to make typing like that for capability with react-toastify types
export const CloseIcon = ({ closeToast }: Props): React.ReactElement => (
    <button onClick={closeToast} className={styles.closeButton}>
        <InlineSvg type="close-toast" size={10} />
    </button>
);
