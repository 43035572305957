import React, { useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'app/hooks';
import { TwoFactorAuthVerifyAttrs, usePostTwoFactorVerificationMutation } from 'entities/two-factor-auth';
import { useGetLoginQuery } from 'entities/logins';
import { FormContext } from 'entities/form-context';
import { ErrorCode, QueryError } from 'shared/api';
import { Drawer } from 'features/login/ui/drawer';
import { Fields } from './fields';
import { validator } from '../lib/validator';
import { setSession } from 'entities/auth/lib/set-session';
import { routes } from 'shared/routes';
import { useHistory } from 'react-router';

export const Form: React.FC = () => {
    const [isLoggined, setLoggined] = useState(false);
    const [postTwoFactorVerification] = usePostTwoFactorVerificationMutation();
    const { data } = useGetLoginQuery('');
    const history = useHistory();
    const dispatch = useDispatch();

    const formik = useFormik<TwoFactorAuthVerifyAttrs>({
        initialValues: {
            otp_code: '',
        },
        validate: validator,
        onSubmit: async (values, { setErrors }) => {
            await postTwoFactorVerification(values)
                .unwrap()
                .then(() => {
                    if (data) {
                        const login = data.resources[0];
                        setSession(
                            {
                                username: login.id,
                                customer_create_allowed: login.customer_create_allowed,
                                external_account_create_allowed: login.external_account_create_allowed,
                                two_factor_auth_enabled: login.two_factor_auth_enabled,
                            },
                            dispatch,
                        );
                    }

                    setLoggined(true);
                })
                .catch((err: QueryError) => {
                    if (err.code === ErrorCode.Validation) {
                        setErrors(err.errors);
                    }
                    if (err.code === ErrorCode.Locked) {
                        history.push(routes.signIn);
                    }
                });
        },
    });

    return (
        <Drawer isHidden={isLoggined}>
            <FormContext formik={formik}>
                <Fields />
            </FormContext>
        </Drawer>
    );
};
