import React from 'react';
import { IncomingTrunkCdr } from 'entities/incoming-trunk-cdrs';
import { GridLayout } from 'shared/ui/layouts';

interface Props {
    incomingTrunkCdr: IncomingTrunkCdr;
}

export const CdrInfo: React.FC<Props> = ({ incomingTrunkCdr }) => (
    <GridLayout>
        <GridLayout.Row title="Incoming Trunk CDR ID">{incomingTrunkCdr.id}</GridLayout.Row>
        <GridLayout.Row title="Duration">{incomingTrunkCdr.duration}</GridLayout.Row>
        <GridLayout.Row title="Src name">{incomingTrunkCdr.src_name}</GridLayout.Row>
        <GridLayout.Row title="Src number">{incomingTrunkCdr.src_number}</GridLayout.Row>
        <GridLayout.Row title="Dst number">{incomingTrunkCdr.dst_number}</GridLayout.Row>
        <GridLayout.Row title="Did number">{incomingTrunkCdr.did_number}</GridLayout.Row>
        <GridLayout.Row title="Lega disconnect code">{incomingTrunkCdr.lega_disconnect_code}</GridLayout.Row>
        <GridLayout.Row title="Lega disconnect reason">
            {incomingTrunkCdr.lega_disconnect_reason}
        </GridLayout.Row>
        <GridLayout.Row title="Incoming Trunk ID">{incomingTrunkCdr.lega_call_id}</GridLayout.Row>
        <GridLayout.Row title="Time start">{incomingTrunkCdr.time_start}</GridLayout.Row>
        <GridLayout.Row title="Time end">{incomingTrunkCdr.time_end}</GridLayout.Row>
        <GridLayout.Row title="Time connect">{incomingTrunkCdr.time_connect}</GridLayout.Row>
        <GridLayout.Row title="Customer">{incomingTrunkCdr.customer.name}</GridLayout.Row>
    </GridLayout>
);
