import { useState, useEffect } from 'react';

export const useNetworkStatus = (): boolean => {
    const [isOnline, setNetworkStatus] = useState(navigator.onLine);

    useEffect(() => {
        const offlineHandler = () => {
            setNetworkStatus(false);
        };

        const onlineHandler = () => {
            setNetworkStatus(true);
        };

        window.addEventListener('offline', offlineHandler);
        window.addEventListener('online', onlineHandler);

        return () => {
            window.removeEventListener('offline', offlineHandler);
            window.removeEventListener('online', onlineHandler);
        };
    }, []);

    return isOnline;
};
