import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput } from 'shared/ui/fields';

const optionalFilters = [
    {
        name: 'domain',
        label: 'Domain',
        defaultValue: '',
        node: (
            <ConditionalFilter.Item size="large">
                <TextInput name="domain" labelText="Domain" />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="Incoming Trunk ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <CustomersSelect labelText="Customer" />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
