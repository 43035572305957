import React from 'react';
import { AvaiableDidNumber } from 'entities/available-did-numbers';
import { AvailableNumberDelete } from 'features/available-numbers';
import { Edit } from 'shared/ui/buttons';
import { GridLayout, PageLayout } from 'shared/ui/layouts';
import { BooleanCell } from 'shared/ui/cells';
import { routes } from 'shared/routes';

interface Props {
    availableNumber: AvaiableDidNumber;
}

export const NumberInfo: React.FC<Props> = ({ availableNumber }) => (
    <>
        <PageLayout.Header title={`Available Number ${availableNumber.number}`} />
        <PageLayout.Content>
            <GridLayout>
                <GridLayout.Row title="Available Number ID">{availableNumber.id}</GridLayout.Row>
                <GridLayout.Row title="Number">{availableNumber.number}</GridLayout.Row>
                <GridLayout.Row title="External ID">{availableNumber.external_id}</GridLayout.Row>
                <GridLayout.Row title="Customer ID">{availableNumber.customer.id}</GridLayout.Row>
                <GridLayout.Row title="Customer">{availableNumber.customer.name}</GridLayout.Row>
                <GridLayout.Row title="Enabled">
                    <BooleanCell value={availableNumber.enabled} />
                </GridLayout.Row>
                <GridLayout.Row title="Read-only">
                    <BooleanCell value={availableNumber.readonly} />
                </GridLayout.Row>
            </GridLayout>
        </PageLayout.Content>
        <PageLayout.Footer
            backPath={routes.availableNumbers.list}
            actions={
                availableNumber.readonly
                    ? undefined
                    : [
                          <Edit key="edit" id={availableNumber.id} route={routes.availableNumbers.edit} />,
                          <AvailableNumberDelete key="delete" id={availableNumber.id} />,
                      ]
            }
        />
    </>
);
