import React from 'react';
import { TooltipRule } from './tooltip-rule';
import { Spacer } from 'shared/ui/spacer';
import { ValidationResult } from '../types';
import styles from './styles.module.scss';

export const TooltipContent: React.FC<ValidationResult> = ({
    color,
    message,
    progress,
    complexityRequirements,
}) => {
    return (
        <div className={styles.tooltip}>
            <span className={styles.title} data-testid="tooltip-title">
                Password must be at least 8 characters long and contain any 3 of the following:
            </span>
            <ul className={styles.rules}>
                <TooltipRule
                    type="lowercase"
                    isChecked={complexityRequirements.lowercase}
                    label="Lowercase letters (a-z)"
                />
                <TooltipRule
                    type="uppercase"
                    isChecked={complexityRequirements.uppercase}
                    label="Uppercase letters (A-Z)"
                />
                <TooltipRule type="digits" isChecked={complexityRequirements.digits} label="Digits (0-9)" />
                <TooltipRule
                    type="symbols"
                    isChecked={complexityRequirements.symbols}
                    label="Special characters (?#@…)"
                />
            </ul>

            <span className={styles.title}>Strength:</span>
            <div className={styles.progress}>
                <div
                    className={styles.progressInner}
                    style={{
                        width: `${progress}%`,
                        backgroundColor: color,
                    }}
                    data-testid="progress-value"
                />
            </div>

            <Spacer size={10} />

            <span className={styles.message} data-testid="tooltip-message" style={{ color }}>
                {message}
            </span>
        </div>
    );
};
