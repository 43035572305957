import React from 'react';
import { useParams } from 'react-router-dom';
import { IncomingTrunkCdrShow } from 'features/incoming-trunk-cdrs';
import { PageLayout } from 'shared/ui/layouts';
import { routes } from 'shared/routes';

export const IncomingTrunkCdrShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <PageLayout.Header title={`Incoming Trunk CDR #${id}`} />
            <PageLayout.Content>
                <IncomingTrunkCdrShow id={id} />
            </PageLayout.Content>
            <PageLayout.Footer backPath={routes.incomingTrunkCdrs.list} />
        </PageLayout>
    );
};
