import React from 'react';
import { TerminationRouteCreate } from 'features/termination-routes';
import { PageLayout } from 'shared/ui/layouts';

export const TerminationRouteCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Termination Route" />
        <PageLayout.Content>
            <TerminationRouteCreate />
        </PageLayout.Content>
    </PageLayout>
);
