import React from 'react';
import { FormikProps } from 'formik';
import { FormContext } from 'entities/form-context';
import { GeneralModal } from '../general-modal';
import { GeneralModalProps } from '../types';

interface Props extends GeneralModalProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: FormikProps<any>;
}

export const ModalForm: React.FC<Props> = ({ formik, children, ...generalModalProps }) => {
    return (
        <FormContext formik={formik}>
            <GeneralModal {...generalModalProps}>{children}</GeneralModal>
        </FormContext>
    );
};
