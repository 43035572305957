import React from 'react';
import { Toggle } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';
import { AllowedIps } from './allowed-ips';

interface Props {
    isAllowedIpsDisabled: boolean;
}

export const Fields: React.FC<Props> = ({ isAllowedIpsDisabled }) => (
    <FormLayout>
        <FormLayout.Field>
            <Toggle name="use_allowed_ips" labelText="Use allowed IPs" />
        </FormLayout.Field>
        <FormLayout.Field>
            <AllowedIps isDisabled={isAllowedIpsDisabled} />
        </FormLayout.Field>
    </FormLayout>
);
