import { SuccessResponse, apiSlice, getTagsInvalidators, getTagsProviders } from 'shared/api';
import { Page } from 'shared/types/resources';
import {
    IncomingTrunkFilter,
    IncomingTrunk,
    IncomingTrunkCreateAttrs,
    IncomingTrunkEditAttrs,
} from './types';

type IncomingTrunkQuery = {
    filter: IncomingTrunkFilter;
    page: Page;
    sort?: string;
};

const RESOURCE_NAME = 'incoming_trunks';

const tagsProvider = getTagsProviders(RESOURCE_NAME);
const tagsInvalidators = getTagsInvalidators(RESOURCE_NAME);

const incomingTrunksApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getIncomingTrunks: builder.query<SuccessResponse<IncomingTrunk>, IncomingTrunkQuery>({
            query: ({ filter: { customer_id, ...filter }, page, sort }) => ({
                url: RESOURCE_NAME,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
                filter: {
                    ...filter,
                    ...(customer_id ? { 'customer.id': customer_id } : undefined),
                },
                page,
                sort: sort || '-id',
            }),
            providesTags: tagsProvider.list,
        }),
        getIncomingTrunk: builder.query<SuccessResponse<IncomingTrunk>, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'GET',
                include: 'customer',
                fields: {
                    customers: 'name',
                },
            }),
            providesTags: tagsProvider.show,
        }),
        postIncomingTrunk: builder.mutation<SuccessResponse<IncomingTrunk>, IncomingTrunkCreateAttrs>({
            query: ({ customer_id, ...attributes }) => ({
                url: RESOURCE_NAME,
                method: 'POST',
                data: {
                    type: RESOURCE_NAME,
                    attributes,
                    relationships: {
                        customer: {
                            data: {
                                id: customer_id,
                                type: 'customers',
                            },
                        },
                    },
                },
            }),
            invalidatesTags: tagsInvalidators.create,
        }),
        patchIncomingTrunk: builder.mutation<
            SuccessResponse<IncomingTrunk>,
            { id: string; values: IncomingTrunkEditAttrs }
        >({
            query: ({ id, values }) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'PATCH',
                data: {
                    id,
                    type: RESOURCE_NAME,
                    attributes: values,
                },
            }),
            invalidatesTags: tagsInvalidators.update,
        }),
        deleteIncomingTrunk: builder.mutation<undefined, string>({
            query: (id) => ({
                url: `${RESOURCE_NAME}/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: tagsInvalidators.remove,
        }),
    }),
});

export const {
    useGetIncomingTrunksQuery,
    useGetIncomingTrunkQuery,
    usePostIncomingTrunkMutation,
    useDeleteIncomingTrunkMutation,
    usePatchIncomingTrunkMutation,
} = incomingTrunksApi;
