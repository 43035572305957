import React from 'react';
import { ExternalAccountCreate } from 'features/external-accounts';
import { PageLayout } from 'shared/ui/layouts';

export const ExternalAccountCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create External Account" />
        <PageLayout.Content>
            <ExternalAccountCreate />
        </PageLayout.Content>
    </PageLayout>
);
