import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { TextInput } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';
import { CodecsSelect } from 'entities/form-selects';
import { DestinationSelect, TransportSelect } from 'entities/incoming-trunks/ui';

export const Fields: React.FC = () => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TransportSelect />
        </FormLayout.Field>
        <FormLayout.Field>
            <CodecsSelect />
        </FormLayout.Field>
        <FormLayout.Field>
            <DestinationSelect />
        </FormLayout.Field>
    </FormLayout>
);
