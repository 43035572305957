import React from 'react';
import styles from './styles.module.scss';

interface Props {
    totalCapacity?: number;
    assignedCapacity?: number;
}

export const StatsInfo: React.FC<Props> = ({ totalCapacity = 0, assignedCapacity = 0 }) => {
    const ungroupedCapacity = totalCapacity - assignedCapacity;

    return (
        <div className={styles.stats}>
            <div className={styles.card}>
                <span className={styles.title}>System Capacity</span>
                <span className={styles.value}>{totalCapacity}</span>
            </div>
            <div className={styles.card}>
                <span className={styles.title}>Ungrouped Capacity</span>
                <span className={styles.value}>{ungroupedCapacity}</span>
            </div>
            <div className={styles.card}>
                <span className={styles.title}>Grouped Capacity</span>
                <span className={styles.value}>{assignedCapacity}</span>
            </div>
        </div>
    );
};
