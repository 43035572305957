import { AppThunk } from 'app/store';
import { CapacityGroup, CapacityGroupsMeta } from './types';
import { authorizedRequest } from 'shared/api';

export const fetchTotalCapacity = (): AppThunk<Promise<number | null>> => async (dispatch) => {
    const { data } = await dispatch(
        authorizedRequest<CapacityGroup, CapacityGroupsMeta>({
            method: 'GET',
            url: 'capacity_groups',
            params: {
                fields: {
                    capacity_groups: 'id',
                },
                page: {
                    number: 1,
                    size: 1,
                },
            },
        }),
    );

    if (data?.meta) {
        return data.meta.total_capacity;
    }

    return null;
};
