import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from 'shared/routes';
import { IncomingTrunksListPage } from './list';
import { IncomingTrunksShowPage } from './show';
import { IncomingTrunkCreatePage } from './create';
import { IncomingTrunkEditPage } from './edit';

export const IncomingTrunksPages: React.FC = () => (
    <Switch>
        <Route exact path={routes.incomingTrunks.list}>
            <IncomingTrunksListPage />
        </Route>
        <Route exact path={routes.incomingTrunks.show}>
            <IncomingTrunksShowPage />
        </Route>
        <Route exact path={routes.incomingTrunks.create}>
            <IncomingTrunkCreatePage />
        </Route>
        <Route exact path={routes.incomingTrunks.edit}>
            <IncomingTrunkEditPage />
        </Route>
        <Route path="*">
            <Redirect to={routes.incomingTrunks.list} />
        </Route>
    </Switch>
);
