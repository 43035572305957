import React from 'react';
import { useParams } from 'react-router-dom';
import { routes } from 'shared/routes';
import { SipAccountShow } from 'features/sip-accounts';
import { Edit } from 'shared/ui/buttons';
import { PageLayout } from 'shared/ui/layouts';

export const SipAccountShowPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <PageLayout.Header title={`SIP-Account #${id}`} />
            <PageLayout.Content>
                <SipAccountShow id={id} />
            </PageLayout.Content>
            <PageLayout.Footer
                backPath={routes.sipAccounts.list}
                actions={[<Edit key="edit" id={id} route={routes.sipAccounts.edit} />]}
            />
        </PageLayout>
    );
};
