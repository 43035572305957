const DEVELOPMENT_HOST = process.env.REACT_APP_API_URL;
const PRODUCTION_HOST = window.location.origin;
const TEST_HOST = 'https://pbx2-staging.in.didww.com';

export const HOST = (() => {
    switch (process.env.NODE_ENV) {
        case 'development':
            return DEVELOPMENT_HOST;
        case 'production':
            return PRODUCTION_HOST;
        case 'test':
            return TEST_HOST;
        default:
            return TEST_HOST;
    }
})();

export const ENVIRONMENT = (() => {
    const origin = window.location.origin;

    if (origin.includes('staging')) {
        return 'staging';
    } else if (origin.includes('sandbox')) {
        return 'sandbox';
    }

    return 'production';
})();
