import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { CapacityGroupsSelect, CapacityGroup } from 'entities/form-selects';
import { OPERATOR_TERMINATION_OPTIONS_ARRAY, TERMINATION_OPTIONS_ARRAY } from 'entities/customers';
import { TextInput, SingleSelect } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

interface Props {
    initialGroups?: CapacityGroup[];
    isTrmModeEditable: boolean;
}

export const Fields: React.FC<Props> = ({ initialGroups, isTrmModeEditable }) => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <CapacityGroupsSelect
                initialResources={initialGroups}
                labelText={<LabelInfo title="Capacity Group" />}
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput
                name="capacity_limit"
                labelText={<LabelInfo title="Capacity limit" />}
                placeholder="Unlimited"
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput
                name="sip_account_limit"
                labelText={<LabelInfo title="SIP-Account limit" />}
                placeholder="Unlimited"
            />
        </FormLayout.Field>
        <FormLayout.Field>
            <SingleSelect
                name="trm_mode"
                isDisabled={!isTrmModeEditable}
                isSearchable={false}
                labelText={<LabelInfo title="Termination mode" />}
                options={isTrmModeEditable ? OPERATOR_TERMINATION_OPTIONS_ARRAY : TERMINATION_OPTIONS_ARRAY}
            />
        </FormLayout.Field>
    </FormLayout>
);
