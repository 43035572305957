import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'app/hooks';
import { Autocomplete, Option } from 'shared/ui/fields';
import { CapacityGroup } from '../types';
import { fetchCapacityGroupsOptions } from '../model';

interface Props {
    name?: string;
    labelText?: React.ReactNode;
    initialResources?: CapacityGroup[];
    allowNotAssign?: boolean;
}

const notAssignedOption = {
    value: 'null',
    label: 'Not assigned',
};

export const GroupsSelect: React.FC<Props> = ({
    name = 'capacity_group_id',
    labelText,
    initialResources,
    allowNotAssign,
}) => {
    const dispatch = useDispatch();

    const fetchOptions = useCallback(
        (page: number, name?: string) => dispatch(fetchCapacityGroupsOptions(page, name)),
        [dispatch],
    );

    const initialOptions = useMemo(() => {
        if (allowNotAssign) {
            return [notAssignedOption, ...(initialResources || [])];
        }

        return initialResources;
    }, [initialResources, allowNotAssign]);

    const makeGroupsOptions = (resources: CapacityGroup[]): Option[] => {
        const options: Option[] = [];

        if (allowNotAssign) {
            options.push(notAssignedOption);
        }

        return options.concat(
            resources.map((option: CapacityGroup) => ({
                value: option.id,
                label: option.name,
            })),
        );
    };

    return (
        <Autocomplete
            name={name}
            onLoad={fetchOptions}
            labelText={labelText}
            initialOptions={initialOptions}
            makeOptions={makeGroupsOptions}
            filterBy="none"
            isClearable
            valueOnClear=""
            isLoadOnCloseMenu
        />
    );
};
