import React from 'react';
import { CustomersSelect } from 'entities/form-selects';
import { ConditionalFilter } from 'entities/conditional-filter';
import { TextInput, SingleSelect } from 'shared/ui/fields';

const defaultOptions = [
    {
        value: '',
        label: 'Any',
    },
    {
        value: true,
        label: 'Yes',
    },
    {
        value: false,
        label: 'No',
    },
];

const enabledOptions = [
    {
        value: '',
        label: 'All',
    },
    {
        value: true,
        label: 'Enabled',
    },
    {
        value: false,
        label: 'Disabled',
    },
];

const optionalFilters = [
    {
        name: 'username',
        label: 'Username',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="username" labelText="Username" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'incoming_internal',
        label: 'Incoming internal',
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    isSearchable={false}
                    name="incoming_internal"
                    options={defaultOptions}
                    labelText="Incoming internal"
                />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'incoming_external',
        label: 'Incoming external',
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    isSearchable={false}
                    name="incoming_external"
                    options={defaultOptions}
                    labelText="Incoming external"
                />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'outgoing_internal',
        label: 'Outgoing internal',
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    isSearchable={false}
                    name="outgoing_internal"
                    options={defaultOptions}
                    labelText="Outgoing internal"
                />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'outgoing_external',
        label: 'Outgoing external',
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    isSearchable={false}
                    name="outgoing_external"
                    options={defaultOptions}
                    labelText="Outgoing external"
                />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'on_demand',
        label: 'On demand',
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    isSearchable={false}
                    name="on_demand"
                    options={defaultOptions}
                    labelText="On demand"
                />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'caller_id_name',
        label: 'Caller ID name',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="caller_id_name" labelText="Caller ID name" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'internal_caller_id',
        label: 'Internal caller ID',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="internal_caller_id" labelText="Internal caller ID" />
            </ConditionalFilter.Item>
        ),
    },
    {
        name: 'external_caller_id',
        label: 'External caller ID',
        node: (
            <ConditionalFilter.Item>
                <TextInput name="external_caller_id" labelText="External caller ID" />
            </ConditionalFilter.Item>
        ),
    },

    {
        name: 'allow_external_calls',
        label: 'Allow external calls',
        defaultValue: false,
        node: (
            <ConditionalFilter.Item size="medium">
                <SingleSelect
                    name="allow_external_calls"
                    isSearchable={false}
                    options={enabledOptions}
                    labelText="Allow external calls"
                />
            </ConditionalFilter.Item>
        ),
    },
];

export const Filter: React.FC<{ storageKey: string }> = ({ storageKey }) => (
    <ConditionalFilter storageKey={storageKey} optionalFilters={optionalFilters}>
        <ConditionalFilter.Item>
            <TextInput name="id" labelText="SIP-Account ID" />
        </ConditionalFilter.Item>
        <ConditionalFilter.Item>
            <CustomersSelect labelText="Customer" />
        </ConditionalFilter.Item>
    </ConditionalFilter>
);
