import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface Props {
    in?: boolean;
    timeout: number;
    classNames: Record<string, string>;
    unmountOnExit?: boolean;
}

function isTestEnv(): boolean {
    return process.env.NODE_ENV === 'test';
}

export const Transition: React.FC<Props> = (props) => {
    if (isTestEnv()) {
        return props.in ? <>{props.children}</> : null;
    }

    return (
        <CSSTransition {...props}>
            <>{props.children}</>
        </CSSTransition>
    );
};
