import React from 'react';
import { CapacityUsage } from 'features/capacity';
import { PageLayout } from 'shared/ui/layouts';

export const DashboardPage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Capacity Usage" />
        <PageLayout.Content>
            <CapacityUsage />
        </PageLayout.Content>
    </PageLayout>
);
