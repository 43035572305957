import React, { useCallback, useState } from 'react';
import { useField } from 'formik';
import { useDispatch } from 'app/hooks';
import { Autocomplete, Option } from 'shared/ui/fields';
import { AvailableNumber } from '../types';
import { fetchNumbersOptions } from '../model';
import { useUpdate } from 'shared/lib/hooks/use-update';

interface Props {
    name?: string;
    customerId: string | undefined;
    labelText?: React.ReactNode;
    initialResources?: AvailableNumber[];
}

const makeNumbersOptions = (numbers: AvailableNumber[]): Option[] =>
    numbers.map(({ id, number }) => ({
        value: id,
        label: number,
    }));

export const NumbersSelect: React.FC<Props> = ({
    name = 'available_number_id',
    customerId,
    labelText,
    initialResources,
}) => {
    const dispatch = useDispatch();

    const [isDisabled, setDisabled] = useState(!customerId);

    const { setValue } = useField<string>(name)[2];

    useUpdate(() => {
        setValue('');
        setDisabled(true);

        if (customerId) {
            // We should trigger refetching of options with
            // new customer id
            setTimeout(() => {
                setDisabled(false);
            });
        }
    }, [customerId]);

    const fetchOptions = useCallback(
        (page: number, number?: string) => {
            if (!customerId) {
                return {
                    data: [],
                };
            }

            return dispatch(fetchNumbersOptions(customerId, page, number));
        },
        [dispatch, customerId],
    );

    return (
        <Autocomplete
            isDisabled={isDisabled}
            name={name}
            onLoad={fetchOptions}
            labelText={labelText}
            initialOptions={initialResources}
            makeOptions={makeNumbersOptions}
            filterBy="none"
            isClearable
            valueOnClear=""
            isLoadOnCloseMenu
        />
    );
};
