import React from 'react';
import { CapacityGroupCreate } from 'features/capacity-groups';
import { PageLayout } from 'shared/ui/layouts';

export const CapacityGroupCreatePage: React.FC = () => (
    <PageLayout>
        <PageLayout.Header title="Create Capacity Group" />
        <PageLayout.Content>
            <CapacityGroupCreate />
        </PageLayout.Content>
    </PageLayout>
);
