/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useSelector } from 'app/hooks';
import { addEndListener } from './helpers';
import { useRedirects } from './hooks';
import styles from './styles.module.scss';

type Props = {
    user: React.ReactNode;
    guest: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ user, guest }) => {
    const hasAccess = useSelector((state) => !!state.auth.username);

    const { redirectToGuest, redirectToUser } = useRedirects();

    const redirect = useCallback(() => {
        // If hasAccess is switching from false to true,
        // then we should redirect user to guest routes
        if (!hasAccess) {
            redirectToUser();
        } else {
            redirectToGuest();
        }
    }, [hasAccess, redirectToGuest, redirectToUser]);

    return (
        <div className={styles.bg}>
            <SwitchTransition mode="out-in">
                <CSSTransition
                    key={hasAccess ? 'user' : 'guest'}
                    classNames={styles}
                    addEndListener={addEndListener}
                    onExited={redirect}
                    timeout={300}
                >
                    {hasAccess ? user : guest}
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
};
