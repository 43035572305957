import React from 'react';
import { Spacer } from 'shared/ui/spacer';
import { ErrorLayout } from 'shared/ui/layouts';
import { LottieLogo } from 'shared/ui/lottie-logo';
import json from './animation.json';
import styles from './styles.module.scss';

export const SomethingWentWrongPage: React.FC = () => {
    return (
        <ErrorLayout>
            <LottieLogo json={json} width="160px" height="140px" />
            <Spacer size={10} />
            <div className={styles.title}>400</div>
            <div className={styles.subTitle}>Something went wrong</div>
            <Spacer size={31} />
            <ErrorLayout.Button />
        </ErrorLayout>
    );
};
