import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDeleteCustomerMutation } from 'entities/customers';
import { routes } from 'shared/routes';
import { Delete } from 'shared/ui/buttons';
import { showConfirmModal } from 'shared/lib/services';
import { message } from 'shared/message';

interface Props {
    id: string;
}

export const CustomerDelete: React.FC<Props> = ({ id }) => {
    const { replace } = useHistory();
    const [deleteCustomer] = useDeleteCustomerMutation();

    const showDeleteModal = showConfirmModal({
        content: 'This will delete selected Customer',
        onConfirm: async (): Promise<void> => {
            await deleteCustomer(id)
                .unwrap()
                .then(() => {
                    replace(routes.customers.list);

                    message.success('Customer deleted');
                });
        },
    });

    return <Delete onClick={showDeleteModal} />;
};
