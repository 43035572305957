import React from 'react';
import { PageLoader } from 'shared/ui/spinners';
import { useGetIncomingTrunkQuery } from 'entities/incoming-trunks';
import { TrunkInfo } from './trunk-info';

interface Props {
    id: string;
}

export const Show: React.FC<Props> = ({ id }) => {
    const { data, isLoading } = useGetIncomingTrunkQuery(id);

    if (isLoading || !data) {
        return <PageLoader />;
    }

    const [incomingTrunk] = data.resources;

    return <TrunkInfo incomingTrunk={incomingTrunk} />;
};
