import React from 'react';
import { LabelInfo } from '@didww/ui-kit';
import { CodecsSelect } from 'entities/form-selects';
import { NetProtocolSelect } from 'entities/termination-gateways/ui/';
import { TextInput } from 'shared/ui/fields';
import { FormLayout } from 'shared/ui/layouts';

export const Fields: React.FC = () => (
    <FormLayout>
        <FormLayout.Field>
            <TextInput name="name" labelText={<LabelInfo title="Name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="host" labelText={<LabelInfo title="Host" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="port" labelText={<LabelInfo title="Port" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <NetProtocolSelect />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="authorization_name" labelText={<LabelInfo title="Auth name" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="authorization_password" labelText={<LabelInfo title="Auth password" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="src_rewrite_rule" labelText={<LabelInfo title="SRC rewrite rule" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="src_rewrite_result" labelText={<LabelInfo title="SRC rewrite result" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="dst_rewrite_rule" labelText={<LabelInfo title="DST rewrite rule" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <TextInput name="dst_rewrite_result" labelText={<LabelInfo title="DST rewrite result" />} />
        </FormLayout.Field>
        <FormLayout.Field>
            <CodecsSelect />
        </FormLayout.Field>
    </FormLayout>
);
