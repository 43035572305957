import dayjs from 'dayjs';
import { TerminationCdrsFilter } from 'entities/termination-cdrs';

export const getInitialFilter = (): TerminationCdrsFilter => ({
    time_start_gteq: dayjs().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    time_start_lteq: dayjs().add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    customer_id: '',
    termination_gateway_id: '',
    termination_route_id: '',
    aleg_code: '',
    aleg_reason: '',
    src_name_in: '',
    src_number_in: '',
    dst_number_in: '',
    orig_call_id: '',
    global_tag: '',
    local_tag: '',
});
