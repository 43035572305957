import React from 'react';
import { ResourceColumnsType } from 'entities/resources-table';
import { TableSorter } from 'shared/types/common';
import { IncomingTrunk } from 'entities/incoming-trunks';
import { Codecs } from 'shared/ui/cells';
import { TruncateTooltip } from 'shared/ui/truncate-tooltip';

export const getColumns = (sorter: TableSorter): ResourceColumnsType<IncomingTrunk> => [
    {
        key: 'id',
        dataIndex: 'id',
        title: 'Incoming Trunk ID',
        sorter: true,
        sortOrder: sorter.column === 'id' && sorter.order,
    },
    {
        key: 'name',
        dataIndex: 'name',
        title: 'Name',
        sorter: true,
        sortOrder: sorter.column === 'name' && sorter.order,
        render: function NameCell(_, row) {
            return <TruncateTooltip text={row.name} />;
        },
    },
    {
        key: 'domain',
        dataIndex: 'domain',
        title: 'Domain',
    },
    {
        key: 'customer_id',
        title: 'Customer ID',
        render: (_, row) => row.customer.id,
    },
    {
        key: 'customer_name',
        title: 'Customer',
        render: function CustomerNameCell(_, row) {
            return <TruncateTooltip text={row.customer.name} />;
        },
    },
    {
        key: 'codecs',
        dataIndex: 'codecs',
        title: 'Codecs',
        render: function CodecsCell(_, row) {
            return <Codecs codecs={row.codecs} />;
        },
    },
    {
        key: 'transport_protocol',
        dataIndex: 'transport_protocol',
        title: 'Transport',
    },
    {
        key: 'destination_field',
        dataIndex: 'destination_field',
        title: 'Destination field',
        defaultHidden: true,
    },
];
