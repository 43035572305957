import React from 'react';
import { useParams } from 'react-router-dom';
import { TerminationRouteEdit } from 'features/termination-routes';
import { PageLayout } from 'shared/ui/layouts';

export const TerminationRouteEditPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    return (
        <PageLayout>
            <TerminationRouteEdit id={id} />
        </PageLayout>
    );
};
